import { useEffect, useState, useRef } from "react";
import classes from "./Campaigns.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";

// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { firestore } from "../../Services/Firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const Campaigns = () => {
  const [widthButton, setWidthButton] = useState("");
  const [openModalCreateBank, setOpenModalCreateBank] = useState("");
  const [campaignsName, setCampaignsName] = useState("");
  const [bank, setBank] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [userRowData, setUserRowData] = useState("");
  const [openModalDeleteClient, setOpenModalDeleteClient] = useState(false);
  const [noContentPage, setNoContentPage] = useState(false);
  const [smsSendSucces, setSmsSendSucces] = useState();
  const [loading, setLoading] = useState({
    content: true,
    create_campaigns: false,
  });

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const useCollection = collection(firestore, "sms_campaigns");

  const campaignsNameRef = useRef(campaignsName);
  const dateCreateRef = useRef(date_create);

  const DeleteClient = async (id) => {
    const userDoc = doc(firestore, "sms_campaigns", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteClient(false);
  };

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    campaignsNameRef.current = campaignsName;
    dateCreateRef.current = date_create;
  }, [campaignsName, date_create]);

  const CreateUser = async () => {
    setLoading((prevState) => ({
      ...prevState,
      create_campaigns: true,
    }));
    try {
      const user = await addDoc(useCollection, {
        // Utiliza os valores das refs diretamente
        name: campaignsNameRef.current,
        date_create: dateCreateRef.current,
      });

      setOpenModalCreateBank(false);
      setCallUsers(!callUsers);
      setLoading((prevState) => ({
        ...prevState,
        create_campaigns: false,
      }));
      setSmsSendSucces(true)

      return user;
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      throw error;
    }
  };

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    // Adicionar um ouvinte de evento para o evento 'resize'
    window.addEventListener("resize", obterTamanhoDaTela);

    // Chamar a função inicialmente para obter o tamanho da tela quando a página carregar
    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      setLoading((prevState) => ({
        ...prevState,
        content: true,
      }));
      const data = await getDocs(useCollection);
      setBank(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading((prevState) => ({
        ...prevState,
        content: false,
      }));
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  const columns = [
    { id: "name", label: "campanha", flex: 1 },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = bank.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={openModalDeleteClient}
        setOpenModal={setOpenModalDeleteClient}
        title={`Deseja realmente excluir a campanha?`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteClient(userRowData.id)}
      />
      <ModalSmall
        openModal={smsSendSucces}
        setOpenModal={setSmsSendSucces}
        title={`Campanha cadastrada com sucesso!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalDefault
        openModal={openModalCreateBank}
        setOpenModal={setOpenModalCreateBank}
        setCampaignsName={setCampaignsName}
        widthButton={widthButton}
        modalType="cadastro de campanha"
        title="cadastro de campanha"
        btnLeftText="cadastrar"
        btnCloseModalText="cancelar"
        loadingSms={loading.create_campaigns}
        callFunc={() => {
          CreateUser();
        }}
      />
      <CardTable
        title="Campanhas"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Campanhas" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="cadastrar campanha"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "200px" : "100%"}
                callFunc={() => setOpenModalCreateBank(true)}
              />
            </div>
          </div>
        }
        table={
          loading.content ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="banco" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            height: "20px",
                            color: "#0091D7",
                            textTransform: "uppercase",
                            borderColor: "#275370",
                            fontSize: "11px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bank &&
                      usersToShow.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              let value = row[column.id];
                              return (
                                <TableCell
                                  className={classes.TableCell}
                                  style={{
                                    // minWidth: column.minWidth,
                                    padding: "10px 16px 10px 16px",
                                    color: "#fff",
                                    borderColor: "#275370",
                                    marginRight: "50px",
                                    fontSize: "11px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.id === "action"
                                    ? (value = (
                                        <div
                                          className={classes.TableActionButtons}
                                        >
                                          <button
                                            title="Deletar usuário"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalDeleteClient(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={deleteImg} alt="" />
                                          </button>
                                        </div>
                                      ))
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={bank.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Campaigns;
