import { useState, useContext, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import classes from "./AsideNav.module.scss";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import shopping_cart from "./../../Assets/AsideNavIcons/home.svg";
import credits from "./../../Assets/AsideNavIcons/credits.svg";
import people from "./../../Assets/AsideNavIcons/people.svg";
import cart from "./../../Assets/AsideNavIcons/cart.svg";
import admin from "./../../Assets/AsideNavIcons/admin.svg";
import logout from "./../../Assets/AsideNavIcons/logout.svg";
import ellipse from "./../../Assets/ellipse.svg";
import lineBlue from "./../../Assets/lineBlue.svg";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const AsideNav = () => {
  const { user, SignOut, userLogged } = useContext(AuthGoogleContext);
  const [collapsed, setCollapsed] = useState(false);
  const [companyInfo, setCompanyInfo] = useState();

  const stylesMenuItem = {
    color: "#fff",
    background: "#212c32",
    padding: "20px 30px",
    display: "flex",
  };
  const subMenuItem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const ImageDefault = (
    <img
      style={{
        height: "50px",
      }}
      src={lineBlue}
      alt=""
    />
  );

  const [openDashboard, setOpenDashboard] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openCredit, setOpenCredit] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openResale, setOpenResale] = useState(false);
  const [openDashboardDesk, setOpenDashboardDesk] = useState(true);
  const [openAdminDesk, setOpenAdminDesk] = useState(false);
  const [openCreditDesk, setOpenCreditDesk] = useState(false);
  const [openClientDesk, setOpenClientDesk] = useState(false);
  const [openResaleDesk, setOpenResaleDesk] = useState(false);
  // const [openAdminMenuDesk, setOpenAdminMenuDesk] = useState(false);
  // const [openCreditMenuDesk, setOpenCreditMenuDesk] = useState(false);
  // const [openClientMenuDesk, setOpenClientMenuDesk] = useState(false);
  // const [openResaleMenuDesk, setOpenResaleMenuDesk] = useState(false);

  const toggleDrawerCredit = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenCredit(!openCredit);
    setOpenClient(false);
    setOpenResale(false);
    setOpenDashboard(false);
    setOpenAdmin(false);
  };

  const toggleDrawerClient = (value) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenClient(!openClient);
    setOpenCredit(false);
    setOpenResale(false);
    setOpenDashboard(false);
    setOpenAdmin(false);
  };
  const toggleDrawerResale = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenResale(!openResale);
    setOpenClient(false);
    setOpenCredit(false);
    setOpenDashboard(false);
    setOpenAdmin(false);
  };

  useEffect(() => {
    const getCompanyLocStor = sessionStorage.getItem("@AuthFirebase:company");
    const userCompanyJson = JSON.parse(getCompanyLocStor);
    setCompanyInfo(userCompanyJson?.createCompany_image_url);
  }, [user]);

  return (
    <>
      <div className={classes.AsideNav}>
        <div className={classes.CardCompany}>
          <button
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <img
              // className={classes.ImgCompany}
              style={{ width: collapsed ? "40px" : "70px" }}
              src={companyInfo}
              alt=""
            />
          </button>
        </div>
        <Sidebar
          className={classes.CustomSidebar}
          collapsed={collapsed}
          collapsedWidth="75px"
          width="200px"
          rootStyles={{
            backgroundColor: "red",
          }}
        >
          <Menu className={classes.Menu}>
            <div
              onClick={() => {
                setOpenCreditDesk(false);
                setOpenClientDesk(false);
                setOpenResaleDesk(false);
                setOpenDashboardDesk(true);
                setOpenAdminDesk(false);
              }}
              className={
                openDashboardDesk ? classes.MenuItemActive : classes.MenuItem
              }
            >
              {userLogged.permission.dashboard && (
                <MenuItem>
                  <Link to="/dashboard">
                    <div className={classes.MenuItem}>
                      <img
                        className={classes.IconAsideNav}
                        src={shopping_cart}
                        alt=""
                      />
                      <div className={classes.TextAsideNav}>Dashboard</div>
                    </div>
                  </Link>
                </MenuItem>
              )}
            </div>
            <div
              onClick={() => {
                setOpenCreditDesk(true);
                setOpenClientDesk(false);
                setOpenResaleDesk(false);
                setOpenDashboardDesk(false);
                setOpenAdminDesk(false);
                // setOpenCreditMenuDesk(!openCreditMenuDesk)
              }}
              className={
                openCreditDesk ? classes.MenuItemActive : classes.MenuItem
              }
            >
              {collapsed ? (
                <MenuItem>
                  <div className={classes.MenuItem}>
                    <img
                      className={classes.IconAsideNav}
                      src={credits}
                      alt=""
                    />
                  </div>
                </MenuItem>
              ) : (
                <SubMenu
                  // onOpenChange={handleOpenChange}
                  // open={openCreditMenuDesk}
                  label={
                    <div className={classes.MenuItem}>
                      <img
                        className={classes.IconAsideNav}
                        src={credits}
                        alt=""
                      />
                      <div className={classes.TextAsideNav}>Créditos</div>
                    </div>
                  }
                >
                  {userLogged.permission.credits.credit_list && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/creditos">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Lista de crédito
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.credits.history && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/historico">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Histórico
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {/* <MenuItem style={stylesMenuItem}>
                    <div style={subMenuItem}>
                      {ImageDefault}
                      <div
                        style={{
                          width: "125px",
                        }}
                      >
                        Relatórios
                      </div>
                    </div>
                  </MenuItem> */}
                  {userLogged.permission.credits.supplier && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/fornecedores">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Fornecedor
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.credits.bank && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/bancos">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Banco
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </div>
            <div
              onClick={() => {
                setOpenCreditDesk(false);
                setOpenClientDesk(true);
                setOpenResaleDesk(false);
                setOpenDashboardDesk(false);
                setOpenAdminDesk(false);
              }}
              className={
                openClientDesk ? classes.MenuItemActive : classes.MenuItem
              }
            >
              {collapsed ? (
                <MenuItem>
                  <div className={classes.MenuItem}>
                    <img className={classes.IconAsideNav} src={people} alt="" />
                  </div>
                </MenuItem>
              ) : (
                <SubMenu
                  label={
                    <div className={classes.MenuItem}>
                      <img
                        className={classes.IconAsideNav}
                        src={people}
                        alt=""
                      />
                      <div className={classes.TextAsideNav}>Clientes</div>
                    </div>
                  }
                >
                  {userLogged.permission.clients.clients && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/clientes">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Clientes
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.clients.plans && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/planos-cliente">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Planos
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.clients.sms_history && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/sms">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            SMS Histórico
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.clients.sms_campaign && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/campanhas">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            SMS Campanhas
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.clients.report && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/relatorios-cliente">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Relatórios
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </div>
            <div
              onClick={() => {
                setOpenCreditDesk(false);
                setOpenClientDesk(false);
                setOpenResaleDesk(true);
                setOpenDashboardDesk(false);
                setOpenAdminDesk(false);
              }}
              className={
                openResaleDesk ? classes.MenuItemActive : classes.MenuItem
              }
            >
              {collapsed ? (
                <MenuItem>
                  <div className={classes.MenuItem}>
                    <img className={classes.IconAsideNav} src={cart} alt="" />
                  </div>
                </MenuItem>
              ) : (
                <SubMenu
                  label={
                    <div className={classes.MenuItem}>
                      <img className={classes.IconAsideNav} src={cart} alt="" />
                      <div className={classes.TextAsideNav}>Revenda</div>
                    </div>
                  }
                >
                  {userLogged.permission.resale.resale && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/revendas">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Revendas
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.resale.financial_history && (
                    <MenuItem style={stylesMenuItem}>
                      <Link
                        style={{ color: "#fff" }}
                        to="/historico-financeiro-revenda"
                      >
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Histórico Financeiro
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {userLogged.permission.resale.report && (
                    <MenuItem style={stylesMenuItem}>
                      <Link style={{ color: "#fff" }} to="/relatorios-revenda">
                        <div style={subMenuItem}>
                          {ImageDefault}
                          <div
                            style={{
                              width: "125px",
                              fontSize: "14px",
                            }}
                          >
                            Relatórios
                          </div>
                        </div>
                      </Link>
                    </MenuItem>
                  )}
                  {/* <MenuItem style={stylesMenuItem}>
                    <div style={subMenuItem}>
                      {ImageDefault}
                      <div
                        style={{
                          width: "125px",
fontSize:"14px"
                        }}
                      >
                        Relatórios
                      </div>
                    </div>
                  </MenuItem> */}
                </SubMenu>
              )}
            </div>
            <div
              onClick={() => {
                setOpenCreditDesk(false);
                setOpenClientDesk(false);
                setOpenResaleDesk(false);
                setOpenDashboardDesk(false);
                setOpenAdminDesk(true);
              }}
              className={
                openAdminDesk ? classes.MenuItemActive : classes.MenuItem
              }
            >
              {collapsed ? (
                <MenuItem>
                  <div className={classes.MenuItem}>
                    <img className={classes.IconAsideNav} src={admin} alt="" />
                  </div>
                </MenuItem>
              ) : (
                userLogged.permission.admin && (
                  <MenuItem>
                    <Link to="/admin">
                      <div className={classes.MenuItem}>
                        <img
                          className={classes.IconAsideNav}
                          src={admin}
                          alt=""
                        />
                        <div className={classes.TextAsideNav}>
                          Administrador
                        </div>
                      </div>
                    </Link>
                  </MenuItem>
                )
              )}
            </div>
            <div className={classes.MenuItemLogout}>
              {collapsed ? (
                <MenuItem>
                  <div className={classes.MenuItem}>
                    <img className={classes.IconAsideNav} src={logout} alt="" />
                  </div>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    SignOut();
                  }}
                >
                  <div className={classes.MenuItem}>
                    <img className={classes.IconAsideNav} src={logout} alt="" />
                    <div className={classes.TextAsideNav}>Sair</div>
                  </div>
                </MenuItem>
              )}
            </div>
          </Menu>
        </Sidebar>
      </div>

      <SwipeableDrawer
        anchor="bottom"
        open={openCredit}
        onClose={toggleDrawerCredit(false)}
        onOpen={toggleDrawerCredit(true)}
        sx={{ backgroundColor: "none", background: "transparent" }}
      >
        <div
          role="presentation"
          onClick={toggleDrawerCredit(false)}
          onKeyDown={toggleDrawerCredit(false)}
          // style={{ background: "transparent", backgroundColor: "none" }}
          className={classes.BackDivOfMenu}
        >
          <List
            sx={{
              backgroundColor: "#1A2328",
              paddingBottom: "100px",
              borderRadius: "8px",
              border: "2px solid #3487BE",
              width: "90%",
              margin: "0 22px",
              span: {
                fontSize: "20px",
                color: "#F3F7F9",
              },
              img: {
                width: "18px",
                margin: "0 30px 0 15px",
              },
            }}
          >
            {userLogged.permission.credits.credit_list && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/creditos"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Lista de crédito"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.credits.history && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/historico"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Histórico"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.credits.supplier && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/fornecedores"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Fornecedor"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.credits.bank && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/bancos"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Banco"} />
                </ListItem>
              </Link>
            )}
          </List>
        </div>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={openClient}
        onClose={toggleDrawerClient(false)}
        onOpen={toggleDrawerClient(true)}
        sx={{ backgroundColor: "none", background: "transparent" }}
      >
        <div
          role="presentation"
          onClick={toggleDrawerClient(false)}
          onKeyDown={toggleDrawerClient(false)}
          // style={{ background: "transparent", backgroundColor: "none" }}
          className={classes.BackDivOfMenu}
        >
          <List
            sx={{
              backgroundColor: "#1A2328",
              paddingBottom: "100px",
              borderRadius: "8px",
              border: "2px solid #3487BE",
              width: "90%",
              margin: "0 22px",
              span: {
                fontSize: "20px",
                color: "#F3F7F9",
              },
              img: {
                width: "18px",
                margin: "0 30px 0 15px",
              },
            }}
          >
            {userLogged.permission.clients.clients && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/clientes"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Clientes"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.clients.plans && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/planos-cliente"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Planos"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.clients.sms_history && (
              <Link style={{ color: "#fff", textDecoration: "none" }} to="/sms">
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"SMS Histórico"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.clients.sms_campaign && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/campanhas"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"SMS Campanhas"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.clients.report && (
              <Link
                style={{ color: "#fff", textDecoration: "none" }}
                to="/relatorios-cliente"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Relatórios"} />
                </ListItem>
              </Link>
            )}
          </List>
        </div>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={openResale}
        onClose={toggleDrawerResale(false)}
        onOpen={toggleDrawerResale(true)}
        sx={{ backgroundColor: "none", background: "transparent" }}
      >
        <div
          role="presentation"
          onClick={toggleDrawerResale(false)}
          onKeyDown={toggleDrawerResale(false)}
          // style={{ background: "transparent", backgroundColor: "none" }}
          className={classes.BackDivOfMenu}
        >
          <List
            sx={{
              backgroundColor: "#1A2328",
              paddingBottom: "100px",
              borderRadius: "8px",
              border: "2px solid #3487BE",
              width: "90%",
              margin: "0 22px",
              span: {
                fontSize: "20px",
                color: "#F3F7F9",
              },
              img: {
                width: "18px",
                margin: "0 30px 0 15px",
              },
            }}
          >
            {userLogged.permission.resale.resale && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: "10px",
                }}
                to="/revendas"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Revendas"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.resale.financial_history && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: "10px",
                }}
                to="/historico-financeiro-revenda"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Histórico Financeiro"} />
                </ListItem>
              </Link>
            )}
            {userLogged.permission.resale.report && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: "10px",
                }}
                to="/relatorios-revenda"
              >
                <ListItem button key={"text"}>
                  <img src={ellipse} alt="" />
                  <ListItemText primary={"Relatórios"} />
                </ListItem>
              </Link>
            )}
          </List>
        </div>
      </SwipeableDrawer>

      <div className={classes.AsideNavMobile}>
        {userLogged.permission.dashboard && (
          <div
            onClick={() => {
              setOpenCredit(false);
              setOpenClient(false);
              setOpenResale(false);
              setOpenDashboard(true);
              setOpenAdmin(false);
            }}
            className={
              openDashboard
                ? classes.AsideNavButtonActive
                : classes.AsideNavButton
            }
          >
            <Link to="/dashboard">
              <img
                className={classes.IconAsideNavMob}
                src={shopping_cart}
                alt=""
              />
            </Link>
          </div>
        )}
        <div
          onClick={toggleDrawerCredit(true)}
          className={
            openCredit ? classes.AsideNavButtonActive : classes.AsideNavButton
          }
        >
          <img className={classes.IconAsideNavMob} src={credits} alt="" />
        </div>
        <div
          onClick={toggleDrawerClient(true)}
          className={
            openClient ? classes.AsideNavButtonActive : classes.AsideNavButton
          }
        >
          <img className={classes.IconAsideNavMob} src={people} alt="" />
        </div>
        <div
          onClick={
            (toggleDrawerCredit(false),
            toggleDrawerClient(false),
            toggleDrawerResale(true))
          }
          className={
            openResale ? classes.AsideNavButtonActive : classes.AsideNavButton
          }
        >
          <img className={classes.IconAsideNavMob} src={cart} alt="" />
        </div>
        {userLogged.permission.admin && (
          <div
            onClick={() => {
              setOpenCredit(false);
              setOpenClient(false);
              setOpenResale(false);
              setOpenDashboard(false);
              setOpenAdmin(true);
            }}
            className={
              openAdmin ? classes.AsideNavButtonActive : classes.AsideNavButton
            }
          >
            <Link to="/admin">
              <img className={classes.IconAsideNavMob} src={admin} alt="" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default AsideNav;
