/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import classes from "./CreditsHistory.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";
import { firestore } from "../../Services/Firebase";
import {
  collection,
  getDocs,
  // query,
  // where,
} from "firebase/firestore";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";
import { useRef } from "react";

const CreditsHistory = () => {
  const [users, setUsers] = useState("");
  const [widthButton, setWidthButton] = useState("");
  const [supplier, setSupplier] = useState("");
  /**************************************** */
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [filter, setFilter] = useState();
  const [filterNameValue, setFilterNameValue] = useState();
  const [filtering, setFiltering] = useState();
  const [callFilter, setCallFilter] = useState(false);
  const [formatDatePickerDate, setFormatDatePickerDate] = useState();
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [arrayOrdenado, setArrayOrdenado] = useState();
  const [filterSupplier, setFilterSupplier] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(true);
  const [noContentPage, setNoContentPage] = useState(false);
  const [selectedDateInicial, setSelectedDateInicial] = useState();
  const [selectedDateFinal, setSelectedDateFinal] = useState();
  const [formatDatePickerDateInicial, setFormatDatePickerDateInicial] =
    useState();
  const [formatDatePickerDateFinal, setFormatDatePickerDateFinal] = useState();

  useEffect(() => {
    setFilter(filtering);
  }, [callFilter]);

  /***************************************** */
  const optionsSupplier =
    supplier &&
    supplier.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  // const dataAtual = new Date();
  // const dia = dataAtual.getDate();
  // const mes = dataAtual.getMonth() + 1;
  // const ano = dataAtual.getFullYear();
  // const date_create = `${dia < 10 ? "0" : ""}${dia}/${
  //   mes < 10 ? "0" : ""
  // }${mes}/${ano}`;

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    if (selectedDateInicial) {
      const FormatDatePickerDate = () => {
        const day = selectedDateInicial.format("DD");
        const month = selectedDateInicial.format("MM");
        const year = selectedDateInicial.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;
        setFormatDatePickerDateInicial(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateInicial]);

  useEffect(() => {
    if (selectedDateFinal) {
      const FormatDatePickerDate = () => {
        const day = selectedDateFinal.format("DD");
        const month = selectedDateFinal.format("MM");
        const year = selectedDateFinal.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDateFinal(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateFinal]);

  const useCollectionCreditHistory = collection(firestore, "credit_history");
  const useCollectionSupplier = collection(firestore, "credit_supplier");

  /*************************** */

  const formatDatePickerDateInicialRef = useRef(formatDatePickerDateInicial);
  const formatDatePickerDateFinalRef = useRef(formatDatePickerDateFinal);

  useEffect(() => {
    formatDatePickerDateInicialRef.current = formatDatePickerDateInicial;
    formatDatePickerDateFinalRef.current = formatDatePickerDateFinal;
  }, [formatDatePickerDateInicial, formatDatePickerDateFinal]);

  function parseDate(str) {
    const [datePart] = str.split(" "); // Ignora a parte do tempo e usa apenas a parte da data
    const [day, month, year] = datePart.split("/");
    return new Date(year, month - 1, day); // Apenas data, sem tempo
  }

  useEffect(() => {
    if (formatDatePickerDateInicial && formatDatePickerDateFinal) {
      function filterItemsByDateRange(items, startDate, endDate) {
        // Converter as datas usando a função parseDate para garantir o formato correto
        const startDateObj = parseDate(startDate);
        const endDateObj = parseDate(endDate);

        // Definir os horários para meia-noite para garantir comparação apenas por data
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(0, 0, 0, 0);

        // Filtrar os itens que estão entre as datas especificadas
        return (
          items &&
          items.filter((item) => {
            // Converter a data de criação do item para o formato de data
            const itemDate = parseDate(item.date_create);
            itemDate.setHours(0, 0, 0, 0);

            // Verificar se a data do item está dentro do intervalo especificado
            return itemDate >= startDateObj && itemDate <= endDateObj;
          })
        );
      }

      // Filtrar os itens pelo intervalo de datas
      const filteredOutputsAll = filterItemsByDateRange(
        filter,
        formatDatePickerDateInicial,
        formatDatePickerDateFinal
      );

      setFilter(filteredOutputsAll); // Suponho que setFilter seja uma função de atualização de estado
    }
  }, [callFilter]);

  /******************************************* */

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionCreditHistory);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCreditHistory = async () => {
      setLoading(true);
      const data = await getDocs(useCollectionCreditHistory);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setArrayOrdenado(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((objeto) => objeto.date_create)
          .sort(compararPorDateCreate)
      );
      setFilter(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((objeto) => objeto.date_create)
          .sort(compararPorDateCreate)
      );
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSupplier();
    getCreditHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterSupplier && formatDatePickerDate) {
      setFiltering(
        filter &&
          filter?.filter((newUsers) => {
            // Obtém apenas a parte da data da string
            const datePart = newUsers.date_create.split(" ")[0];

            return (
              newUsers.supplier === filterSupplier &&
              datePart === formatDatePickerDate
            );
          })
      );
    } else if (filterSupplier) {
      setFiltering(
        filter &&
          filter?.filter((newUsers) => newUsers.supplier === filterSupplier)
      );
    } else if (formatDatePickerDate) {
      setFiltering(
        filter &&
          filter?.filter((newUsers) => {
            // Obtém apenas a parte da data da string
            const datePart = newUsers.date_create.split(" ")[0];

            return datePart === formatDatePickerDate;
          })
      );
    }
  }, [formatDatePickerDate, filterSupplier]);

  useEffect(() => {
    if (filterNameValue?.length > 0) {
      setFilter(
        filter &&
          filter?.filter((newUsers) =>
            newUsers.client
              .toLowerCase()
              .includes(filterNameValue.toLowerCase())
          )
      );
    } else {
      setFilter(arrayOrdenado);
    }
  }, [filterNameValue]);

  useEffect(() => {
    if (selectedDate) {
      const FormatDatePickerDate = () => {
        const day = selectedDate.format("DD");
        const month = selectedDate.format("MM");
        const year = selectedDate.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDate(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDate]);

  const compararPorDateCreate = (a, b) => {
    const extrairDataHora = (dataString) => {
      const [dataParte, horaParte] = dataString.split(" ");
      const [dia, mes, ano] = dataParte.split("/");
      const [hora, minuto, segundo] = horaParte.split(":");
      return new Date(ano, mes - 1, dia, hora, minuto, segundo);
    };

    const dataA = extrairDataHora(a.date_create);
    const dataB = extrairDataHora(b.date_create);

    // Compara as datas em ordem decrescente
    return dataB - dataA;
  };

  const columns = [
    { id: "operation", label: "TIPO OPERAÇÃO", flex: 1 },
    { id: "supplier", label: "FORNECEDOR", flex: 1 },
    { id: "client", label: "CLIENTE / REVENDA", flex: 1 },
    { id: "operation_amount", label: "QUANTIDADE", flex: 1 },
    { id: "initial_amount", label: "SALDO INICIAL", flex: 1 },
    { id: "final_amount", label: "SALDO FINAL", flex: 1 },
    { id: "user_logged", label: "USER SISTEMA", flex: 1 },
    { id: "date_create", label: "CRIADO EM", flex: 1 },
  ];

  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = filter && filter.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      <ModalDefault
        openModal={openModalFilter}
        setOpenModal={setOpenModalFilter}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        filterSupplier={filterSupplier}
        filter={filter}
        optionsSupplier={optionsSupplier}
        setFilterSupplier={setFilterSupplier}
        users={users}
        selectedDateInicial={selectedDateInicial}
        setSelectedDateInicial={setSelectedDateInicial}
        selectedDateFinal={selectedDateFinal}
        setSelectedDateFinal={setSelectedDateFinal}
        widthButton={widthButton}
        modalType="filtros"
        title="filtros"
        btnLeftText="filtrar"
        btnRightText="limpar filtros"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          setCallFilter(!callFilter);
          setOpenModalFilter(false);
        }}
        callFuncRight={() => {
          setFilter(arrayOrdenado);
          setOpenModalFilter(false);
          setFilterSupplier();
          setSelectedDateInicial();
          setSelectedDateFinal();
          setFormatDatePickerDate();
          setSelectedDate();
        }}
      />
      <CardTable
        hasFilterArea="true"
        FilterAreaCallFunc={() => {
          setOpenModalFilter(true);
        }}
        hasFilterName="true"
        setFilterNameValue={setFilterNameValue}
        title="Histórico de Créditos"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Histórico de Créditos" />
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="histórico" />
          ) : (
            <div>
              <div>
                <TableContainer sx={{ maxHeight: "70vh" }}>
                  {widthButton > 768 && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: "20px",
                                color: "#0091D7",
                                textTransform: "uppercase",
                                borderColor: "#275370",
                                fontSize: "11px",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersToShow &&
                          usersToShow.map((row) => {
                            return (
                              <TableRow
                                hover
                                // role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columns.map((column) => {
                                  let value = row[column.id];
                                  return (
                                    <TableCell
                                      className={classes.TableCell}
                                      style={{
                                        // minWidth: column.minWidth,
                                        padding: "10px 16px 10px 16px",
                                        color: "#fff",
                                        borderColor: "#275370",
                                        marginRight: "50px",
                                        fontSize: "11px",
                                      }}
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "operation" ? (
                                        value === "Saída" ? (
                                          <div
                                            className={classes.TableButtonGreen}
                                          >
                                            {value}
                                          </div>
                                        ) : value === "Entrada" ? (
                                          <div
                                            className={classes.TableButtonRed}
                                          >
                                            {value}
                                          </div>
                                        ) : null
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                  {widthButton < 768 && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columnsCollapse.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: "20px",
                                color: "#0091D7",
                                textTransform: "uppercase",
                                borderColor: "#275370",
                                fontSize: "11px",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersToShow &&
                          usersToShow.map((row) => {
                            return (
                              <TableRow
                                hover
                                // role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columnsCollapse.map((column) => {
                                  let value = row[column.id];
                                  return (
                                    <TableCell
                                      className={classes.TableCell}
                                      style={{
                                        // minWidth: column.minWidth,
                                        padding: "5px 16px 5px 16px",
                                        color: "#fff",
                                        borderColor: "#275370",
                                        marginRight: "50px",
                                        fontSize: "11px",
                                      }}
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "collapse" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ fontSize: "16px" }}>
                                            {row.operation === "Saída" ? (
                                              <div
                                                className={
                                                  classes.TableButtonGreen
                                                }
                                              >
                                                {row.operation}
                                              </div>
                                            ) : row.operation === "Entrada" ? (
                                              <div
                                                className={
                                                  classes.TableButtonRed
                                                }
                                              >
                                                {row.operation}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div>{row.date_create}</div>

                                          <IconButton
                                            sx={{
                                              color: "#0091d7",
                                              "&.Mui-checked": {
                                                color: "#0091d7",
                                              },
                                            }}
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                              setRowId(row.id);
                                              setOpen(!open);
                                            }}
                                          >
                                            <img
                                              style={{ width: "25px" }}
                                              src={
                                                open && row.id == rowId
                                                  ? closeIcon
                                                  : openIcon
                                              }
                                              alt=""
                                            />
                                          </IconButton>
                                        </div>
                                      ) : (
                                        value
                                      )}
                                      {row.id == rowId && (
                                        <Collapse
                                          in={open}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box
                                            sx={{
                                              margin: 1,
                                              color: "#FFF",
                                              fontSize: "11px",
                                            }}
                                          >
                                            <Table
                                              size="small"
                                              aria-label="purchases"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    Itens
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    Informações
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"FORNECEDOR:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.supplier}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"CLIENTE / REVENDA"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.client}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"QUANTIDADE"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.operation_amount}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"SALDO INICIAL"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.initial_amount}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"SALDO FINAL"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.final_amount}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"USER SISTEMA"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.user_logged}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"CRIADO EM:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.date_create}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </div>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={filter && filter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default CreditsHistory;
