import { useEffect, useState, useRef } from "react";
import classes from "./ClientPlans.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";

// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { firestore } from "../../Services/Firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  // doc,
  // deleteDoc,
  // updateDoc,
  // query,
  // where,
} from "firebase/firestore";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const ClientPlans = () => {
  const [users, setUsers] = useState("");
  const [widthButton, setWidthButton] = useState("");
  const [openModalCreatePlan, setOpenModalCreatePlan] = useState("");
  const [clientPlanName, setClientPlanName] = useState("");
  const [clientPlanValue, setClientPlanValue] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
  const [userRowData, setUserRowData] = useState();
  const [loading, setLoading] = useState(true);
  const [noContentPage, setNoContentPage] = useState(false);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    // Adicionar um ouvinte de evento para o evento 'resize'
    window.addEventListener("resize", obterTamanhoDaTela);

    // Chamar a função inicialmente para obter o tamanho da tela quando a página carregar
    obterTamanhoDaTela();
  }, []);

  const useCollectionClientPlans = collection(firestore, "client_plans");

  const clientPlanNameRef = useRef(clientPlanName);
  const clientPlanValueRef = useRef(clientPlanValue);

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    clientPlanNameRef.current = clientPlanName;
    clientPlanValueRef.current = clientPlanValue;
  }, [clientPlanName, clientPlanValue]);

  const DeleteUser = async (id) => {
    const userDoc = doc(firestore, "client_plans", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteUser(false);
  };

  const CreateClientPlan = async () => {
    try {
      const user = await addDoc(useCollectionClientPlans, {
        // Utiliza os valores das refs diretamente
        clientPlanName: clientPlanNameRef.current,
        clientPlanValue: clientPlanValueRef.current,
      });

      setCallUsers(!callUsers);
      setOpenModalCreatePlan(false);

      return user;
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const data = await getDocs(useCollectionClientPlans);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  const columns = [
    { id: "clientPlanName", label: "plano", minWidth: 80 },
    { id: "clientPlanValue", label: "valor", minWidth: 70 },
    { id: "action", label: "ações", minWidth: 70 },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = users.slice(startIndex, endIndex);

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={openModalDeleteUser}
        setOpenModal={setOpenModalDeleteUser}
        title={`Deseja realmente excluir o usuário '${userRowData?.name}', de e-mail '${userRowData?.email}' ?`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteUser(userRowData.id)}
      />
      <ModalDefault
        openModal={openModalCreatePlan}
        setOpenModal={setOpenModalCreatePlan}
        setOnchangeInfoOne={setClientPlanName}
        setOnchangeInfoTwo={setClientPlanValue}
        widthButton={widthButton}
        modalType="cadastro de plano"
        title="cadastro de plano"
        btnLeftText="cadastrar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          CreateClientPlan();
        }}
      />
      <CardTable
        title="Planos de cliente"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Planos de Cliente" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="cadastrar plano"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "185px" : "100%"}
                callFunc={() => setOpenModalCreatePlan(true)}
              />
            </div>
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="plano de cliente" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            height: "20px",
                            color: "#0091D7",
                            textTransform: "uppercase",
                            borderColor: "#275370",
                            fontSize: "11px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      usersToShow.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              let value = row[column.id];
                              return (
                                <TableCell
                                  className={classes.TableCell}
                                  style={{
                                    // minWidth: column.minWidth,
                                    padding: "10px 16px 10px 16px",
                                    color: "#fff",
                                    borderColor: "#275370",
                                    marginRight: "50px",
                                    fontSize: "11px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.id === "action" ? (
                                    <button
                                      onClick={() => {
                                        setOpenModalDeleteUser(true);
                                        setUserRowData(row);
                                      }}
                                      className={classes.DeleteButton}
                                    >
                                      <img src={deleteImg} alt="" />
                                    </button>
                                  ) : column.id === "clientPlanValue" ? (
                                    formatarValor(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default ClientPlans;
