import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Containers/Login/Login";
import Dashboard from "./Containers/Dashboard/Dashboard";
import Admin from "./Containers/Admin/Admin";
import AuthGoogleProvider from "./Contexts/authGoogle";
import PrivateRoutes from "./Routes";
import Layout from "./Layouts/Layout";
import SignUp from "./Containers/SignUp/SignUp";
import Clients from "./Containers/Clients/Clients";
import ClientPlans from "./Containers/ClientPlans/ClientPlans";
import Bank from "./Containers/Bank/Bank";
import Supplier from "./Containers/Supplier/Supplier";
import Resale from "./Containers/Resale/Resale";
import Credits from "./Containers/Credits/Credits";
import CreditsHistory from "./Containers/CreditsHistory/CreditsHistory";
import FinancialResale from "./Containers/FinancialResale/FinancialResale";
import Sms from "./Containers/Sms/Sms";
import Campaigns from "./Containers/Campaigns/Campaigns";
import ReportClient from "./Containers/ReportClient/ReportClient";
import ResaleFinancial from "./Containers/ReportResale/ReportResale";

const App = () => {
  const getLoginRoutesHandler = () => (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cadastro/:any" element={<SignUp />} />
      <Route path="/dashboard" element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      <Route path="/relatorios-cliente" element={<PrivateRoutes />}>
        <Route path="/relatorios-cliente" element={<ReportClient />} />
      </Route>
      <Route path="/relatorios-revenda" element={<PrivateRoutes />}>
        <Route path="/relatorios-revenda" element={<ResaleFinancial />} />
      </Route>
      <Route path="/admin" element={<PrivateRoutes />}>
        <Route path="/admin" element={<Admin />} />
      </Route>
      <Route path="/clientes" element={<PrivateRoutes />}>
        <Route path="/clientes" element={<Clients />} />
      </Route>
      <Route path="/revendas" element={<PrivateRoutes />}>
        <Route path="/revendas" element={<Resale />} />
      </Route>
      <Route path="/historico-financeiro-revenda" element={<PrivateRoutes />}>
        <Route
          path="/historico-financeiro-revenda"
          element={<FinancialResale />}
        />
      </Route>
      <Route path="/planos-cliente" element={<PrivateRoutes />}>
        <Route path="/planos-cliente" element={<ClientPlans />} />
      </Route>
      <Route path="/sms" element={<PrivateRoutes />}>
        <Route path="/sms" element={<Sms />} />
      </Route>
      <Route path="/campanhas" element={<PrivateRoutes />}>
        <Route path="/campanhas" element={<Campaigns />} />
      </Route>
      <Route path="/historico" element={<PrivateRoutes />}>
        <Route path="/historico" element={<CreditsHistory />} />
      </Route>
      <Route path="/bancos" element={<PrivateRoutes />}>
        <Route path="/bancos" element={<Bank />} />
      </Route>
      <Route path="/fornecedores" element={<PrivateRoutes />}>
        <Route path="/fornecedores" element={<Supplier />} />
      </Route>
      <Route path="/creditos" element={<PrivateRoutes />}>
        <Route path="/creditos" element={<Credits />} />
      </Route>
    </Routes>
  );
  return (
    <AuthGoogleProvider>
      <BrowserRouter>
        <Layout>{getLoginRoutesHandler()}</Layout>
      </BrowserRouter>
    </AuthGoogleProvider>
  );
};

export default App;
