/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, useRef } from "react";
import classes from "./Admin.module.scss";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";

// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { firestore } from "../../Services/Firebase";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import copyImg from "../../Assets/AdminPage/copy.svg";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../Services/Firebase";
import { Box, Collapse, IconButton, TablePagination } from "@mui/material";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const Admin = () => {
  const { user, userLogged } = useContext(AuthGoogleContext);
  const [callUsers, setCallUsers] = useState(false);
  const [users, setUsers] = useState("");
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
  const [openModalUpdateUser, setOpenModalUpdateUser] = useState(false);
  const [openModalEditCompany, setOpenModalEditCompany] = useState(false);
  const [openModalCopyLink, setOpenModalCopyLink] = useState(false);
  const [openModalPermission, setOpenModalPermission] = useState(false);
  const [openModalNewCompany, setOpenModalNewCompany] = useState(false);
  const [openModalAllCompanies, setOpenModalAllCompanies] = useState(false);
  const [userRowData, setUserRowData] = useState();
  const [userRowPermission, setUserRowPermission] = useState();
  const [createCompany_admin, setCreateCompany_admin] = useState("");
  const [createCompany_image_url, setCreateCompany_image_url] = useState("");
  const [createCompany_name, setCreateCompany_name] = useState("");
  const [companyUserLogged, setCompanyUserLogged] = useState("");
  const [widthButton, setWidthButton] = useState("");
  const [noEmptyField, setNoEmptyField] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [updatePermissionModal, setUpdatePermissionModal] = useState(false);
  const [progressBar, setProgressBar] = useState();
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [transferAreaInfo, setTransferAreaInfo] = useState({
    text: "Selecione ou arraste uma imagem",
    format: "Formatos: JPEG, PNG, PDF e SVG",
  });
  const [loading, setLoading] = useState(true);
  const [noContentPage, setNoContentPage] = useState(false);

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const NewPermission = { ...userRowPermission };

  console.log("NewPermission", NewPermission);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      setWidthButton(larguraTela);
    }

    // Adicionar um ouvinte de evento para o evento 'resize'
    window.addEventListener("resize", obterTamanhoDaTela);

    // Chamar a função inicialmente para obter o tamanho da tela quando a página carregar
    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    if (
      createCompany_name.length > 0 &&
      createCompany_admin.length > 0 &&
      createCompany_image_url
        ? true
        : false
    ) {
      setNoEmptyField(true);
    } else {
      setNoEmptyField(false);
    }
  }, [createCompany_name, createCompany_admin, createCompany_image_url]);

  const DeleteUser = async (id) => {
    const userDoc = doc(firestore, "users", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteUser(false);
  };

  const UpdateUser = async (id) => {
    const userDoc = doc(firestore, "users", id);
    await updateDoc(userDoc, { status: "ativo" });
    setCallUsers(!callUsers);
    setOpenModalUpdateUser(false);
  };

  const UpdateUsersPermission = async (id) => {
    const userDoc = doc(firestore, "users", id);
    await updateDoc(userDoc, {
      permission: {
        admin: NewPermission.admin,
        clients: {
          clients: NewPermission.clients.clients,
          plans: NewPermission.clients.plans,
          report: NewPermission.clients.report,
          sms_campaign: NewPermission.clients.sms_campaign,
          sms_history: NewPermission.clients.sms_history,
        },
        credits: {
          bank: NewPermission.credits.bank,
          credit_list: NewPermission.credits.credit_list,
          history: NewPermission.credits.history,
          supplier: NewPermission.credits.supplier,
        },
        dashboard: NewPermission.dashboard,
        resale: {
          financial_history: NewPermission.resale.financial_history,
          report: NewPermission.resale.report,
          resale: NewPermission.resale.resale,
        },
        top_nav_btn: {
          active: NewPermission.top_nav_btn.active,
          credit_cart: NewPermission.top_nav_btn.credit_cart,
          expire_all: NewPermission.top_nav_btn.expire_all,
          expire_month: NewPermission.top_nav_btn.expire_month,
          expire_today: NewPermission.top_nav_btn.expire_today,
          monthly_billing: NewPermission.top_nav_btn.monthly_billing,
          profit_month: NewPermission.top_nav_btn.profit_month,
          resale: NewPermission.top_nav_btn.resale,
        },
      },
    });
    setOpenModalPermission(false);
    setCallUsers(!callUsers);
    setUpdatePermissionModal(true);
  };

  const createCompany_nameRef = useRef(createCompany_name);
  const createCompany_adminRef = useRef(createCompany_admin);

  const useCollectionUsers = collection(firestore, "users");
  const useCollectionCompany = collection(firestore, "company");

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    createCompany_nameRef.current = createCompany_name;
    createCompany_adminRef.current = createCompany_admin;
  }, [createCompany_name, createCompany_admin]);

  const CreateCompany = async () => {
    if (noEmptyField) {
      try {
        // Upload da imagem e obtenção da URL
        const imageUrl = await handleUpload();

        // Adição do documento ao Firestore
        const user = await addDoc(useCollectionCompany, {
          createCompany_admin: createCompany_adminRef.current,
          date_create,
          createCompany_image_url: imageUrl, // Substituído pelo URL retornado do upload
          createCompany_name: createCompany_nameRef.current,
          createCompany_users_number: "1",
        });

        // Aqui você pode realizar ações adicionais após a conclusão bem-sucedida da operação
        setOpenModalNewCompany(false);
        // setCreateCompany_image_url("")
        setTransferAreaInfo({
          text: "Selecione ou arraste uma imagem",
          format: "Formatos: JPEG, PNG, PDF e SVG",
        });

        return user;
      } catch (error) {
        // Lida com erros, se houver algum
        console.error("Erro ao adicionar documento:", error);
        throw error; // Propaga o erro para que possa ser tratado posteriormente
      }
    } else if (!noEmptyField) {
      setAlertModal(true);
    }
  };

  const handleUpload = () => {
    return new Promise((resolve, reject) => {
      // Verifique se createCompany_image_url é um objeto File válido
      if (!(createCompany_image_url instanceof File)) {
        console.error("createCompany_image_url não é um objeto File válido.");
        reject("Erro: arquivo inválido");
        return;
      }

      const storageRef = ref(storage, `images/${createCompany_image_url.name}`);
      const uploadTask = uploadBytesResumable(
        storageRef,
        createCompany_image_url
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBar(`Upload está ${progress}% concluído.`);
          console.log(`Upload está ${progress}% concluído.`);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              console.log("Upload concluído. URL:", url);
              setProgressBar("Upload concluído");
              resolve(url);
            })
            .catch((error) => {
              reject(error);
            });
        }
      );
    });
  };

  const UpdateCompanyImg = async () => {
    const imageUrl = await handleUpload();
    const userDoc = doc(firestore, "company", companyUserLogged[0].id);
    await updateDoc(userDoc, {
      createCompany_image_url: imageUrl,
    });
    getCompanyByEmail();
    setOpenModalEditCompany(false);
    setTransferAreaInfo({
      text: "Selecione ou arraste uma imagem",
      format: "Formatos: JPEG, PNG, PDF e SVG",
    });
  };

  const handleUpdateCompanyImg = () => {
    return new Promise((resolve, reject) => {
      // Verifique se createCompany_image_url é um objeto File válido
      if (!(createCompany_image_url instanceof File)) {
        console.error("createCompany_image_url não é um objeto File válido.");
        reject("Erro: arquivo inválido");
        return;
      }

      const storageRef = ref(storage, `images/${createCompany_image_url.name}`);
      const uploadTask = uploadBytesResumable(
        storageRef,
        createCompany_image_url
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBar(`Upload está ${progress}% concluído.`);
          console.log(`Upload está ${progress}% concluído.`);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              console.log("Upload concluído. URL:", url);
              setProgressBar("Upload concluído");
              resolve(url);
            })
            .catch((error) => {
              reject(error);
            });
        }
      );
    });
  };

  const getCompanyByEmail = async () => {
    const CompanyCollection = collection(firestore, "company");
    const q = await getDocs(
      query(
        CompanyCollection,
        where("createCompany_admin", "==", user && user?.email)
      )
    );

    if (q.docs.length > 0) {
      const userData = q.docs[0].data();
      sessionStorage.setItem("@AuthFirebase:company", JSON.stringify(userData));
    } else {
      sessionStorage.setItem("@AuthFirebase:company", JSON.stringify(null)); // Define como null se não encontrar o usuário
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const data = await getDocs(useCollectionUsers);
      setUsers(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.company === userLogged.company)
      );
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    getUsers();
  }, [callUsers]);

  useEffect(() => {
    const getCompanyUser = async () => {
      const data = await getDocs(useCollectionCompany);
      setCompanyUserLogged(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.createCompany_name === userLogged.company)
      );
    };
    getCompanyUser();
  }, [openModalEditCompany]);

  const columns = [
    { id: "name", label: "Nome", flex: 1 },
    { id: "email", label: "e-mail", flex: 1 },
    { id: "permission", label: "permissão", flex: 1 },
    {
      id: "status",
      label: "status",
      flex: 1,
    },
    {
      id: "date_create",
      label: "criado",
      flex: 1,
    },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];

  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = users && users.slice(startIndex, endIndex);
  // const usersToShow =
  //   usersWithPerm &&
  //   usersWithPerm.map((item) => {
  //     const { permission, ...rest } = item;
  //     return rest;
  //   });

  // console.log("usersWithPerm", usersWithPerm);

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={openModalDeleteUser}
        setOpenModal={setOpenModalDeleteUser}
        title={`Deseja realmente excluir o usuário '${userRowData?.name}', de e-mail '${userRowData?.email}' ?`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteUser(userRowData.id)}
      />
      <ModalSmall
        openModal={openModalUpdateUser}
        setOpenModal={setOpenModalUpdateUser}
        title={`Deseja Ativar o usuário '${userRowData?.name}', de e-mail '${userRowData?.email}' ?`}
        btnLeftText="confirmar"
        callFunc={() => UpdateUser(userRowData.id)}
      />
      <ModalSmall
        openModal={alertModal}
        setOpenModal={setAlertModal}
        title={`Existem campos vazios!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={updatePermissionModal}
        setOpenModal={setUpdatePermissionModal}
        title={`Dados alterados com sucesso!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalDefault
        openModal={openModalEditCompany}
        setOpenModal={setOpenModalEditCompany}
        createCompany_image_url={createCompany_image_url}
        setCreateCompany_image_url={setCreateCompany_image_url}
        transferAreaInfo={transferAreaInfo}
        setTransferAreaInfo={setTransferAreaInfo}
        progressBar={progressBar}
        setProgressBar={setProgressBar}
        modalType="editar empresa"
        title="editar empresa"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          handleUpdateCompanyImg();
          UpdateCompanyImg();
        }}
      />
      <ModalDefault
        openModal={openModalCopyLink}
        setOpenModal={setOpenModalCopyLink}
        modalType="copiar link"
        title="copiar link"
        btnCloseModalText="voltar"
        company={userLogged?.company}
      />
      <ModalDefault
        openModal={openModalPermission}
        setOpenModal={setOpenModalPermission}
        modalType="permissões"
        title={`permissões - ${userRowData?.name}`}
        btnCloseModalText="voltar"
        setUserRowData={setUserRowPermission}
        NewPermission={NewPermission}
        btnLeftText="salvar"
        callFunc={() => {
          UpdateUsersPermission(userRowData.id);
        }}
      />
      <ModalDefault
        openModal={openModalNewCompany}
        setOpenModal={setOpenModalNewCompany}
        setOnchangeInfoTwo={setCreateCompany_admin}
        createCompany_image_url={createCompany_image_url}
        setCreateCompany_image_url={setCreateCompany_image_url}
        setOnchangeInfoOne={setCreateCompany_name}
        transferAreaInfo={transferAreaInfo}
        setTransferAreaInfo={setTransferAreaInfo}
        widthButton={widthButton}
        modalType="cadastro de empresa"
        title="cadastro de empresa"
        btnCloseModalText="voltar"
        btnLeftText="cadastrar empresa"
        callFunc={() => {
          alertModal && handleUpload();
          CreateCompany();
        }}
      />
      <ModalDefault
        openModal={openModalAllCompanies}
        setOpenModal={setOpenModalAllCompanies}
        modalType="empresas cadastradas"
        title="empresas cadastradas"
        btnCloseModalText="voltar"
        btnLeftText="cadastrar nova empresa"
        callFunc={() => {
          setOpenModalNewCompany(true);
          setOpenModalAllCompanies(false);
        }}
      />
      <CardTable
        title="Usuários Ativos no Sistema"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Administrador" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="empresas cadastradas"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                leftIcon={paperImg}
                rightIcon={plusImg}
                margin={widthButton < 768 ? "10px 0" : null}
                width={widthButton > 768 ? "210px" : "100%"}
                callFunc={() => setOpenModalAllCompanies(true)}
              />
              <GeneralButton
                text="criar nova empresa"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "185px" : "100%"}
                callFunc={() => setOpenModalNewCompany(true)}
              />
              <GeneralButton
                text="copiar link"
                fontSize="12px"
                border="1px solid"
                padding="10px 10px 8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                rightIcon={copyImg}
                width={widthButton > 768 ? "110px" : "100%"}
                callFunc={() => setOpenModalCopyLink(true)}
              />
              <GeneralButton
                text="editar empresa"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "160px" : "100%"}
                callFunc={() => setOpenModalEditCompany(true)}
              />
            </div>
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="usuário" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                {widthButton > 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "10px 16px 10px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "action" ? (
                                      (value = (
                                        <div
                                          className={classes.TableActionButtons}
                                        >
                                          <button
                                            className={classes.ActionButton}
                                            disabled={row.status === "ativo"}
                                            onClick={() => {
                                              setOpenModalUpdateUser(true);
                                              setUserRowData(row);
                                            }}
                                          >
                                            ativar
                                          </button>

                                          <button
                                            onClick={() => {
                                              setOpenModalDeleteUser(true);
                                              setUserRowData(row);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={deleteImg} alt="" />
                                          </button>
                                        </div>
                                      ))
                                    ) : column.id === "status" &&
                                      value === "pendente" ? (
                                      <div
                                        className={classes.TableStatusPending}
                                      >
                                        {value}
                                      </div>
                                    ) : column.id === "status" &&
                                      value === "ativo" ? (
                                      <div
                                        className={classes.TableStatusActive}
                                      >
                                        {value}
                                      </div>
                                    ) : column.id === "permission" ? (
                                      <div
                                        className={classes.TableActionButtons}
                                      >
                                        <button
                                          className={classes.ActionButton}
                                          // disabled={row.status === "pendente"}
                                          onClick={() => {
                                            setOpenModalPermission(true);
                                            setUserRowData(row);
                                            setUserRowPermission(
                                              row.permission
                                            );
                                          }}
                                        >
                                          editar
                                        </button>
                                      </div>
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
                {widthButton < 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columnsCollapse.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columnsCollapse.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "collapse" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ fontSize: "16px" }}>
                                          {row.status === "pendente" ? (
                                            <div
                                              className={
                                                classes.TableStatusPending
                                              }
                                            >
                                              {row.status}
                                            </div>
                                          ) : row.status === "ativo" ? (
                                            <div
                                              className={
                                                classes.TableStatusActive
                                              }
                                            >
                                              {row.status}
                                            </div>
                                          ) : (
                                            row.status
                                          )}
                                        </div>
                                        <div style={{ fontSize: "16px" }}>
                                          {row.name}
                                        </div>

                                        <IconButton
                                          sx={{
                                            color: "#0091d7",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => {
                                            setRowId(row.id);
                                            setOpen(!open);
                                          }}
                                        >
                                          <img
                                            style={{ width: "25px" }}
                                            src={
                                              open && row.id === rowId
                                                ? closeIcon
                                                : openIcon
                                            }
                                            alt=""
                                          />
                                        </IconButton>
                                      </div>
                                    ) : (
                                      value
                                    )}
                                    {row.id === rowId && (
                                      <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box
                                          sx={{
                                            margin: 1,
                                            color: "#FFF",
                                            fontSize: "11px",
                                          }}
                                        >
                                          <Table
                                            size="small"
                                            aria-label="purchases"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Itens
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Informações
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"E-MAIL:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.email}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"PERMISSÃO:"}
                                                </TableCell>
                                                <div
                                                  className={
                                                    classes.TableActionButtons
                                                  }
                                                >
                                                  <button
                                                    className={
                                                      classes.ActionButton
                                                    }
                                                    // disabled={
                                                    //   row.status === "pendente"
                                                    // }
                                                    onClick={() => {
                                                      setOpenModalPermission(
                                                        true
                                                      );
                                                      setUserRowData(row);
                                                      setUserRowPermission(
                                                        row.permission
                                                      );
                                                    }}
                                                  >
                                                    editar
                                                  </button>
                                                </div>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"STATUS:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.status}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"STATUS:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.date_create}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"AÇÕES:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  <div
                                                    className={
                                                      classes.TableActionButtons
                                                    }
                                                  >
                                                    <button
                                                      className={
                                                        classes.ActionButton
                                                      }
                                                      disabled={
                                                        row.status === "ativo"
                                                      }
                                                      onClick={() => {
                                                        setOpenModalUpdateUser(
                                                          true
                                                        );
                                                        setUserRowData(row);
                                                      }}
                                                    >
                                                      ativar
                                                    </button>

                                                    <button
                                                      onClick={() => {
                                                        setOpenModalDeleteUser(
                                                          true
                                                        );
                                                        setUserRowData(row);
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img
                                                        src={deleteImg}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Admin;
