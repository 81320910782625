import { useState, createContext, useEffect } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../Services/Firebase";
import { Navigate } from "react-router-dom";
import { firestore } from "../Services/Firebase";
import { collection, getDocs, where, query } from "firebase/firestore";

export const AuthGoogleContext = createContext({});

const AuthGoogleProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLogged, setUserLogged] = useState(null);
  const [searchName, setSearchName] = useState(false);
  const [refreshTopNav, setRefreshTopNav] = useState(false);
  const [topNavCall, setTopNavCall] = useState({
    ActiveAll: false,
    ExpireToday: false,
    ExpireMonth: false,
    ExpireAll: false,
  });

  const HandleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result?.user;
        setUser(user);
        sessionStorage.setItem("UserLogged:", true);
        sessionStorage.setItem("@AuthFirebase:token", token);
        sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const getUserByEmail = async () => {
      const usersCollection = collection(firestore, "users");
      const q = await getDocs(
        query(
          usersCollection,
          where("email", "==", user && user?.email),
          where("status", "==", "ativo")
        )
      );
      if (q.docs.length > 0) {
        const userData = q.docs[0].data();
        setUserLogged(userData);
      } else {
        setUserLogged(null); // Define como null se não encontrar o usuário
      }
    };
    const getCompanyByEmail = async () => {
      const CompanyCollection = collection(firestore, "company");
      const q = await getDocs(
        query(
          CompanyCollection,
          where("createCompany_admin", "==", user && user?.email)
        )
      );

      if (q.docs.length > 0) {
        const userData = q.docs[0].data();
        sessionStorage.setItem(
          "@AuthFirebase:company",
          JSON.stringify(userData)
        );
      } else {
        sessionStorage.setItem("@AuthFirebase:company", JSON.stringify(null)); // Define como null se não encontrar o usuário
      }
    };
    getCompanyByEmail();
    getUserByEmail();
  }, [user]);

  const SignOut = () => {
    sessionStorage.setItem("UserLogged:", false);
    sessionStorage.setItem("@AuthFirebase:token", null);
    sessionStorage.setItem("@AuthFirebase:user", null);
    setUser(null);
    setUserLogged(null);

    return <Navigate to="/" />;
  };

  useEffect(() => {
    const loadStorageAuth = () => {
      const sessionToken = sessionStorage.getItem("@AuthFirebase:token");
      const sessionUser = sessionStorage.getItem("@AuthFirebase:user");
      if (sessionToken && sessionUser) {
        setUser(JSON.parse(sessionUser));
      }
    };
    loadStorageAuth();
  }, []);

  return (
    <AuthGoogleContext.Provider
      value={{
        HandleGoogleSignIn,
        signed: !!userLogged,
        userLogged,
        SignOut,
        user,
        searchName,
        setSearchName,
        refreshTopNav,
        setRefreshTopNav,
        topNavCall,
        setTopNavCall,
      }}
    >
      {children}
    </AuthGoogleContext.Provider>
  );
};
export default AuthGoogleProvider;
