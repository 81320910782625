import classes from "./Spinner.module.scss";
import loading from "../../../Assets/loading.gif";

const Spinner = ({content = false}) => {
  return (
    <div
      className={classes.SpinnerMain}
      style={{ height: content ? "50vh" : "none" }}
    >
      <img className={classes.SpinnerCompanyImg} src={loading} alt="" />
      <div className={classes.SpinnerText}>carregando...</div>
    </div>
  );
};
export default Spinner;
