/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import classes from "./ModalDefault.module.scss";
import CloseCircle from "../../../Assets/close_circle.svg";
import DropImg from "../../../Assets/drop_image.svg";
import deleteImg from "../../../Assets/AdminPage/delete.svg";
import GeneralButton from "../../../Components/UI/GeneralButton/GeneralButton";
import paper_white from "../../../Assets/AdminPage/paper_white.svg";
import check from "../../../Assets/AdminPage/check.svg";
import copy from "clipboard-copy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { firestore } from "../../../Services/Firebase";
import { collection, getDocs } from "firebase/firestore";
import { Select, MenuItem, Input, FormLabel } from "@mui/material";
import * as XLSX from "xlsx";
import ModalCustomCopyInfo from "../CustomModals/ModalCustomCopyInfo/ModalCustomCopyInfo";
import ModalCustomSendWhats from "../CustomModals/ModalCustomSendWhats/ModalCustomSendWhats";
import ModalSmall from "../../../Components/Modals/ModalSmall/ModalSmall";
import copyImg from "../../../Assets/copy.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { IMaskInput } from "react-imask";
import Spinner from "../../UI/Spinner/Spinner";

const ModalDefault = ({
  openModal = null,
  setOpenModal = null,
  setOnchangeInfoOne = null,
  setOnchangeInfoTwo = null,
  setOnchangeInfoThree = null,
  setPlaylistNameSup,
  setUrlSup,
  setLinkM3uSup,
  setDans01Sup,
  setDans02Sup,
  setDans03Sup,
  setWebPlayerSup,
  setWebPlayerSupTwo,
  optionsBankGet,
  optionsSupplier,
  optionsApps,
  optionsClientPlan,
  setClientName,
  setClientBank,
  setClientPlanNamePost,
  setClientPlanValuePost,
  setClientExpirationDate,
  setClientFone,
  setClientLogin,
  excelData,
  setExcelData,
  setClientKey,
  setClientSupplier,
  setClientStatus,
  filterSupplier,
  setFilterSupplier,
  clientName,
  NewUserRowData,
  setUserRowData,
  userRowDataAll,
  clientStatus,
  userRowData,
  widthButton,
  createCompany_image_url,
  setCreateCompany_image_url,
  setBankName,
  setCampaignsName,
  title = null,
  callFunc,
  btnLeftText,
  isDelete = false,
  modalType = null,
  btnCloseModalText = null,
  transferAreaInfo,
  setTransferAreaInfo,
  company,
  users,
  progressBar,
  setProgressBar,
  optionsResalePlan,
  setResaleName,
  clientSupplier,
  clientPlanNamePost,
  amount,
  setAmount,
  costValue,
  setCostValue,
  costPerCreditString,
  setCostPerCreditString,
  setIndicatedBy,
  setObservation,
  selectedDate,
  setSelectedDate,
  getIsActive,
  setGetIsActive,
  btnRightText,
  NewPermission,
  callFuncRight,
  filter,
  selectedDateEdidClient,
  setSelectedDateEdidClient,
  hasSelectActive = false,
  selectedDateInicial,
  setSelectedDateInicial,
  selectedDateFinal,
  setSelectedDateFinal,
  callCsvList = false,
  callRegisteredUser,
  sendSMS,
  setSendSMS,
  loadingSms,
  optionsGetCampaigns,
  permission,
}) => {
  const [content, setContent] = useState(false);
  const [copyLink, setCopyLink] = useState("copiar link");
  const [serverInfo, setServerInfo] = useState();
  const [serverApp, setServerApp] = useState("Duplex");
  const [openModalCopyInfoCompany, setOpenModalCopyInfoCompany] =
    useState(false);

  const [companyInfo, setCompanyInfo] = useState("");
  const [companyRowData, setCompanyRowData] = useState("");

  const handleChangeBank = (event) => {
    setClientBank(event.target.value);
  };
  const handleChangeCampaigns = (event) => {
    setSendSMS((prevState) => ({
      ...prevState,
      message: event.target.value,
    }));
  };
  const handleServerInfo = useCallback(
    (event) => {
      setServerInfo(event.target.value, () => {});
    },
    [serverInfo]
  );

  const handleServerApp = (event) => {
    setServerApp(event.target.value);
  };

  useEffect(() => {
    setServerInfo(userRowData?.supplier);
  }, [userRowData]);

  const UserIndicated =
    users && users?.filter((user) => user.indicated_by == userRowData?.name);

  const UserIndicatedArray =
    users && UserIndicated.map((user) => ({ name: user.name }));

  const UserIndicatedLength =
    users && UserIndicated.map((user) => user.name).length;

  const optionsIndicatedGet =
    UserIndicatedArray &&
    UserIndicatedArray.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  const handleChangeClientPlan = (event) => {
    const selectedOption = optionsClientPlan.find(
      (option) => option.value === event.target.value
    );

    if (selectedOption) {
      setClientPlanNamePost(selectedOption.label);
      setClientPlanValuePost(selectedOption.value);
    }
  };

  const handleChangeSupplier = (event) => {
    setClientSupplier(event.target.value);
  };

  const handleChangeFilterSupplier = (event) => {
    setFilterSupplier(event.target.value);
  };

  const handleChangeFilterIsActive = (event) => {
    setGetIsActive(event.target.value);
  };

  // const handleDrop = (e) => {
  //   e.preventDefault();

  //   const files = e.dataTransfer.files;
  //   const file = files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];

  //     // Obtendo todas as linhas, exceto a primeira
  //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }).slice(1);
  //     setExcelData(jsonData);
  //     CallAgain();
  //   };

  //   reader.readAsArrayBuffer(file);
  // };

  const handleDropImg = useCallback((e) => {
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;

    if (files.length > 0) {
      // Atualiza o estado createCompany_image_url com o nome do primeiro arquivo da lista
      setCreateCompany_image_url(files[0].name);
    }

    handleFiles(files);
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Processando cada célula individualmente
        const results = XLSX.utils
          .sheet_to_json(worksheet, { header: "A", raw: false, defval: "" })
          .slice(1);

        setExcelData(results);
        CallAgain(results);
      };

      reader.readAsBinaryString(file);
    }
  };

  const [showClientList, setShowClientList] = useState(
    <input
      type="file"
      onChange={handleFileUpload}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
  );

  const showClientListRef = useRef(showClientList);
  const serverInfoRef = useRef(serverInfo);
  const serverAppRef = useRef(serverApp);
  useEffect(() => {
    showClientListRef.current = showClientList;
    serverInfoRef.current = serverInfo;
    serverAppRef.current = serverApp;
    setServerInfo(serverInfoRef.current);
    setServerApp(serverAppRef.current);
  }, [showClientList, serverInfo, serverApp]);

  useEffect(() => {
    if (amount) setAmount();
  }, [openModal]);

  useEffect(() => {
    if (progressBar) setProgressBar("");
  }, [openModal]);

  const columns = [
    { id: "createCompany_name", label: "empresa", flex: 1 },
    { id: "createCompany_admin", label: "e-mail", flex: 1 },
    {
      id: "link",
      label: "link",
      flex: 1,
    },
    {
      id: "date_create",
      label: "criação",
      flex: 1,
    },
  ];

  const columnsExcel = [
    { id: "fornecedor", label: "fornecedor", minWidth: 30 },
    { id: "nome", label: "nome", minWidth: 30 },
    { id: "plano cliente", label: "plano cliente", minWidth: 30 },
    { id: "valor plano", label: "valor plano", minWidth: 30 },
    { id: "expiração", label: "expiração", minWidth: 30 },
    { id: "mês criação", label: "mês criação", minWidth: 30 },
    { id: "obs", label: "obs", minWidth: 30 },
    { id: "banco", label: "banco", minWidth: 30 },
    { id: "telefone", label: "telefone", minWidth: 30 },
    { id: "login", label: "login", minWidth: 30 },
    { id: "senha", label: "senha", minWidth: 30 },
  ];

  function formatarData(data) {
    // Verifica se a variável data é uma string
    if (typeof data !== "string") {
      return data; // Retorna a data original se não for uma string
    }

    const partes = data.split("/");

    let dia = String(partes[1]).padStart(2, "0");
    let mes = String(partes[0]).padStart(2, "0");
    let ano = String(partes[2]).padStart(2, "0"); // Por padrão, assume que o ano está no formato de dois dígitos

    // Se o ano for de dois dígitos e estiver no formato personalizado do Excel (yy), converta-o para o formato de quatro dígitos (yyyy)
    if (ano.length === 2) {
      ano = "20" + ano;
    }

    return `${dia}/${mes}/${ano}`;
  }

  const CallAgain = (excelValues) => {
    setShowClientList(
      <TableContainer sx={{ maxHeight: "55vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columnsExcel.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    height: "20px",
                    color: "#0091D7",
                    textTransform: "uppercase",
                    borderColor: "#275370",
                    fontSize: "10px",
                    padding: "10px 10px",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {excelValues &&
              excelValues.map(
                (
                  row,
                  rowIndex // Adicionando o índice da linha
                ) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {" "}
                    {/* Usando rowIndex como chave */}
                    {Object.values(row).map(
                      (
                        value,
                        columnIndex // Mapeando os valores do objeto
                      ) => (
                        <TableCell
                          className={classes.TableCell}
                          style={{
                            padding: "10px 10px",
                            color: "#fff",
                            borderColor: "#275370",
                            marginRight: "20px",
                            fontSize: "10px",
                          }}
                          key={columnIndex} // Usando columnIndex como chave
                          align="left" // Ajustando o alinhamento para 'left', ou ajuste conforme necessário
                        >
                          {columnIndex === 4 ? formatarData(value) : value}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const page = 0;
  const rowsPerPage = 5;

  useEffect(() => {
    const getUsers = async () => {
      const query = collection(firestore, "company");

      try {
        const data = await getDocs(query);
        setCompanyInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    setShowClientList(
      <input
        type="file"
        onChange={handleFileUpload}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    );
  }, [callRegisteredUser]);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = companyInfo.slice(startIndex, endIndex);

  const handleDropCsv = useCallback((e) => {
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;

    if (files.length > 0) {
      setCreateCompany_image_url(files[0]); // Update createCompany_image_url with the file object
    }

    handleFiles(files);
  }, []);

  const preventDefaults = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const highlight = useCallback(() => {
    // Add highlight styles here, if desired
  }, []);

  const unhighlight = useCallback(() => {
    // Remove highlight styles here, if desired
  }, []);

  const resetTransferArea = useCallback(() => {
    setCreateCompany_image_url(null);
    setTransferAreaInfo({
      text: "Selecione ou arraste uma imagem",
      format: "Formatos: JPEG, PNG, PDF e SVG",
    });
    const dropArea = document.getElementById("drop-area");
    dropArea.innerHTML = "";
    const img = document.createElement("img");
    img.className = classes.ImageDrop;
    img.src = DropImg;
    dropArea.appendChild(img);
  }, []);

  const handleFiles = useCallback((files) => {
    for (const file of files) {
      if (file.type.startsWith("image/")) {
        setCreateCompany_image_url(file); // Store the file object
        const fileName2 = file.name;

        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;

          const dropArea = document.getElementById("drop-area");

          dropArea.innerHTML = ""; // Clear drop area
          dropArea.appendChild(img);

          setTransferAreaInfo({
            text: "Imagem selecionada",
            format: `Nome do arquivo: ${fileName2}`,
          });
        };

        reader.readAsDataURL(file);
      } else {
        alert("Por favor, selecione apenas imagens.");
      }
    }
  }, []);

  const handleCopyLink = () => {
    copy(`https://mpadmin-d4396.web.app/cadastro/${company}`);
  };

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  useEffect(() => {
    setContent(
      openModal ? (
        <div className={classes.ModalDefault}>
          <div className={classes.ModalDefaultTop}>
            <div className={classes.Title}>{title}</div>
            <button
              className={classes.ButtonClose}
              onClick={() => {
                setOpenModal(false);
                callCsvList &&
                  setShowClientList(
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  );
              }}
            >
              <img src={CloseCircle} alt="close button" />
            </button>
          </div>
          <div className={classes.ModalDefaultBottom}>
            <ModalSmall
              openModal={openModalCopyInfoCompany}
              setOpenModal={setOpenModalCopyInfoCompany}
              companyRowData={companyRowData}
              title={`Deseja realmente excluir o usuário '${userRowData?.name}'`}
              modalType={"sendCompanyLink"}
              isDelete={false}
              // callFunc={() => DeleteClient(userRowData.id)}
            />
            {modalType === "editar empresa" && (
              <div className={classes.ImageArea}>
                <div
                  className={classes.TransferArea}
                  id="drop-area"
                  onDrop={handleDropImg}
                  onDragOver={preventDefaults}
                  onDragEnter={highlight}
                  onDragLeave={unhighlight}
                  onDragEnd={unhighlight}
                  onDragExit={unhighlight}
                  onDragStart={preventDefaults}
                >
                  <img className={classes.ImageDrop} src={DropImg} alt="" />
                  <label htmlFor="fileInput" className={classes.TransferText}>
                    {transferAreaInfo.text}
                    <div className={classes.TransferTextImage}>
                      {transferAreaInfo.format}
                    </div>
                  </label>
                </div>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length > 0) {
                      setCreateCompany_image_url(files[0]); // Update createCompany_image_url with the file object
                      handleFiles(files);
                    }
                  }}
                />
                <div>
                  {transferAreaInfo.text === "Imagem selecionada" && (
                    <div>
                      {progressBar}
                      <div className={classes.DescImageSelected}>
                        Imagem selecionada
                      </div>
                      <div className={classes.ImageSelectedArea}>
                        <div>
                          {createCompany_image_url
                            ? createCompany_image_url.name
                            : ""}
                        </div>
                        <button
                          className={classes.DeleteButton}
                          onClick={() => resetTransferArea()}
                        >
                          <img src={deleteImg} alt="" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {modalType === "copiar link" && (
              <div className={classes.CopyLinkArea}>
                <div className={classes.CopyLinkTopText}>
                  Copie o link abaixo e envie para os usuários que você deseja
                  adicionar
                </div>
                <div className={classes.CopyLinkBottomButton}>
                  <div className={classes.Link}>
                    {`https://mpadmin-d4396.web.app/cadastro/${company}`}
                  </div>
                  <GeneralButton
                    text={copyLink}
                    fontSize="12px"
                    padding="8px 10px"
                    rightIcon={copyLink === "copiar link" ? paper_white : check}
                    backgroundColor="#3487BE"
                    color="#CACACA"
                    width="115px"
                    callFunc={() => {
                      handleCopyLink();
                      setCopyLink("link copiado");
                    }}
                  />
                </div>
              </div>
            )}
            {modalType === "cadastro de empresa" && (
              <div className={classes.NewCompanyArea}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome da Empresa
                    </FormLabel>
                    <Input
                      onChange={(e) => setOnchangeInfoOne(e.target.value)}
                      placeholder="Digite um login"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      E-mail do Admin
                    </FormLabel>
                    <Input
                      onChange={(e) => setOnchangeInfoTwo(e.target.value)}
                      placeholder="Digite uma senha"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ImageArea}>
                  <div
                    className={classes.TransferArea}
                    id="drop-area"
                    onDrop={handleDropImg}
                    onDragOver={preventDefaults}
                    onDragEnter={highlight}
                    onDragLeave={unhighlight}
                    onDragEnd={unhighlight}
                    onDragExit={unhighlight}
                    onDragStart={preventDefaults}
                  >
                    <img className={classes.ImageDrop} src={DropImg} alt="" />
                    <label htmlFor="fileInput" className={classes.TransferText}>
                      {transferAreaInfo.text}
                      <div className={classes.TransferTextImage}>
                        {transferAreaInfo.format}
                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files.length > 0) {
                        setCreateCompany_image_url(files[0]); // Update createCompany_image_url with the file object
                        handleFiles(files);
                      }
                    }}
                  />
                  <div>
                    {transferAreaInfo.text === "Imagem selecionada" && (
                      <div>
                        <div className={classes.DescImageSelected}>
                          Imagem selecionada
                        </div>
                        <div className={classes.ImageSelectedArea}>
                          <div>
                            {createCompany_image_url
                              ? createCompany_image_url.name
                              : ""}
                          </div>
                          <button
                            className={classes.DeleteButton}
                            onClick={() => resetTransferArea()}
                          >
                            <img src={deleteImg} alt="" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {modalType === "empresas cadastradas" && (
              <div className={classes.AllCompaniesArea}>
                <TableContainer sx={{ maxHeight: "55vh" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyInfo &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      padding: "10px 16px 10px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "link" ? (
                                      <button
                                        onClick={() => {
                                          setCompanyRowData(row);
                                          setOpenModalCopyInfoCompany(true);
                                        }}
                                        className={classes.DeleteButton}
                                      >
                                        <img src={copyImg} alt="" />
                                      </button>
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            {modalType === "cadastro de plano" && (
              <div className={classes.ClientPlanArea}>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Digite o nome"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "23vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "80vw",
                        maxWidth: widthButton > "768" ? "300px" : "481px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setOnchangeInfoOne(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Valor
                    </FormLabel>
                    <IMaskInput
                      onChange={(e) =>
                        setOnchangeInfoTwo(
                          e.target.value.replace(/\s/g, "").replace("R$", "")
                        )
                      }
                      placeholder="R$ 00,00"
                      style={{
                        position: "relative",
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "75vw",
                        maxWidth: widthButton > "768" ? "282px" : "460px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        backgroundColor: "#212c32",
                        outline: "none",
                      }}
                      mask="R$ 00"
                      definitions={{
                        "#": /[1-9]/,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "cadastro de banco" && (
              <div className={classes.ClientBankArea}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome do banco
                    </FormLabel>
                    <Input
                      placeholder="Nome do banco"
                      onChange={(e) => setBankName(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "cadastro de fornecedor" && (
              <div className={classes.ClientArea}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Central"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "48vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "580px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setOnchangeInfoOne(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Playlist name
                    </FormLabel>
                    <Input
                      placeholder="TV"
                      onChange={(e) => setPlaylistNameSup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {`Url (app)`}
                    </FormLabel>
                    <Input
                      placeholder="smart.cms-central.ovh"
                      onChange={(e) => setUrlSup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 01
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) => setDans01Sup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 02
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) => setDans02Sup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 03
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) => setDans03Sup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Link m3u (sigla): "}
                    </FormLabel>
                    <Input
                      placeholder="5ce"
                      onChange={(e) => setLinkM3uSup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Web player
                    </FormLabel>
                    <Input
                      placeholder="www.webplayer.com"
                      onChange={(e) => setWebPlayerSup(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Web player 2
                    </FormLabel>
                    <Input
                      placeholder="www.webplayer.com"
                      onChange={(e) => setWebPlayerSupTwo(e.target.value)}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "editar fornecedor" && (
              <div className={classes.ClientArea}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Central"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "48vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "580px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          name: e.target.value,
                        })
                      }
                      value={userRowData.name}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Playlist name
                    </FormLabel>
                    <Input
                      placeholder="TV"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          playlist_name: e.target.value,
                        })
                      }
                      value={userRowData.playlist_name}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {`Url (app)`}
                    </FormLabel>
                    <Input
                      placeholder="smart.cms-central.ovh"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          url: e.target.value,
                        })
                      }
                      value={userRowData.url}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 01
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          dns_number_one: e.target.value,
                        })
                      }
                      value={userRowData.dns_number_one}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 02
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          dns_number_two: e.target.value,
                        })
                      }
                      value={userRowData.dns_number_two}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Dns 03
                    </FormLabel>
                    <Input
                      placeholder="999.999.99.999"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          dns_number_three: e.target.value,
                        })
                      }
                      value={userRowData.dns_number_three}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Link m3u (sigla): "}
                    </FormLabel>
                    <Input
                      placeholder="5ce"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          m3u_link: e.target.value,
                        })
                      }
                      value={userRowData.m3u_link}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Web player
                    </FormLabel>
                    <Input
                      placeholder="www.webplayer.com"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          web_player: e.target.value,
                        })
                      }
                      value={userRowData.web_player}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Web player 2
                    </FormLabel>
                    <Input
                      placeholder="www.webplayer.com"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          web_player_two: e.target.value,
                        })
                      }
                      value={userRowData.web_player_two}
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "cadastrar cliente" && (
              <div className={classes.ClientArea}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Digite o nome"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "48vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "580px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Telefone
                    </FormLabel>
                    <IMaskInput
                      onChange={(e) => setClientFone(e.target.value)}
                      placeholder="00(00) 00000-0000"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "85vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        backgroundColor: "#212c32",
                        outline: "none",
                      }}
                      mask="00(00) 0 0000-0000"
                      definitions={{
                        "#": /[1-9]/,
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Banco
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "282px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeBank}
                      placeholder="te"
                    >
                      {optionsBankGet.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.ClientButtonsZoneThree}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Fornecedor
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeSupplier}
                    >
                      {optionsSupplier.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Plano
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeClientPlan}
                    >
                      {optionsClientPlan.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Vencimento
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(date) => setSelectedDateEdidClient(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "14vw"
                                : widthButton > "768"
                                ? "28vw"
                                : "90vw",
                            maxWidth: widthButton > "768" ? "194px" : null,
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Login
                    </FormLabel>
                    <Input
                      onChange={(e) => setClientLogin(e.target.value)}
                      placeholder="Digite um login"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Senha
                    </FormLabel>
                    <Input
                      onChange={(e) => setClientKey(e.target.value)}
                      placeholder="Digite uma senha"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Indicado por
                    </FormLabel>
                    <Input
                      onChange={(e) => setIndicatedBy(e.target.value)}
                      placeholder="Digite um nome"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "32vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "196px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Obs
                    </FormLabel>
                    <Input
                      onChange={(e) => setObservation(e.target.value)}
                      placeholder="Digite alguma observação"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "29.5vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "402px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "importar cliente" && (
              <div className={classes.ImageAreaExcell}>
                {
                  <div
                    className={classes.TransferArea}
                    id="drop-area"
                    onDrop={handleDropCsv}
                  >
                    {showClientListRef.current}
                  </div>
                }
              </div>
            )}
            {modalType === "editar cliente" && (
              <div className={classes.ClientArea}>
                <div className={classes.ClientButtonsName}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      value={userRowData.name}
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Placeholder"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "48vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "580px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Telefone
                    </FormLabel>
                    <IMaskInput
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          fone: e.target.value,
                        })
                      }
                      value={userRowData.fone.replace(/\D/g, "")}
                      placeholder="00(00) 00000-0000"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "85vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        backgroundColor: "#212c32",
                        outline: "none",
                      }}
                      mask="00(00) 0 0000-0000"
                      definitions={{
                        "#": /[1-9]/,
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Banco
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "282px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          bank: e.target.value,
                        })
                      }
                      value={userRowData.bank}
                    >
                      {optionsBankGet.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.ClientButtonsZoneThree}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Fornecedor
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          supplier: e.target.value,
                        })
                      }
                      value={userRowData.supplier}
                    >
                      {optionsSupplier.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Plano
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "28vw"
                            : "90vw",
                        maxWidth: widthButton > "768" ? "194px" : "100vw",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => {
                        const selectedOption = optionsClientPlan.find(
                          (option) => option.value === e.target.value
                        );

                        if (selectedOption) {
                          setUserRowData({
                            ...NewUserRowData,
                            client_plan: selectedOption.label,
                            plan_value: selectedOption.value,
                          });
                        }
                      }}
                      value={userRowData.plan_value}
                    >
                      {optionsClientPlan.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Vencimento
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={
                          selectedDateEdidClient
                            ? selectedDateEdidClient
                            : dayjs(userRowData.expiration_date, "DD/MM/YYYY")
                        }
                        onChange={(date) => setSelectedDateEdidClient(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "14vw"
                                : widthButton > "768"
                                ? "28vw"
                                : "90vw",
                            maxWidth: widthButton > "768" ? "194px" : null,
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Login
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          login: e.target.value,
                        })
                      }
                      value={userRowData.login}
                      placeholder="Placeholder"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Senha
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          senha: e.target.value,
                        })
                      }
                      value={userRowData.senha}
                      placeholder="Placeholder"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                </div>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Indicado por
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          indicated_by: e.target.value,
                        })
                      }
                      value={userRowData.indicated_by}
                      placeholder="Digite um nome"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "14vw"
                            : widthButton > "768"
                            ? "32vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "196px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Obs
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        setUserRowData({
                          ...NewUserRowData,
                          obs: e.target.value,
                        })
                      }
                      value={userRowData.obs}
                      placeholder="Digite alguma observação"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "29.5vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "402px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "copiar informações" && (
              <div className={classes.CopyInfoArea}>
                <div
                  className={classes.ClientButtons}
                  style={{ flexDirection: "column" }}
                >
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Fornecedor
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "50vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "600px",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleServerInfo}
                      value={serverInfo}
                    >
                      {optionsSupplier.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Aplicativo
                    </FormLabel>
                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "50vw"
                            : widthButton > "768"
                            ? "58vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "768" && "600px",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleServerApp}
                      value={serverApp}
                    >
                      {optionsApps.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <ModalCustomCopyInfo
                  stepSupplier={serverInfoRef.current}
                  userRowData={userRowData}
                  serverApp={serverAppRef.current}
                />
              </div>
            )}
            {modalType === "indicações" && (
              <div className={classes.IndicatedArea}>
                <div className={classes.IndicatedTop}>
                  <div>
                    <label style={{ color: "#fff" }}>Indicado por:</label>{" "}
                    {userRowData.indicated_by
                      ? userRowData.indicated_by
                      : "Sem indicação"}
                  </div>
                  <div>
                    <label style={{ color: "#fff" }}>Indicou:</label>{" "}
                    {`${UserIndicatedLength} usuários`}
                  </div>
                </div>
                <div className={classes.IndicatedBottom}>
                  <div className={classes.ClientButtons}>
                    <div className={classes.ButtonArea}>
                      <FormLabel
                        style={{
                          color: "#3487be",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                        }}
                      >
                        Lista de Indicados
                      </FormLabel>

                      <Select
                        disabled={optionsIndicatedGet.length === 0}
                        style={{
                          border: "1.5px solid #3487be",
                          borderRadius: "5px",
                          color: "#3487be",
                          padding: "0 10px",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                          height: "30px",
                          minWidth:
                            widthButton > "1200"
                              ? "21vw"
                              : widthButton > "768"
                              ? "30vw"
                              : "90vw",
                        }}
                        sx={{
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como vermelho
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        {optionsIndicatedGet.map((option) => (
                          <MenuItem
                            sx={{
                              color: "#3487be",
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modalType === "lista envio whatsapp" && (
              <ModalCustomSendWhats userRowDataAll={userRowDataAll} />
            )}
            {modalType === "cadastrar revenda" && (
              <div className={classes.ClientPlanArea}>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Nome
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Digite o nome"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "23vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "80vw",
                        maxWidth: widthButton > "768" ? "300px" : "481px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setResaleName(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Fornecedor
                    </FormLabel>

                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "23vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "80vw",
                        maxWidth: widthButton > "768" ? "300px" : "481px",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeSupplier}
                    >
                      {optionsSupplier.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {modalType === "recarga de revenda" && (
              <div className={classes.ClientAreaAddCredit}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Quantidade de créditos
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Digite a quantidade"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "45vw"
                            : widthButton > "768"
                            ? "80vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "1200"
                            ? "640px"
                            : widthButton > "768"
                            ? "600px"
                            : null,
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Valor total do lote
                    </FormLabel>
                    <IMaskInput
                      onChange={(e) =>
                        setCostValue(
                          e.target.value.replace(/\s/g, "").replace("R$", "")
                        )
                      }
                      placeholder="Digite o valor pago"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "85vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        backgroundColor: "#212c32",
                        outline: "none",
                      }}
                      mask="R$ 000000"
                      definitions={{
                        "#": /[1-9]/,
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Valor de custo por crédito
                    </FormLabel>
                    <Input
                      // disabled
                      value={
                        costPerCreditString &&
                        formatarValor(
                          costPerCreditString
                            .replace(/\s/g, "")
                            .replace("R$", "")
                        )
                      }
                      placeholder="R$ 0,00"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "cadastro de crédito" && (
              <div className={classes.ClientAreaAddCredit}>
                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Quantidade de créditos
                    </FormLabel>
                    <Input
                      // size="small"
                      // variant="outlined"
                      placeholder="Digite a quantidade"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "45vw"
                            : widthButton > "768"
                            ? "80vw"
                            : "90vw",
                        maxWidth:
                          widthButton > "1400"
                            ? "640px"
                            : widthButton > "1200"
                            ? "640px"
                            : widthButton > "768"
                            ? "600px"
                            : null,
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div className={classes.ClientButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Valor total do lote
                    </FormLabel>
                    <IMaskInput
                      onChange={(e) =>
                        setCostValue(
                          e.target.value.replace(/\s/g, "").replace("R$", "")
                        )
                      }
                      placeholder="Digite o valor pago"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "85vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        backgroundColor: "#212c32",
                        outline: "none",
                      }}
                      mask="R$ 000000"
                      definitions={{
                        "#": /[1-9]/,
                      }}
                    />
                  </div>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Valor de custo por crédito
                    </FormLabel>
                    <Input
                      // disabled
                      value={
                        costPerCreditString &&
                        formatarValor(
                          costPerCreditString
                            .replace(/\s/g, "")
                            .replace("R$", "")
                        )
                      }
                      placeholder="R$ 0,00"
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        width:
                          widthButton > "1200"
                            ? "21vw"
                            : widthButton > "768"
                            ? "38vw"
                            : "90vw",
                        maxWidth: widthButton > "768" && "282px",
                        height: "30px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                      // slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </div>
                </div>
              </div>
            )}
            {modalType === "filtros" && (
              <div className={classes.ClientAreaAddCredit}>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Data Inicial"}
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={selectedDateInicial ? selectedDateInicial : null}
                        onChange={(date) => setSelectedDateInicial(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "23vw"
                                : widthButton > "768"
                                ? "50vw"
                                : "80vw",
                            maxWidth: widthButton > "768" ? "300px" : "481px",
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <br />
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Data Final"}
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={selectedDateFinal ? selectedDateFinal : null}
                        onChange={(date) => setSelectedDateFinal(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "23vw"
                                : widthButton > "768"
                                ? "50vw"
                                : "80vw",
                            maxWidth: widthButton > "768" ? "300px" : "481px",
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {hasSelectActive && (
                  <div className={classes.ClientPlanButtons}>
                    <div className={classes.ButtonArea}>
                      <FormLabel
                        style={{
                          color: "#3487be",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                        }}
                      >
                        Ativo
                      </FormLabel>

                      <Select
                        style={{
                          border: "1.5px solid #3487be",
                          borderRadius: "5px",
                          color: "#3487be",
                          padding: "0 10px",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                          height: "30px",
                          width:
                            widthButton > "1200"
                              ? "23vw"
                              : widthButton > "768"
                              ? "50vw"
                              : "80vw",
                          maxWidth: widthButton > "768" ? "300px" : "481px",
                        }}
                        sx={{
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como vermelho
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleChangeFilterIsActive}
                        value={getIsActive}
                      >
                        <MenuItem value={"ativo"}>Sim</MenuItem>
                        <MenuItem value={"inativo"}>Não</MenuItem>
                      </Select>
                    </div>
                  </div>
                )}
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      Fornecedor
                    </FormLabel>

                    <Select
                      style={{
                        border: "1.5px solid #3487be",
                        borderRadius: "5px",
                        color: "#3487be",
                        padding: "0 10px",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                        height: "30px",
                        width:
                          widthButton > "1200"
                            ? "23vw"
                            : widthButton > "768"
                            ? "50vw"
                            : "80vw",
                        maxWidth: widthButton > "768" ? "300px" : "481px",
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#3487be", // Define a cor do ícone como vermelho
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeFilterSupplier}
                      value={filterSupplier}
                    >
                      {optionsSupplier.map((option) => (
                        <MenuItem
                          sx={{
                            color: "#3487be",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {modalType === "filtros data" && (
              <div className={classes.ClientAreaAddCredit}>
                <div className={classes.ClientPlanButtons}>
                  <div className={classes.ButtonArea}>
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Data Inicial"}
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={selectedDateInicial ? selectedDateInicial : null}
                        onChange={(date) => setSelectedDateInicial(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "23vw"
                                : widthButton > "768"
                                ? "50vw"
                                : "80vw",
                            maxWidth: widthButton > "768" ? "300px" : "481px",
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <br />
                    <FormLabel
                      style={{
                        color: "#3487be",
                        fontSize: widthButton > "768" ? "14px" : "12px",
                      }}
                    >
                      {"Data Final"}
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={selectedDateFinal ? selectedDateFinal : null}
                        onChange={(date) => setSelectedDateFinal(date)}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#3487be",
                            height: "30px",
                            width:
                              widthButton > "1200"
                                ? "23vw"
                                : widthButton > "768"
                                ? "50vw"
                                : "80vw",
                            maxWidth: widthButton > "768" ? "300px" : "481px",
                            borderColor: "#3487be", // Cor da borda
                            borderWidth: "2px", // Largura da borda
                            borderStyle: "solid", // Estilo da borda
                            borderRadius: "4px", // Raio da borda
                          },
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            )}
            {modalType === "enviar sms" && (
              <div className={classes.ClientAreaAddCredit}>
                {loadingSms ? (
                  <Spinner />
                ) : (
                  <div className={classes.ClientPlanButtons}>
                    <div className={classes.ButtonArea}>
                      <FormLabel
                        style={{
                          color: "#3487be",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                        }}
                      >
                        Selecione a campanha que deseja enviar:
                      </FormLabel>
                      <Select
                        style={{
                          border: "1.5px solid #3487be",
                          borderRadius: "5px",
                          color: "#3487be",
                          padding: "0 10px",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                          height: "30px",
                          width:
                            widthButton > "1200"
                              ? "38vw"
                              : widthButton > "768"
                              ? "38vw"
                              : "90vw",
                          maxWidth: widthButton > "768" ? "450px" : "100vw",
                        }}
                        sx={{
                          "& .MuiSelect-icon": {
                            color: "#3487be", // Define a cor do ícone como vermelho
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleChangeCampaigns}
                        placeholder="te"
                      >
                        {optionsGetCampaigns.map((option) => (
                          <MenuItem
                            sx={{
                              color: "#3487be",
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
            )}
            {modalType === "cadastro de campanha" && (
              <div className={classes.ClientAreaAddCredit}>
                {loadingSms ? (
                  <Spinner />
                ) : (
                  <div className={classes.ClientPlanButtons}>
                    <div className={classes.ButtonArea}>
                      <FormLabel
                        style={{
                          color: "#3487be",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                        }}
                      >
                        {`Mensagem - Utilize "{name}" quando quiser referenciar o nome do cliente`}
                      </FormLabel>
                      <Input
                        multiline
                        // size="small"
                        // variant="outlined"
                        placeholder="Digite sua mensagem"
                        style={{
                          border: "1.5px solid #3487be",
                          wordWrap: "break-word",
                          borderRadius: "5px",
                          color: "#3487be",
                          padding: "0 10px",
                          width:
                            widthButton > "1200"
                              ? "48vw"
                              : widthButton > "768"
                              ? "58vw"
                              : "90vw",
                          maxWidth:
                            widthButton > "1400"
                              ? "640px"
                              : widthButton > "768" && "580px",
                          height: "90px",
                          fontSize: widthButton > "768" ? "14px" : "12px",
                        }}
                        onChange={(e) => setCampaignsName(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {modalType === "permissões" && (
              <div className={classes.PermissionContent}>
                {loadingSms ? (
                  <Spinner />
                ) : (
                  <div className={classes.PermissionArea}>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "dashboard" }}
                    >
                      <div className={classes.Title}>Dashboard</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              dashboard: !NewPermission.dashboard,
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.dashboard}
                            id=""
                          />
                          <label htmlFor="te">Dashboard</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "credits" }}
                    >
                      <div className={classes.Title}>Créditos</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              credits: {
                                ...NewPermission.credits,
                                bank: !NewPermission.credits.bank,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.credits.bank}
                            id=""
                          />
                          <label htmlFor="te">Banco</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              credits: {
                                ...NewPermission.credits,
                                credit_list: !NewPermission.credits.credit_list,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.credits.credit_list}
                            id=""
                          />
                          <label htmlFor="te">Lista de Créditos</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              credits: {
                                ...NewPermission.credits,
                                history: !NewPermission.credits.history,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.credits.history}
                            id=""
                          />
                          <label htmlFor="te">Histórico</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              credits: {
                                ...NewPermission.credits,
                                supplier: !NewPermission.credits.supplier,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.credits.supplier}
                            id=""
                          />
                          <label htmlFor="te">Fornecedor</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "clients" }}
                    >
                      <div className={classes.Title}>Clientes</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              clients: {
                                ...NewPermission.clients,
                                clients: !NewPermission.clients.clients,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.clients.clients}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Clientes</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              clients: {
                                ...NewPermission.clients,
                                plans: !NewPermission.clients.plans,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.clients.plans}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Planos</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              clients: {
                                ...NewPermission.clients,
                                report: !NewPermission.clients.report,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.clients.report}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Relatórios</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              clients: {
                                ...NewPermission.clients,
                                sms_history: !NewPermission.clients.sms_history,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.clients.sms_history}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">SMS Histórico </label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              clients: {
                                ...NewPermission.clients,
                                sms_campaign:
                                  !NewPermission.clients.sms_campaign,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.clients.sms_campaign}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">SMS Campanha </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "resale" }}
                    >
                      <div className={classes.Title}>Revenda</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              resale: {
                                ...NewPermission.resale,
                                resale: !NewPermission.resale.resale,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.resale.resale}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Revendas</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              resale: {
                                ...NewPermission.resale,
                                financial_history:
                                  !NewPermission.resale.financial_history,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.resale.financial_history}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Histórico Finan.</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              resale: {
                                ...NewPermission.resale,
                                report: !NewPermission.resale.report,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.resale.report}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Relatórios</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "admin" }}
                    >
                      <div className={classes.Title}>Admin</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              admin: !NewPermission.admin,
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            type="checkbox"
                            name="tes"
                            checked={NewPermission.admin}
                            id=""
                          />
                          <label htmlFor="te">Administrador</label>
                        </div>{" "}
                      </div>
                    </div>
                    <div
                      className={classes.Column}
                      style={{ gridArea: "header" }}
                    >
                      <div className={classes.Title}>Header</div>
                      <div className={classes.PermissionItems}>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                active: !NewPermission.top_nav_btn.active,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.active}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Ativos</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                expire_today:
                                  !NewPermission.top_nav_btn.expire_today,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.expire_today}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Vence Hoje</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                expire_month:
                                  !NewPermission.top_nav_btn.expire_month,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.expire_month}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Vence Mês</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                expire_all:
                                  !NewPermission.top_nav_btn.expire_all,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.expire_all}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Vence Total</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                resale: !NewPermission.top_nav_btn.resale,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.resale}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Revendas</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                monthly_billing:
                                  !NewPermission.top_nav_btn.monthly_billing,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.monthly_billing}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Fat. Mensal</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                profit_month:
                                  !NewPermission.top_nav_btn.profit_month,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.profit_month}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Lucro Mês</label>
                        </div>
                        <div
                          onClick={(e) =>
                            setUserRowData({
                              ...NewPermission,
                              top_nav_btn: {
                                ...NewPermission.top_nav_btn,
                                credit_cart:
                                  !NewPermission.top_nav_btn.credit_cart,
                              },
                            })
                          }
                          className={classes.Item}
                        >
                          <input
                            checked={NewPermission.top_nav_btn.credit_cart}
                            type="checkbox"
                            name="tes"
                            id=""
                          />
                          <label htmlFor="te">Carrinho</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={classes.ButtonsArea}>
              {btnLeftText && (
                <button
                  onClick={callFunc}
                  type="submit"
                  className={
                    isDelete ? classes.ButtonDelete : classes.ButtonConfirm
                  }
                >
                  {btnLeftText}
                </button>
              )}
              {btnRightText && (
                <button
                  onClick={callFuncRight}
                  type="submit"
                  className={classes.ButtonCancel}
                >
                  {btnRightText}
                </button>
              )}
              {btnCloseModalText && (
                <button
                  onClick={() => {
                    setOpenModal(false);
                    callCsvList &&
                      setShowClientList(
                        <input
                          type="file"
                          onChange={handleFileUpload}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      );
                  }}
                  className={classes.ButtonCancel}
                >
                  {btnCloseModalText}
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null
    );
  }, [
    openModal,
    createCompany_image_url,
    transferAreaInfo,
    resetTransferArea,
    copyLink,
    excelData,
    showClientList,
    serverInfo,
    serverApp,
    userRowData,
    openModalCopyInfoCompany,
    progressBar,
    clientSupplier,
    clientPlanNamePost,
    widthButton,
    costPerCreditString,
    NewUserRowData,
    clientStatus,
    getIsActive,
    NewPermission,
    selectedDate,
    filterSupplier,
    filter,
    serverInfoRef.current,
    loadingSms,
  ]);

  return content;
};

export default ModalDefault;
