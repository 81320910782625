import classes from "./CardTable.module.scss";
import filterIcon from "../../Assets/filters.svg";
import { useContext } from "react";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import { Checkbox } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const CardTable = ({
  title = null,
  table = null,
  pagination = null,
  cardTop = null,
  FilterAreaCallFunc,
  hasFilterArea,
  hasFilterName,
  setFilterNameValue,
  hasCheckbox,
  CheckboxCallFunc,
  CardGraphic,
  param = null,
}) => {
  const { searchName } = useContext(AuthGoogleContext);
  const [tableShow, setTableShow] = useState();

  useEffect(() => {
    setTableShow(table);
  }, [param, table]);

  return (
    <div className={classes.CardMain}>
      <div className={classes.CardTop}>{cardTop}</div>
      {CardGraphic && <div className={classes.CardGraphic}>{CardGraphic}</div>}

      {table && (
        <div className={classes.CardTableMain}>
          <div className={classes.CardTopInside}>
            <div>
              <div className={classes.Title}>{title}</div>
              {hasCheckbox && (
                <div style={{ height: "38px" }}>
                  <Checkbox
                    // defaultChecked
                    // checked={selectCheckAll}
                    onClick={CheckboxCallFunc}
                    sx={{
                      color: "#0091d7",
                      "&.Mui-checked": {
                        color: "#0091d7",
                      },
                    }}
                  />
                  <label style={{ color: "#0091d7" }}>Marcar todos</label>
                </div>
              )}
            </div>
            {searchName && hasFilterName && (
              <input
                className={classes.FiltersName}
                type="text"
                name=""
                id=""
                placeholder="busca por nome"
                onChange={(e) => {
                  setFilterNameValue(e.target.value);
                }}
              />
            )}
            {hasFilterArea && (
              <button
                onClick={FilterAreaCallFunc}
                className={classes.FiltersArea}
              >
                Filtros
                <img src={filterIcon} alt="" />
              </button>
            )}
          </div>
          <div>{tableShow}</div>
        </div>
      )}
      <div className={classes.Pagination}>{pagination}</div>
    </div>
  );
};
export default CardTable;
