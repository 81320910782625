/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext } from "react";
import classes from "./Resale.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";
import { firestore } from "../../Services/Firebase";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  // query,
  // where,
} from "firebase/firestore";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import reload from "../../Assets/reload.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const Resale = () => {
  const { userLogged, setRefreshTopNav, refreshTopNav } =
    useContext(AuthGoogleContext);
  const [users, setUsers] = useState("");
  const [openModalCreateResale, setOpenModalCreateResale] = useState(false);
  const [widthButton, setWidthButton] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [newSupplier, setNewSupplier] = useState("");
  const [clientPlanGet, setClientPlanGet] = useState("");
  const [userRowData, setUserRowData] = useState("");
  const [openModalDeleteClient, setOpenModalDeleteClient] = useState(false);
  const [openModalReloadResale, setOpenModalReloadResale] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loading, setLoading] = useState(true);
  /**************************************** */

  const [clientSupplier, setClientSupplier] = useState("");
  const [resaleName, setResaleName] = useState("");
  const [amount, setAmount] = useState();
  const [costValue, setCostValue] = useState();
  const [costPerCreditString, setCostPerCreditString] = useState();
  const [newAmountString, setNewAmountString] = useState();
  const [newAmountResaleString, setNewAmountResaleString] = useState();
  const [newCostValueString, setNewCostValueString] = useState();
  const [modalSuccess, setModalSuccess] = useState(false);
  const [amountNumber, setAmountNumber] = useState();
  const [getAmountData, setGetAmountData] = useState();
  const [getSameSupplier, setGetSameSupplier] = useState();
  const [getSupplierCost, setGetSupplierCost] = useState();
  const [diferencePurchaseSale, setDiferencePurchaseSale] = useState();
  const [noContentPage, setNoContentPage] = useState(false);

  /***************************************** */

  const optionsSupplier =
    supplier &&
    supplier.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  const optionsResalePlan =
    clientPlanGet &&
    clientPlanGet.map((item) => ({
      value: item.resalePlanValue,
      label: item.resalePlanName,
    }));
  const optionsClientPlan =
    clientPlanGet &&
    clientPlanGet.map((item) => ({
      value: item.resalePlanValue,
      label: item.resalePlanName,
    }));

  useEffect(() => {
    setGetSameSupplier(
      supplier && supplier?.filter((sup) => sup.name === userRowData.supplier)
    );
  }, [userRowData]);

  useEffect(() => {
    setGetSupplierCost(
      getSameSupplier && getSameSupplier[0]?.last_cost_per_credit
    );
  }, [getSameSupplier]);

  useEffect(() => {
    if (costValue && getSupplierCost && amount) {
      const costValueNumber = Number(costValue);
      const getSupplierCostNumber = Number(getSupplierCost);
      const amountNumber = Number(amount);
      setDiferencePurchaseSale(
        costValueNumber - getSupplierCostNumber * amountNumber
      );
    }
  }, [costValue, getSupplierCost, amount]);

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const hora = String(dataAtual.getHours()).padStart(2, "0");
  const minuto = String(dataAtual.getMinutes()).padStart(2, "0");
  const segundo = String(dataAtual.getSeconds()).padStart(2, "0");
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const date_create_complete = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano} ${hora}:${minuto}:${segundo}`;

  const transformarData = (dataString) => {
    const [, mes, ano] = dataString.split("/");
    return `${mes}/${ano}`;
  };

  const month_create = transformarData(date_create);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    if (amount && costValue && openModalReloadResale) {
      const amountNumber = parseFloat(amount);
      const costValueNumber = parseFloat(costValue);
      const getAmountData = parseFloat(userRowData.credits);
      const getCostValueData = parseFloat(userRowData.cost_value);
      const CostPerCredit = costValueNumber / amountNumber;
      const NewAmount = getAmountData + amountNumber;
      const NewCostValue = getCostValueData + costValueNumber;

      setCostPerCreditString(String(CostPerCredit));
      setNewAmountResaleString(String(NewAmount));
      setNewCostValueString(String(NewCostValue));
    } else {
      setCostPerCreditString("");
    }
  }, [amount, costValue]);

  useEffect(() => {
    if (amount && costValue && openModalReloadResale) {
      const amountnumber = parseFloat(amount);
      const costValueNumber = parseFloat(costValue);
      const CostPerCredit = costValueNumber / amountnumber;
      setCostPerCreditString(String(CostPerCredit));
    } else {
      setCostPerCreditString("");
    }
  }, [amount, costValue]);

  const useCollectionResale = collection(firestore, "resale");
  const useCollectionSupplier = collection(firestore, "credit_supplier");
  const useCollectionResalePlan = collection(firestore, "resale_plans");
  const useCollectionCreditHistory = collection(firestore, "credit_history");

  /*************************** */

  const resaleNameRef = useRef(resaleName);

  const clientSupplierRef = useRef(clientSupplier);

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    resaleNameRef.current = resaleName;
    clientSupplierRef.current = clientSupplier;
  }, [clientSupplier, resaleName]);

  const UpdateClient = async (id) => {
    const userDoc = doc(firestore, "resale", id);
    await updateDoc(userDoc, {
      credits: newAmountResaleString,
      last_purchase: date_create_complete,
    });

    setCallUsers(!callUsers);
    setRefreshTopNav(!refreshTopNav);

    UpdateCredit();
  };

  useEffect(() => {
    if (supplier) {
      setNewSupplier(
        supplier.filter(
          (newSupplier) => newSupplier.name === userRowData.supplier
        )
      );
    }
  }, [userRowData, callUsers, amount, costValue, refreshTopNav]);

  useEffect(() => {
    if ((amount || costValue) && openModalReloadResale) {
      setAmountNumber(parseFloat(amount));
      const costValueNumber = parseFloat(costValue);
      setGetAmountData(parseFloat(newSupplier[0]?.amount));
      const getCostValueData = parseFloat(newSupplier[0]?.cost_value);
      const CostPerCredit = costValueNumber / amountNumber;
      const NewAmount = getAmountData - amountNumber;
      const NewCostValue = getCostValueData - costValueNumber;

      setCostPerCreditString(String(CostPerCredit));
      setNewAmountString(String(NewAmount));
      setNewCostValueString(String(NewCostValue));
    } else {
      setCostPerCreditString("");
    }
  }, [amount, costValue, userRowData, callUsers, refreshTopNav]);

  const UpdateCredit = async () => {
    const userDoc = doc(firestore, "credit_supplier", newSupplier[0].id);
    await updateDoc(userDoc, {
      amount: newAmountString,
      cost_value: newCostValueString,
    });
    const supplierGetAmount = newSupplier[0]?.amount;
    const newAmountNumber = parseFloat(newAmountString);

    setCallUsers(!callUsers);
    CreateHistoryLog(supplierGetAmount, newAmountNumber);
    setOpenModalReloadResale(false);
    setModalSuccess(true);
    setRefreshTopNav(!refreshTopNav);
  };

  const CreateResalePlan = async () => {
    try {
      const user = await addDoc(useCollectionResale, {
        // Utiliza os valores das refs diretamente
        // resale_plan: clientPlanNamePostRef.current,
        // plan_value: clientPlanValuePostRef.current,
        resale: resaleNameRef.current,
        supplier: clientSupplierRef.current,
        last_purchase: "",
        company: userLogged.company,
        month_create: month_create,
        credits: "0",
        date_create,
      });

      setCallUsers(!callUsers);
      setOpenModalCreateResale(false);
      setRefreshTopNav(!refreshTopNav);

      return user;
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      throw error;
    }
  };

  const CreateHistoryLog = async (
    supplierGetAmount,
    supplierGetAmountFinal
  ) => {
    try {
      // Adição do documento ao Firestore
      const user = await addDoc(useCollectionCreditHistory, {
        operation_amount: amount,
        initial_amount: supplierGetAmount,
        final_amount: supplierGetAmountFinal,
        supplier: userRowData.supplier,
        user_logged: userLogged.name,
        client: userRowData.resale,
        operation: "Saída",
        diference_between_purchase_sale: diferencePurchaseSale,
        type_user: "resale",
        cost_value: costValue,
        cost_value_by_credit: costPerCreditString,
        date_create: date_create_complete,
      });

      return user;
    } catch (error) {
      // Lida com erros, se houver algum
      console.error("Erro ao cadastrar histórico:", error);
      throw error; // Propaga o erro para que possa ser tratado posteriormente
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const data = await getDocs(useCollectionResale);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  useEffect(() => {
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getClientPlan = async () => {
      const data = await getDocs(useCollectionResalePlan);
      setClientPlanGet(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSupplier();
    getClientPlan();
    // }, [callUsers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSupplier();
  }, [callUsers]);

  const DeleteClient = async (id) => {
    const userDoc = doc(firestore, "resale", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteClient(false);
    setRefreshTopNav(!refreshTopNav);
  };

  const columns = [
    { id: "resale", label: "REVENDA", flex: 1 },
    { id: "supplier", label: "FORNECEDOR", flex: 1 },
    { id: "last_purchase", label: "ÚLTIMA RECARGA", flex: 1 },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = users && users.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={modalSuccess}
        setOpenModal={setModalSuccess}
        title={`Operação concluída com sucesso!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalDeleteClient}
        setOpenModal={setOpenModalDeleteClient}
        title={`Deseja realmente excluir a revenda '${userRowData.resale}'`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteClient(userRowData.id)}
      />
      <ModalSmall
        openModal={alertModal}
        setOpenModal={setAlertModal}
        title={`Selecione um usuário!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalDefault
        openModal={openModalCreateResale}
        setOpenModal={setOpenModalCreateResale}
        optionsSupplier={optionsSupplier}
        setClientSupplier={setClientSupplier}
        clientSupplier={clientSupplier}
        setResaleName={setResaleName}
        widthButton={widthButton}
        modalType="cadastrar revenda"
        title="cadastrar revenda"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          CreateResalePlan();
        }}
      />
      <ModalDefault
        openModal={openModalReloadResale}
        setOpenModal={setOpenModalReloadResale}
        optionsSupplier={optionsSupplier}
        optionsResalePlan={optionsResalePlan}
        optionsClientPlan={optionsClientPlan}
        amount={amount}
        setAmount={setAmount}
        costValue={costValue}
        setCostValue={setCostValue}
        costPerCreditString={costPerCreditString}
        setCostPerCreditString={setCostPerCreditString}
        widthButton={widthButton}
        modalType="recarga de revenda"
        title="recarga de revenda"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          UpdateClient(userRowData.id);
        }}
      />
      <CardTable
        title="Lista de Revendas"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Lista de Revendas" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="cadastrar revenda"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "185px" : "100%"}
                callFunc={() => setOpenModalCreateResale(true)}
              />
            </div>
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="cliente revenda" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            height: "20px",
                            color: "#0091D7",
                            textTransform: "uppercase",
                            borderColor: "#275370",
                            fontSize: "11px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersToShow &&
                      usersToShow.map((row) => {
                        return (
                          <TableRow
                            hover
                            // role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              let value = row[column.id];
                              return (
                                <TableCell
                                  className={classes.TableCell}
                                  style={{
                                    // minWidth: column.minWidth,
                                    padding: "10px 16px 10px 16px",
                                    color: "#fff",
                                    borderColor: "#275370",
                                    marginRight: "50px",
                                    fontSize: "11px",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.id === "action"
                                    ? (value = (
                                        <div
                                          className={classes.TableActionButtons}
                                        >
                                          <button
                                            title="Recarga revenda"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalReloadResale(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={reload} alt="" />
                                          </button>
                                          <button
                                            title="Deletar usuário"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalDeleteClient(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={deleteImg} alt="" />
                                          </button>
                                        </div>
                                      ))
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Resale;
