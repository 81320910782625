/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import classes from "./TopNav.module.scss";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import Avatar from "@mui/material/Avatar";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import search from "./../../Assets/TopNavIcons/search.svg";
// import notific from "./../../Assets/TopNavIcons/notific.svg";
// import mail_outline from "./../../Assets/TopNavIcons/mail_outline.svg";
import shopping_cart from "./../../Assets/TopNavIcons/shopping_cart.svg";
// import Badge from "@mui/material/Badge";
import { slide as MenuBurguer } from "react-burger-menu";
import vector from "../../Assets/vector.svg";
import CloseCircle from "../../Assets/close_circle.svg";
import { firestore } from "../../Services/Firebase";
import {
  // addDoc,
  collection,
  getDocs,
  // doc,
  // deleteDoc,
  // updateDoc,
  // query,
  // where,
} from "firebase/firestore";
import { Link } from "react-router-dom";

const TopNav = () => {
  const {
    user,
    setSearchName,
    searchName,
    refreshTopNav,
    userLogged,
    topNavCall,
    setTopNavCall,
  } = useContext(AuthGoogleContext);
  const [userLogado, setUserLogado] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBurguer, setMenuOpenBurguer] = useState(false);
  const [companyInfo, setCompanyInfo] = useState();
  const [usersNumber, setUsersNumber] = useState("");
  const [expirationToday, setExpirationToday] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationAll, setExpirationAll] = useState("");
  const [getResale, setGetResale] = useState("");
  const [supplier, setSupplier] = useState("");
  const [monthOutputs, setMonthOutputs] = useState("");
  const [monthEntry, setMonthEntry] = useState("");

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const transformarData = (dataString) => {
    const [, mes, ano] = dataString.split("/");
    return `${mes}/${ano}`;
  };

  const transformarDataComplete = (dataString) => {
    if (dataString) {
      const [dataPart] = dataString.split(" ");
      const [, mes, ano] = dataPart.split("/");
      return `${mes}/${ano}`;
    }

    return ""; // Ou qualquer valor padrão que você deseje retornar quando a dataString for indefinida
  };

  function compararDatasMaiorOrMenor(data1, data2) {
    const parts1 = data1.split("/");
    const date1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);

    const parts2 = data2.split("/");
    const date2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);

    if (date1.getTime() > date2.getTime()) {
      return "A primeira data é maior que a segunda.";
    } else if (date1.getTime() < date2.getTime()) {
      return "A primeira data é menor que a segunda.";
    } else {
      return "As datas são iguais.";
    }
  }

  const useCollectionClients = collection(firestore, "clients");
  const useCollectionResale = collection(firestore, "resale");
  const useCollectionSupplier = collection(firestore, "credit_supplier");
  const useCollectionCreditHistory = collection(firestore, "credit_history");

  const handleMenuToggle = () => {
    setMenuOpenBurguer(!menuOpenBurguer);
  };

  const closeMenu = () => {
    setMenuOpenBurguer(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    if (user) setUserLogado(user);
  }, [user]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionClients);
      // setUsers(
      //   data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      //   .filter((user) => user.company === userLogged.company)
      // );
      setUsersNumber(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.status === "ativo").length
      );
      setExpirationToday(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.expiration_date === date_create).length
      );
      setExpirationMonth(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (user) =>
              transformarData(user.expiration_date) ===
                transformarData(date_create) &&
              compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
                "A primeira data é menor que a segunda."
          ).length
      );
      setExpirationAll(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (user) =>
              compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
              "A primeira data é menor que a segunda."
          ).length
      );
    };
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getResale = async () => {
      const data = await getDocs(useCollectionResale);
      setGetResale(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length
        // .filter((user) => user.company === userLogged.company)
      );
    };
    const getHistory = async () => {
      const data = await getDocs(useCollectionCreditHistory);
      setMonthOutputs(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (billing) =>
              billing.type_user === "resale" || billing.type_user === "client"
          )
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
      setMonthEntry(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "supplier")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
    };
    getSupplier();
    getResale();
    getUsers();
    getHistory();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionClients);
      // setUsers(
      //   data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      //   .filter((user) => user.company === userLogged.company)
      // );
      setUsersNumber(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.status === "ativo").length
      );
      setExpirationToday(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((user) => user.expiration_date === date_create).length
      );
      setExpirationMonth(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (user) =>
              transformarData(user.expiration_date) ===
                transformarData(date_create) &&
              compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
                "A primeira data é menor que a segunda."
          ).length
      );
      setExpirationAll(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (user) =>
              compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
              "A primeira data é menor que a segunda."
          ).length
      );
    };
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getResale = async () => {
      const data = await getDocs(useCollectionResale);
      setGetResale(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length
        // .filter((user) => user.company === userLogged.company)
      );
    };
    const getHistory = async () => {
      const data = await getDocs(useCollectionCreditHistory);
      setMonthOutputs(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (billing) =>
              billing.type_user === "resale" || billing.type_user === "client"
          )
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
      setMonthEntry(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "supplier")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
    };
    getSupplier();
    getResale();
    getUsers();
    getHistory();
  }, [refreshTopNav]);

  useEffect(() => {
    const getCompanyLocStor = sessionStorage.getItem("@AuthFirebase:company");
    const userCompanyJson = JSON.parse(getCompanyLocStor);
    setCompanyInfo(userCompanyJson?.createCompany_image_url);
  }, [user]);

  const styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "20px",
      top: "20px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "70px",
      width: "70px",
      top: "5px",
      right: "10px",
    },
    bmCross: {
      background: "#1A2328",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      with: "80%",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", //
    },
    bmMenu: {
      background: "#1A2328",
      // padding: "1em 1em",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#1A2328",
      padding: "0.8em",
    },
    bmItem: {
      display: "flex",
      justifyContent: "space-evenly",
      color: "#fff",
      marginBottom: "30px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  return (
    <>
      <header className={classes.TopNavDesktop}>
        <section className={classes.DesktopNav}>
          <div className={classes.LeftMenu}>
            {userLogged.permission.top_nav_btn.active && (
              <Link to="/clientes">
                <div
                  onClick={() =>
                    setTopNavCall((prevState) => ({
                      ...prevState,
                      ActiveAll: !topNavCall.ActiveAll,
                    }))
                  }
                  className={classes.YellowButton}
                >
                  <div>Ativos</div>
                  <div>{usersNumber}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.expire_today && (
              <Link to="/clientes">
                <div
                  onClick={() =>
                    setTopNavCall((prevState) => ({
                      ...prevState,
                      ExpireToday: !topNavCall.ExpireToday,
                    }))
                  }
                  className={classes.PinkButton}
                >
                  <div>Vence Hoje</div>
                  <div>{expirationToday}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.expire_month && (
              <Link to="/clientes">
                <div
                  onClick={() =>
                    setTopNavCall((prevState) => ({
                      ...prevState,
                      ExpireMonth: !topNavCall.ExpireMonth,
                    }))
                  }
                  className={classes.GreenButton}
                >
                  <div>Venc. Mês</div>
                  <div>{expirationMonth}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.expire_all && (
              <Link to="/clientes">
                <div
                  onClick={() =>
                    setTopNavCall((prevState) => ({
                      ...prevState,
                      ExpireAll: !topNavCall.ExpireAll,
                    }))
                  }
                  className={classes.YellowButton}
                >
                  <div>Venc. Total</div>
                  <div>{expirationAll}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.resale && (
              <Link to="/revendas">
                <div className={classes.BlueButton}>
                  <div>Revendas</div>
                  <div>{getResale}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.monthly_billing && (
              <Link to="/dashboard">
                <div className={classes.GreenButton}>
                  <div>Fat. Mensal</div>
                  <div>{formatarValor(monthOutputs)}</div>
                </div>
              </Link>
            )}
            {userLogged.permission.top_nav_btn.profit_month && (
              <Link to="/dashboard">
                <div className={classes.PurpleButton}>
                  <div>Lucro Mês</div>
                  <div>{formatarValor(monthOutputs - monthEntry)}</div>
                </div>
              </Link>
            )}
          </div>
          <div className={classes.RightMenu}>
            <div className={classes.IconsButtons}>
              <IconButton color="primary" aria-label="delete" size="small">
                <Button
                  style={{
                    backgroundColor: "#212c32",
                    height: "50px",
                    borderRadius: "100%",
                    padding: "none",
                  }}
                  variant="contained"
                  onClick={() => setSearchName(!searchName)}
                >
                  <img src={search} alt="" />
                </Button>
              </IconButton>
              {userLogged.permission.top_nav_btn.credit_cart && (
                <IconButton color="primary" aria-label="delete" size="small">
                  <Button
                    style={{
                      backgroundColor: "#212c32",
                      height: "50px",
                      borderRadius: "100%",
                      padding: "none",
                      // width: "23px",
                    }}
                    variant="contained"
                    onClick={handleClick}
                  >
                    <img src={shopping_cart} alt="" />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen} // Usar o estado menuOpen para controlar a abertura/fechamento do menu
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      style: {
                        // width: "210px",
                        border: "2px solid #3487BE",
                        padding: "5px 20px",
                        backgroundColor: "#1A2328", // Ajuste a largura conforme necessário
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        color: "#3487be",
                        fontWeight: "bold",
                        fontSize: "16px",
                        borderBottom: "2px solid #979797",
                      }}
                      onClick={handleClose}
                    >
                      Créditos restantes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "#3487be",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={handleClose}
                    >
                      <div>{`Servidor`}</div>
                      <div>{`Créditos`}</div>
                    </MenuItem>
                    {supplier &&
                      supplier?.map((supplierMap) => (
                        <MenuItem
                          sx={{
                            color: "#979797",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={handleClose}
                        >
                          <div>{supplierMap.name}</div>
                          <div>{supplierMap.amount}</div>
                        </MenuItem>
                      ))}
                  </Menu>
                </IconButton>
              )}
              {/* <IconButton color="primary" aria-label="delete" size="small">
                <img src={mail_outline} alt="" />
              </IconButton> */}
              {/* <IconButton color="primary" aria-label="delete" size="small">
                <Badge badgeContent={135} color="error">
                  <img src={notific} alt="" />
                </Badge>
              </IconButton> */}
            </div>
            <div>
              <MenuItem
                sx={{
                  backgroundColor: "#212c32",
                  height: "50px",
                  color: "#fff",
                  textTransform: "uppercase",
                }}
                variant="contained"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={userLogado?.photoURL}
                  className={classes.Avatar}
                  sx={{ width: 35, height: 35 }}
                />
                {userLogado?.displayName}
              </MenuItem>
            </div>
          </div>
        </section>
      </header>
      <header className={classes.TopNavMobile}>
        <section className={classes.MobileNav}>
          <div className={classes.LeftMenu}>
            <img src={companyInfo} alt="" />
          </div>
          <div className={classes.RightMenu}>
            <IconButton
              className={classes.IconButton}
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() => setSearchName(!searchName)}
            >
              <img src={search} alt="" />
            </IconButton>
            {userLogged.permission.top_nav_btn.credit_cart && (
              <IconButton color="primary" aria-label="delete" size="small">
                <Button
                  style={{
                    backgroundColor: "#212c32",
                    height: "50px",
                    borderRadius: "100%",
                    padding: "none",
                    // width: "23px",
                  }}
                  variant="contained"
                  onClick={handleClick}
                >
                  <img src={shopping_cart} alt="" />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen} // Usar o estado menuOpen para controlar a abertura/fechamento do menu
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    style: {
                      // width: "210px",
                      border: "2px solid #3487BE",
                      padding: "5px 20px",
                      backgroundColor: "#1A2328", // Ajuste a largura conforme necessário
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      color: "#3487be",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "2px solid #979797",
                    }}
                    onClick={handleClose}
                  >
                    Créditos restantes
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#3487be",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={handleClose}
                  >
                    <div>{`Servidor`}</div>
                    <div>{`Créditos`}</div>
                  </MenuItem>
                  {supplier &&
                    supplier?.map((supplierMap) => (
                      <MenuItem
                        sx={{
                          color: "#979797",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={handleClose}
                      >
                        <div>{supplierMap.name}</div>
                        <div>{supplierMap.amount}</div>
                      </MenuItem>
                    ))}
                </Menu>
              </IconButton>
            )}
            <button className={classes.BurguerImg} onClick={handleMenuToggle}>
              <img src={vector} alt="Menu" />
            </button>

            <MenuBurguer
              isOpen={menuOpenBurguer}
              onStateChange={(state) => (state.isOpen ? null : closeMenu())}
              customBurgerIcon={false}
              customCrossIcon={<img src={CloseCircle} alt="Menu" />}
              styles={styles}
              right
            >
              <MenuItem
                sx={{
                  backgroundColor: "#212c32",
                  height: "50px",
                  color: "#fff",
                  textTransform: "uppercase",
                  maxWidth: "210px",
                }}
                variant="contained"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={userLogado?.photoURL}
                  className={classes.Avatar}
                  sx={{ width: 35, height: 35 }}
                />
                {userLogado?.displayName}
              </MenuItem>
              {/* <div
                className={classes.CreditsArea}
                style={{
                  borderBottom: "1px solid #fff",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <div>
                  <img src={shopping_cart} alt="" />
                </div>
                <div>
                  <div
                    style={{
                      fontSize: "20px",
                      margin: "5px 0 15px",
                      fontWeight: "bold",
                    }}
                  >
                    Créditos restantes
                  </div>
                  <MenuItem
                    sx={{
                      color: "#3487be",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "2px",
                      height: "35px",
                    }}
                    onClick={handleClose}
                  >
                    <div>{`Servidor`}</div>
                    <div>{`Créditos`}</div>
                  </MenuItem>
                  {supplier &&
                    supplier?.map((supplierMap) => (
                      <MenuItem
                        sx={{
                          color: "#979797",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2px",
                          height: "35px",
                        }}
                        onClick={handleClose}
                      >
                        <div>{supplierMap.name}</div>
                        <div>{supplierMap.amount}</div>
                      </MenuItem>
                    ))}
                </div>
              </div> */}
              {/* <div
                className={classes.CreditsArea}
                style={{
                  borderBottom: "1px solid #fff",
                  height: "100px",
                }}
              >
                <div>
                  <img src={mail_outline} alt="" />
                </div>
                <div>
                  <div
                    style={{
                      fontSize: "20px",
                      margin: "5px 0 15px",
                      fontWeight: "bold",
                      minWidth: "154px",
                    }}
                  >
                    Mensagens
                  </div>
                </div>
              </div> */}
              <div
                style={{
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
                className={classes.LeftMenu}
              >
                {userLogged.permission.top_nav_btn.active && (
                  <Link to="/clientes">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.YellowButton}
                      style={{
                        color: "#D6A016",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "130px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Ativos</div>
                      <div>{usersNumber}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.expire_today && (
                  <Link to="/clientes">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#ee82ee",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "120px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Vence Hoje</div>
                      <div>{expirationToday}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.expire_month && (
                  <Link to="/clientes">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#16D69C",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "130px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Vencidos Mês</div>
                      <div>{expirationMonth}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.expire_all && (
                  <Link to="/clientes">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#D6A016",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "130px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Vencidos Total</div>
                      <div>{expirationAll}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.resale && (
                  <Link to="/revendas">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#ffa500",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "120px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Revendas</div>
                      <div>{getResale}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.monthly_billing && (
                  <Link to="/dashboard">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#16D69C",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "130px",
                        fontSize: "14px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Fat. Mensal</div>
                      <div>{formatarValor(monthOutputs)}</div>
                    </div>
                  </Link>
                )}
                {userLogged.permission.top_nav_btn.profit_month && (
                  <Link to="/dashboard">
                    <div
                      onClick={handleMenuToggle}
                      className={classes.CardInfo}
                      style={{
                        color: "#F765A3",
                        borderRadius: "6px",
                        border: "1px solid #06ADE2",
                        minWidth: "120px",
                        fontSize: "16px",
                        textAlign: "center",
                        marginBottom: "15px",
                        height: "45px",
                        padding: "10px 0px 2px",
                      }}
                    >
                      <div>Lucro Mês</div>
                      <div>{formatarValor(monthOutputs - monthEntry)}</div>
                    </div>
                  </Link>
                )}
              </div>
            </MenuBurguer>
          </div>
        </section>
      </header>
    </>
  );
};

export default TopNav;
