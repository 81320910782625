/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classes from "./ModalCustomSendWhats.module.scss";

const ModalCustomSendWhats = ({ userRowDataAll }) => {
  const codeBlinkRef = useRef(null);
  const [copyInfo, setCopyInfo] = useState(false);

  const copyToClipboard = () => {
    if (codeBlinkRef.current) {
      const range = document.createRange();
      range.selectNode(codeBlinkRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  };

  useEffect(() => {
    setCopyInfo(false);
  }, []);

  return (
    <>
      <div className={classes.ModalDefault}>
        <div ref={codeBlinkRef}>
          {userRowDataAll &&
            userRowDataAll.map((user) => (
              <div>{`${user.fone.replace(/\D/g, "")},${user.name}`}</div>
            ))}
        </div>
      </div>
      <button
        className={
          copyInfo ? classes.ButtonConfirmActive : classes.ButtonConfirm
        }
        onClick={() => {
          copyToClipboard();
          setCopyInfo(true);
        }}
      >
        {copyInfo ? "Copiado" : "Copiar informações"}
      </button>
    </>
  );
};

export default ModalCustomSendWhats;
