/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import classes from "./Credits.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";
import { firestore } from "../../Services/Firebase";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  // query,
  // where,
} from "firebase/firestore";
import paperImg from "../../Assets/AdminPage/paper.svg";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const Credits = () => {
  const { userLogged, setRefreshTopNav, refreshTopNav } =
    useContext(AuthGoogleContext);
  const [users, setUsers] = useState("");
  const [openModalAddCredit, setOpenModalAddCredit] = useState(false);
  const [widthButton, setWidthButton] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [bankGet, setBankGet] = useState("");
  const [supplier, setSupplier] = useState("");
  const [clientPlanGet, setClientPlanGet] = useState("");
  const [userRowData, setUserRowData] = useState("");
  /**************************************** */
  const [amount, setAmount] = useState();
  const [costValue, setCostValue] = useState();
  const [costPerCreditString, setCostPerCreditString] = useState();
  const [newAmountString, setNewAmountString] = useState();
  const [newCostValueString, setNewCostValueString] = useState();
  const [amountNumber, setAmountNumber] = useState();
  const [getAmountData, setGetAmountData] = useState();
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noContentPage, setNoContentPage] = useState(false);

  /***************************************** */

  const optionsBankGet =
    bankGet &&
    bankGet.map((item) => ({
      value: item.bankName,
      label: item.bankName,
    }));
  const optionsSupplier =
    supplier &&
    supplier.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  const optionsClientPlan =
    clientPlanGet &&
    clientPlanGet.map((item) => ({
      value: item.clientPlanValue,
      label: item.clientPlanName,
    }));

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const hora = String(dataAtual.getHours()).padStart(2, "0");
  const minuto = String(dataAtual.getMinutes()).padStart(2, "0");
  const segundo = String(dataAtual.getSeconds()).padStart(2, "0");

  const date_create_complete = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano} ${hora}:${minuto}:${segundo}`;

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    if (amount && costValue && openModalAddCredit) {
      setAmountNumber(parseFloat(amount));
      const costValueNumber = parseFloat(costValue);
      setGetAmountData(parseFloat(userRowData.amount));
      const getCostValueData = parseFloat(userRowData.cost_value);
      const CostPerCredit = costValueNumber / amountNumber;
      const NewAmount = getAmountData + amountNumber;
      const NewCostValue = getCostValueData + costValueNumber;

      setCostPerCreditString(String(CostPerCredit));
      setNewAmountString(String(NewAmount));
      setNewCostValueString(String(NewCostValue));
    } else {
      setCostPerCreditString("");
    }
  }, [amount, costValue]);

  const UpdateCredit = async (id) => {
    const userDoc = doc(firestore, "credit_supplier", id);
    await updateDoc(userDoc, {
      amount: newAmountString,
      last_cost_per_credit: costPerCreditString,
      cost_value: newCostValueString,
    });
    setCallUsers(!callUsers);
    CreateHistoryLog();
    setOpenModalAddCredit(false);
    setRefreshTopNav(!refreshTopNav);
  };

  const useCollectionCreditHistory = collection(firestore, "credit_history");

  const CreateHistoryLog = async () => {
    try {
      // Adição do documento ao Firestore
      const user = await addDoc(useCollectionCreditHistory, {
        operation_amount: amountNumber,
        initial_amount: getAmountData,
        final_amount: newAmountString,
        supplier: userRowData.name,
        user_logged: userLogged.name,
        client: "-",
        operation: "Entrada",
        type_user: "supplier",
        cost_value: costValue,
        cost_value_by_credit: costPerCreditString,
        date_create: date_create_complete,
      });

      return user;
    } catch (error) {
      // Lida com erros, se houver algum
      console.error("Erro ao cadastrar histórico:", error);
      throw error; // Propaga o erro para que possa ser tratado posteriormente
    }
  };

  const useCollectionBank = collection(firestore, "bank");
  const useCollectionSupplier = collection(firestore, "credit_supplier");
  const useCollectionClientPlan = collection(firestore, "client_plans");

  /*************************** */

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const data = await getDocs(useCollectionSupplier);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  useEffect(() => {
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getBankGet = async () => {
      const data = await getDocs(useCollectionBank);
      setBankGet(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getClientPlan = async () => {
      const data = await getDocs(useCollectionClientPlan);
      setClientPlanGet(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBankGet();
    getSupplier();
    getClientPlan();
    // }, [callUsers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (amount && costValue && openModalAddCredit) {
      const amountnumber = parseFloat(amount);
      const costValueNumber = parseFloat(costValue);
      const CostPerCredit = costValueNumber / amountnumber;
      setCostPerCreditString(String(CostPerCredit));
    } else {
      setCostPerCreditString("");
    }
  }, [amount, costValue]);

  const columns = [
    { id: "name", label: "FORNECEDOR", flex: 1 },
    { id: "amount", label: "QUANTIDADE ATUAL", flex: 1 },
    { id: "cost_value", label: "VALOR DE CUSTO", flex: 1 },
    {
      id: "date_create",
      label: "CRIADO EM",
      flex: 1,
    },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];

  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = users && users.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      <ModalDefault
        openModal={openModalAddCredit}
        setOpenModal={setOpenModalAddCredit}
        optionsBankGet={optionsBankGet}
        optionsSupplier={optionsSupplier}
        optionsClientPlan={optionsClientPlan}
        amount={amount}
        setAmount={setAmount}
        costValue={costValue}
        setCostValue={setCostValue}
        costPerCreditString={costPerCreditString}
        setCostPerCreditString={setCostPerCreditString}
        widthButton={widthButton}
        modalType="cadastro de crédito"
        title="cadastro de crédito"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          UpdateCredit(userRowData.id);
        }}
      />
      <CardTable
        title="Lista de Créditos"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Lista de Créditos" />
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="fornecedor" />
          ) : (
            <div>
              <div>
                <TableContainer sx={{ maxHeight: "70vh" }}>
                  {widthButton > 768 && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: "20px",
                                color: "#0091D7",
                                textTransform: "uppercase",
                                borderColor: "#275370",
                                fontSize: "11px",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersToShow &&
                          usersToShow.map((row) => {
                            return (
                              <TableRow
                                hover
                                // role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columns.map((column) => {
                                  let value = row[column.id];
                                  return (
                                    <TableCell
                                      className={classes.TableCell}
                                      style={{
                                        // minWidth: column.minWidth,
                                        padding: "10px 16px 10px 16px",
                                        color: "#fff",
                                        borderColor: "#275370",
                                        marginRight: "50px",
                                        fontSize: "11px",
                                      }}
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "action"
                                        ? (value = (
                                            <div
                                              className={
                                                classes.TableActionButtons
                                              }
                                            >
                                              <button
                                                onClick={() => {
                                                  setOpenModalAddCredit(true);
                                                  setUserRowData(row);
                                                }}
                                                className={classes.DeleteButton}
                                              >
                                                <img src={paperImg} alt="" />
                                              </button>
                                            </div>
                                          ))
                                        : column.id === "cost_value"
                                        ? formatarValor(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                  {widthButton < 768 && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columnsCollapse.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: "20px",
                                color: "#0091D7",
                                textTransform: "uppercase",
                                borderColor: "#275370",
                                fontSize: "11px",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersToShow &&
                          usersToShow.map((row) => {
                            return (
                              <TableRow
                                hover
                                // role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columnsCollapse.map((column) => {
                                  let value = row[column.id];
                                  return (
                                    <TableCell
                                      className={classes.TableCell}
                                      style={{
                                        // minWidth: column.minWidth,
                                        padding: "5px 16px 5px 16px",
                                        color: "#fff",
                                        borderColor: "#275370",
                                        marginRight: "50px",
                                        fontSize: "11px",
                                      }}
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "collapse" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ fontSize: "16px" }}>
                                            {row.name}
                                          </div>

                                          <IconButton
                                            sx={{
                                              color: "#0091d7",
                                              "&.Mui-checked": {
                                                color: "#0091d7",
                                              },
                                            }}
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                              setRowId(row.id);
                                              setOpen(!open);
                                            }}
                                          >
                                            <img
                                              style={{ width: "25px" }}
                                              src={
                                                open && row.id == rowId
                                                  ? closeIcon
                                                  : openIcon
                                              }
                                              alt=""
                                            />
                                          </IconButton>
                                        </div>
                                      ) : (
                                        value
                                      )}
                                      {row.id == rowId && (
                                        <Collapse
                                          in={open}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box
                                            sx={{
                                              margin: 1,
                                              color: "#FFF",
                                              fontSize: "11px",
                                            }}
                                          >
                                            <Table
                                              size="small"
                                              aria-label="purchases"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    Itens
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    Informações
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"QUANTIDADE ATUAL:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.amount}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"VALOR DE CUSTO:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {formatarValor(
                                                      row.cost_value
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"CRIADO EM:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.date_create}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      color: "#0091d7",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                  >
                                                    {"AÇÕES:"}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize: "11px",
                                                      borderColor: "#275370",
                                                    }}
                                                    align="right"
                                                  >
                                                    <div
                                                      className={
                                                        classes.TableActionButtons
                                                      }
                                                    >
                                                      <button
                                                        onClick={() => {
                                                          setOpenModalAddCredit(
                                                            true
                                                          );
                                                          setUserRowData(row);
                                                        }}
                                                        className={
                                                          classes.DeleteButton
                                                        }
                                                      >
                                                        <img
                                                          src={paperImg}
                                                          alt=""
                                                        />
                                                      </button>
                                                    </div>
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </div>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Credits;
