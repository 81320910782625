/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import classes from "./ReportResale.module.scss";
import Title from "../../Components/UI/Title/Title";
import dashboard from "./../../Assets/TitleIcons/dashboard.svg";
import { firestore } from "../../Services/Firebase";
import { collection, getDocs } from "firebase/firestore";
import CardTable from "../../Components/CardTable/CardTable";
// import Spinner from "../../Components/UI/Spinner/Spinner";
import calendar from "../../Assets/calendar.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import CardGraphic from "../../Components/CardTable/CardGraphic/CardGraphic";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import { useRef } from "react";
import md5 from "md5";

const ReportResale = () => {
  // const [open, setOpen] = useState(false);
  // const [rowId, setRowId] = useState(false);
  const [widthButton, setWidthButton] = useState("");
  // const [noContentPage, setNoContentPage] = useState({
  //   Profit: false,
  //   Entry: false,
  //   Outputs: false,
  // });
  const [monthEntry, setMonthEntry] = useState("");
  const [monthOutputs, setMonthOutputs] = useState("");
  const [users, setUsers] = useState("");
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [selectedDateInicial, setSelectedDateInicial] = useState();
  const [selectedDateFinal, setSelectedDateFinal] = useState();
  const [formatDatePickerDateInicial, setFormatDatePickerDateInicial] =
    useState();
  const [formatDatePickerDateFinal, setFormatDatePickerDateFinal] = useState();
  const [callFuncTotalFat, setCallFuncTotalFat] = useState(false);
  const [monthProfitByUser, setMonthProfitByUser] = useState("");
  const [monthProfitByUserFiltered, setMonthProfitByUserFiltered] = useState(
    []
  );
  const [monthOutputsByUser, setMonthOutputsByUser] = useState("");
  const [monthOutputsByUserFiltered, setMonthOutputsByUserFiltered] = useState(
    []
  );
  const [monthEntryByUser, setMonthEntryByUser] = useState("");
  const [monthEntryByUserFiltered, setMonthEntryByUserFiltered] = useState([]);

  const useCollectionClients = collection(firestore, "clients");
  const useCollectionCreditHistory = collection(firestore, "credit_history");

  const formatDatePickerDateInicialRef = useRef(formatDatePickerDateInicial);
  const formatDatePickerDateFinalRef = useRef(formatDatePickerDateFinal);
  const monthProfitByUserRef = useRef(monthProfitByUser);
  const monthProfitByUserFilteredRef = useRef(monthProfitByUserFiltered);

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    formatDatePickerDateInicialRef.current = formatDatePickerDateInicial;
    formatDatePickerDateFinalRef.current = formatDatePickerDateFinal;
    monthProfitByUserRef.current = monthProfitByUser;
    monthProfitByUserFilteredRef.current = monthProfitByUserFiltered;
  }, [
    formatDatePickerDateInicial,
    formatDatePickerDateFinal,
    monthProfitByUser,
    monthProfitByUserFiltered,
  ]);

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const transformarDataComplete = (dataString) => {
    if (dataString) {
      const [dataPart] = dataString.split(" ");
      const [, mes, ano] = dataPart.split("/");
      return `${mes}/${ano}`;
    }

    return ""; // Ou qualquer valor padrão que você deseje retornar quando a dataString for indefinida
  };

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  useEffect(() => {
    if (selectedDateInicial) {
      const FormatDatePickerDate = () => {
        const day = selectedDateInicial.format("DD");
        const month = selectedDateInicial.format("MM");
        const year = selectedDateInicial.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;
        setFormatDatePickerDateInicial(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateInicial]);

  useEffect(() => {
    if (selectedDateFinal) {
      const FormatDatePickerDate = () => {
        const day = selectedDateFinal.format("DD");
        const month = selectedDateFinal.format("MM");
        const year = selectedDateFinal.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDateFinal(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateFinal]);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    const getHistory = async () => {
      const data = await getDocs(useCollectionCreditHistory);
      setMonthOutputs(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "resale")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
      setMonthEntry(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "supplier")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );
      setMonthProfitByUser(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
                transformarDataComplete(date_create) &&
              user.type_user === "resale"
          )
      );
      setMonthOutputsByUser(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "resale")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
      );
      setMonthEntryByUser(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((billing) => billing.type_user === "supplier")
          .filter(
            (user) =>
              transformarDataComplete(user.date_create) ===
              transformarDataComplete(date_create)
          )
      );
    };
    const getUsers = async () => {
      const data = await getDocs(useCollectionClients);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
    };
    getUsers();
    // setNoContentPage(false);
    getHistory();
  }, []);

  /*************************************************************************** */

  useEffect(() => {
    function filterItemsByDateRange(items, startDate, endDate) {
      // Converter as datas para o formato de data para facilitar a comparação
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
      startDateObj.setHours(0, 0, 0, 0);
      endDateObj.setHours(0, 0, 0, 0);

      // Filtrar os itens que estão entre as datas especificadas
      return (
        items &&
        items.filter((item) => {
          // Converter a data de criação do item para o formato de data
          const itemDate = new Date(item.date_create);

          // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
          itemDate.setHours(0, 0, 0, 0);

          // Verificar se a data do item está dentro do intervalo especificado
          return itemDate >= startDateObj && itemDate <= endDateObj;
        })
      );
    }

    // Filtrar os itens pelo intervalo de datas
    const filteredOutputsAll = filterItemsByDateRange(
      monthProfitByUser,
      formatDatePickerDateInicial,
      formatDatePickerDateFinal
    );
    setMonthProfitByUserFiltered(filteredOutputsAll);

    filterItemsByDateRange();
  }, [callFuncTotalFat]);

  useEffect(() => {
    function filterItemsByDateRange(items, startDate, endDate) {
      // Converter as datas para o formato de data para facilitar a comparação
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
      startDateObj.setHours(0, 0, 0, 0);
      endDateObj.setHours(0, 0, 0, 0);

      // Filtrar os itens que estão entre as datas especificadas
      return (
        items &&
        items.filter((item) => {
          // Converter a data de criação do item para o formato de data
          const itemDate = new Date(item.date_create);

          // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
          itemDate.setHours(0, 0, 0, 0);

          // Verificar se a data do item está dentro do intervalo especificado
          return itemDate >= startDateObj && itemDate <= endDateObj;
        })
      );
    }

    // Filtrar os itens pelo intervalo de datas
    const filteredOutputsAll = filterItemsByDateRange(
      monthOutputsByUser,
      formatDatePickerDateInicial,
      formatDatePickerDateFinal
    );
    setMonthOutputsByUserFiltered(filteredOutputsAll);

    filterItemsByDateRange();
  }, [callFuncTotalFat]);

  // useEffect(() => {
  //   setNoContentPage((prevState) => ({
  //     ...prevState,
  //     Profit: monthProfitByUserFiltered.length === 0,
  //     Entry: monthEntryByUserFiltered.length === 0,
  //     Outputs: monthOutputsByUserFiltered.length === 0,
  //   }));
  // }, [
  //   monthProfitByUserFiltered,
  //   monthEntryByUserFiltered,
  //   monthOutputsByUserFiltered,
  // ]);

  useEffect(() => {
    function filterEntryByDateRange(items, startDate, endDate) {
      // Converter as datas para o formato de data para facilitar a comparação
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
      startDateObj.setHours(0, 0, 0, 0);
      endDateObj.setHours(0, 0, 0, 0);

      // Filtrar os itens que estão entre as datas especificadas
      return (
        items &&
        items.filter((item) => {
          // Converter a data de criação do item para o formato de data
          const itemDate = new Date(item.date_create);

          // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
          itemDate.setHours(0, 0, 0, 0);

          // Verificar se a data do item está dentro do intervalo especificado
          return itemDate >= startDateObj && itemDate <= endDateObj;
        })
      );
    }

    // Filtrar os itens pelo intervalo de datas
    const filteredEntryAll = filterEntryByDateRange(
      monthEntryByUser,
      formatDatePickerDateInicial,
      formatDatePickerDateFinal
    );
    setMonthEntryByUserFiltered(filteredEntryAll);

    filterEntryByDateRange();
  }, [callFuncTotalFat]);

  /*************************************************************************** */

  useEffect(() => {
    if (monthOutputsByUserFiltered) {
      setMonthOutputs(
        monthOutputsByUserFiltered
          .filter((billing) => billing.type_user === "resale")
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );

      // Outras operações de filtragem e configuração de estado...
    }
  }, [monthOutputsByUserFiltered]);

  useEffect(() => {
    if (monthEntryByUserFiltered) {
      setMonthEntry(
        monthEntryByUserFiltered
          .filter((billing) => billing.type_user === "supplier")
          .reduce((total, item) => {
            return total + parseFloat(item.cost_value || 0);
          }, 0)
      );

      // Outras operações de filtragem e configuração de estado...
    }
  }, [monthEntryByUserFiltered]);

  useEffect(() => {
    if (monthProfitByUser) {
      setMonthProfitByUserFiltered(monthProfitByUser);
    }
  }, [monthProfitByUser]);

  useEffect(() => {
    if (monthOutputsByUser) {
      setMonthOutputsByUserFiltered(monthOutputsByUser);
    }
  }, [monthOutputsByUser]);

  useEffect(() => {
    if (monthEntryByUser) {
      setMonthEntryByUserFiltered(monthEntryByUser);
    }
  }, [monthEntryByUser]);

  const hashedString = md5("hello world");
  console.log("hashedString", hashedString);

  return (
    <div className={classes.DashboardMain}>
      <ModalDefault
        openModal={openModalFilter}
        setOpenModal={setOpenModalFilter}
        selectedDateInicial={selectedDateInicial}
        setSelectedDateInicial={setSelectedDateInicial}
        selectedDateFinal={selectedDateFinal}
        setSelectedDateFinal={setSelectedDateFinal}
        // filter={filter}
        users={users}
        widthButton={widthButton}
        modalType="filtros data"
        title="filtros data"
        btnLeftText="filtrar"
        btnRightText="limpar filtro"
        callFunc={() => {
          setCallFuncTotalFat(!callFuncTotalFat);
          setOpenModalFilter(false);
        }}
        callFuncRight={() => {
          setCallFuncTotalFat(!callFuncTotalFat);
        }}
      />
      <CardTable
        CardGraphic={
          <>
            <CardGraphic
              width={widthButton > "768" ? "38%" : "100%"}
              height={widthButton > "768" ? "320px" : "240px"}
              title="Lucro"
              subTitle={formatarValor(monthOutputs - monthEntry)}
              widthButton={widthButton}
              graphicType="pie profit"
              // noContentPageProfit={noContentPage.Profit}
              monthProfitByUserFiltered={monthProfitByUserFiltered}
              monthProfitByUser={monthProfitByUser}
              formatDatePickerDateInicial={
                formatDatePickerDateInicialRef.current
              }
              formatDatePickerDateFinal={formatDatePickerDateFinalRef.current}
            />

            <CardGraphic
              width={widthButton > "768" ? "60%" : "100%"}
              height={widthButton > "768" ? "320px" : "240px"}
              title="Quantidade x Valor"
              widthButton={widthButton}
              graphicType="bar_amount_value"
              monthOutputsByUserFilteredDataset={monthOutputsByUserFiltered}
            />
            <CardGraphic
              width={widthButton > "768" ? "49%" : "100%"}
              height={widthButton > "768" ? "320px" : "240px"}
              title="Faturamento"
              widthButton={widthButton}
              subTitle={formatarValor(monthOutputs)}
              graphicType="pie"
              // noContentPage={noContentPage}
              monthOutputsByUserFiltered={monthOutputsByUserFiltered}
              monthOutputsByUser={monthOutputsByUser}
              formatDatePickerDateInicial={
                formatDatePickerDateInicialRef.current
              }
              formatDatePickerDateFinal={formatDatePickerDateFinalRef.current}
            />
            <CardGraphic
              width={widthButton > "768" ? "49%" : "100%"}
              height={widthButton > "768" ? "320px" : "240px"}
              title="Entradas"
              widthButton={widthButton}
              subTitle={formatarValor(monthEntry)}
              // noContentPage={noContentPage}
              graphicType="pie"
              monthOutputsByUserFiltered={monthEntryByUserFiltered}
              monthOutputsByUser={monthOutputsByUser}
              formatDatePickerDateInicial={
                formatDatePickerDateInicialRef.current
              }
              formatDatePickerDateFinal={formatDatePickerDateFinalRef.current}
            />
            {/* <CardGraphic
              width="100%"
              height={widthButton > "768" ? "320px" : "240px"}
              title="Histórico Recorrência"
              widthButton={widthButton}
              graphicType="bar_history_recurrence"
              users={users}
            /> */}
          </>
        }
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={dashboard} title="Relatórios - Revenda" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="escolher data"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={calendar}
                rightIcon={plusImg}
                width={widthButton > 768 ? "185px" : "100%"}
                callFunc={() => {
                  setOpenModalFilter(true);
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ReportResale;
