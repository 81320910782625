/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext } from "react";
import classes from "./Clients.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import danger_circle from "./../../Assets/danger_circle.svg";
import CardTable from "../../Components/CardTable/CardTable";
import TablePagination from "@mui/material/TablePagination";
import { firestore } from "../../Services/Firebase";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  // query,
  // where,
} from "firebase/firestore";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import copy from "../../Assets/copy.svg";
import edit from "../../Assets/edit.svg";
import envelope from "../../Assets/envelope.svg";
import whatsapp from "../../Assets/whatsapp.svg";
import reload from "../../Assets/reload.svg";
import arrowDown from "../../Assets/arrow_down.svg";
import arrowUp from "../../Assets/arrow_up.svg";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import { addDays, format, parse } from "date-fns";
import {
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";
import axios from "axios";

const Clients = () => {
  const { userLogged, setRefreshTopNav, refreshTopNav, topNavCall } =
    useContext(AuthGoogleContext);
  const [users, setUsers] = useState("");
  const [openModalCreateClient, setOpenModalCreateClient] = useState(false);
  const [openModalImportClient, setOpenModalImportClient] = useState(false);
  const [openModalEditClient, setOpenModalEditClient] = useState(false);
  const [widthButton, setWidthButton] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [callStatus, setCallStatus] = useState(false);
  const [bankGet, setBankGet] = useState("");
  const [supplier, setSupplier] = useState("");
  const [clientPlanGet, setClientPlanGet] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [userRowData, setUserRowData] = useState("");
  const [userRowDataAll, setUserRowDataAll] = useState("");
  const [openModalDeleteClient, setOpenModalDeleteClient] = useState(false);
  const [openModalUpdateExp, setOpenModalUpdateExp] = useState(false);
  const [openModalCopyInfo, setOpenModalCopyInfo] = useState(false);
  const [openModalIndicatedInfo, setOpenModalIndicatedInfo] = useState(false);
  const [openModalWhatsList, setOpenModalWhatsList] = useState(false);
  const [openModalSendSms, setOpenModalSendSms] = useState(false);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openModalExcelAlert, setOpenModalExcelAlert] = useState({
    expiration: false,
    monthCreate: false,
    hasRepeatedName: false,
    hasNotSupplier: false,
  });
  const [CallNewExpDate, setCallNewExpDate] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectCheckAll, setSelectCheckAll] = useState(false);
  const [IsVerifyRowSelection, setIsVerifyRowSelection] = useState("isNull");
  const [alertModal, setAlertModal] = useState(false);
  /**************************************** */
  const [clientName, setClientName] = useState("");
  const [clientBank, setClientBank] = useState("");
  const [clientPlanNamePost, setClientPlanNamePost] = useState("");
  const [clientPlanValuePost, setClientPlanValuePost] = useState("");
  const [clientExpirationDate, setClientExpirationDate] = useState("");
  const [clientFone, setClientFone] = useState("");
  const [clientLogin, setClientLogin] = useState("");
  const [indicatedBy, setIndicatedBy] = useState("");
  const [observation, setObservation] = useState("");
  const [clientKey, setClientKey] = useState("");
  const [clientSupplier, setClientSupplier] = useState("");
  const [filterSupplier, setFilterSupplier] = useState();
  const [clientStatus, setClientStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [selectMonthReload, setSelectMonthReload] = useState(30);
  const [getIsActive, setGetIsActive] = useState();
  const [filter, setFilter] = useState();
  const [filterNameValue, setFilterNameValue] = useState();
  const [defaultFilterStatus, setDefaultFilterStatus] = useState();
  const [expiredUsers, setExpiredUsers] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getRepeatedName, setGetRepeatedName] = useState([]);
  const [getSuppierNotReg, setGetSuppierNotReg] = useState([]);
  const [selectedDateInicial, setSelectedDateInicial] = useState();
  const [selectedDateFinal, setSelectedDateFinal] = useState();
  const [smsSendSucces, setSmsSendSucces] = useState();
  const [formatDatePickerDateInicial, setFormatDatePickerDateInicial] =
    useState();
  const [formatDatePickerDateFinal, setFormatDatePickerDateFinal] = useState();
  const [getCampaigns, setGetCampaigns] = useState();
  const [openModalWarningCli, setOpenModalWarningCli] = useState({
    emptyName: false,
    clientExists: false,
  });
  const [sendSMS, setSendSMS] = useState({
    title: "",
    message: "",
  });
  const [changeMessages, setChangeMessages] = useState();
  const [orderedColumn, setOrderedColumn] = useState({
    status: null,
    name: null,
    expiration: null,
    type: null,
    supplier: null,
  });
  const [loading, setLoading] = useState({
    content: true,
    send_sms: false,
  });
  const [noContentPage, setNoContentPage] = useState(false);
  const [callRegisteredUser, setCallRegisteredUser] = useState(false);

  /***************************************** */

  const NewUserRowData = { ...userRowData };

  const optionsBankGet =
    bankGet &&
    bankGet.map((item) => ({
      value: item.bankName,
      label: item.bankName,
    }));
  const optionsGetCampaigns =
    getCampaigns &&
    getCampaigns.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  const optionsSupplier =
    supplier &&
    supplier.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  const optionsApps = [
    { value: "Duplex", label: "Duplex" },
    { value: "Roku", label: "Roku" },
    { value: "Blink", label: "Blink" },
    { value: "Stb", label: "Stb" },
    { value: "Smarters", label: "Smarters" },
    { value: "Xciptv", label: "Xciptv" },
    { value: "Webplayer", label: "Webplayer" },
  ];

  const optionsClientPlan =
    clientPlanGet &&
    clientPlanGet.map((item) => ({
      value: item.clientPlanValue,
      label: item.clientPlanName,
    }));

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const hora = String(dataAtual.getHours()).padStart(2, "0");
  const minuto = String(dataAtual.getMinutes()).padStart(2, "0");
  const segundo = String(dataAtual.getSeconds()).padStart(2, "0");
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const date_create_complete = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano} ${hora}:${minuto}:${segundo}`;

  function compararDatasMaiorOrMenor(data1, data2) {
    const parts1 = data1.split("/");
    const date1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);

    const parts2 = data2.split("/");
    const date2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);

    if (date1.getTime() > date2.getTime()) {
      return "A primeira data é maior que a segunda.";
    } else if (date1.getTime() < date2.getTime()) {
      return "A primeira data é menor que a segunda.";
    } else {
      return "As datas são iguais.";
    }
  }

  useEffect(() => {
    if (selectedDateInicial) {
      const FormatDatePickerDate = () => {
        const day = selectedDateInicial.format("DD");
        const month = selectedDateInicial.format("MM");
        const year = selectedDateInicial.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;
        setFormatDatePickerDateInicial(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateInicial]);

  useEffect(() => {
    if (selectedDateFinal) {
      const FormatDatePickerDate = () => {
        const day = selectedDateFinal.format("DD");
        const month = selectedDateFinal.format("MM");
        const year = selectedDateFinal.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDateFinal(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateFinal]);

  useEffect(() => {
    const MapCheck = filter && filter?.map((ids) => ids.id);
    if (selectCheckAll) {
      setRowSelectionModel(MapCheck);
    } else if (!selectCheckAll) {
      setRowSelectionModel([]);
    }
  }, [filter, selectCheckAll]);

  useEffect(() => {
    setFilter(
      users && users?.filter((newUsers) => newUsers.status === "ativo")
    );
  }, [topNavCall.ActiveAll]);

  useEffect(() => {
    setFilter(
      users && users.filter((user) => user.expiration_date === date_create)
    );
  }, [topNavCall.ExpireToday]);

  useEffect(() => {
    setFilter(
      users &&
        users?.filter(
          (user) =>
            transformarData(user.expiration_date) ===
              transformarData(date_create) &&
            compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
              "A primeira data é menor que a segunda."
        )
    );
  }, [topNavCall.ExpireMonth]);

  useEffect(() => {
    setFilter(
      users &&
        users?.filter(
          (user) =>
            compararDatasMaiorOrMenor(user.expiration_date, date_create) ===
            "A primeira data é menor que a segunda."
        )
    );
  }, [topNavCall.ExpireAll]);

  useEffect(() => {
    setRefreshTopNav(!refreshTopNav);
  }, [users]);

  useEffect(() => {
    setExpiredUsers(
      users &&
        users
          .filter(
            (venc) =>
              compararDatasMaiorOrMenor(venc.expiration_date, date_create) ===
              "A primeira data é menor que a segunda."
          )
          .map((venc) => venc.id)
    );
    setActiveUsers(
      users &&
        users
          .filter(
            (venc) =>
              compararDatasMaiorOrMenor(venc.expiration_date, date_create) ===
                "A primeira data é maior que a segunda." ||
              compararDatasMaiorOrMenor(venc.expiration_date, date_create) ===
                "As datas são iguais."
          )
          .map((venc) => venc.id)
    );
  }, [users]);

  useEffect(() => {
    const UpdateClientExpiredUsers = async () => {
      try {
        // Mapeia o array de IDs e cria uma lista de promessas para cada operação de atualização
        const updatePromises =
          expiredUsers &&
          expiredUsers?.map(async (userId) => {
            const userDoc = doc(firestore, "clients", userId);
            await updateDoc(userDoc, {
              status: "inativo",
            });
          });

        // Aguarda todas as atualizações serem concluídas
        await Promise.all(updatePromises);

        // Atualiza o estado ou executa outras ações necessárias após as atualizações

        setCallStatus(!callStatus);
      } catch (error) {}
    };

    UpdateClientExpiredUsers();
  }, [expiredUsers]);

  useEffect(() => {
    const UpdateClientActiveUsers = async () => {
      try {
        // Mapeia o array de IDs e cria uma lista de promessas para cada operação de atualização
        const updatePromises =
          activeUsers &&
          activeUsers?.map(async (userId) => {
            const userDoc = doc(firestore, "clients", userId);
            await updateDoc(userDoc, {
              status: "ativo",
            });
          });

        // Aguarda todas as atualizações serem concluídas
        await Promise.all(updatePromises);

        // Atualiza o estado ou executa outras ações necessárias após as atualizações

        setCallStatus(!callStatus);
      } catch (error) {}
    };

    UpdateClientActiveUsers();
  }, [activeUsers]);

  // Estado para controlar a data selecionada no DatePicker
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateEdidClient, setSelectedDateEdidClient] = useState();
  const [formatDatePickerDate, setFormatDatePickerDate] = useState();
  const [formatDatePickerEditClient, setFormatDatePickerEditClient] = useState(
    userRowData.expiration_date
  );
  const [filtering, setFiltering] = useState();
  const [callFilter, setCallFilter] = useState(false);

  useEffect(() => {
    setFilter(filtering);
  }, [callFilter]);

  useEffect(() => {
    if (filterSupplier && getIsActive && formatDatePickerDate) {
      setFiltering(
        users &&
          users?.filter(
            (newUsers) =>
              newUsers.supplier === filterSupplier &&
              newUsers.status === getIsActive &&
              newUsers.expiration_date === formatDatePickerDate
          )
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (filterSupplier && getIsActive) {
      setFiltering(
        filter &&
          filter?.filter(
            (newUsers) =>
              newUsers.supplier === filterSupplier &&
              newUsers.status === getIsActive
          )
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (formatDatePickerDate && getIsActive) {
      setFiltering(
        filter &&
          filter?.filter(
            (newUsers) =>
              newUsers.status === getIsActive &&
              newUsers.expiration_date === formatDatePickerDate
          )
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (filterSupplier && formatDatePickerDate) {
      setFiltering(
        filter &&
          filter?.filter(
            (newUsers) =>
              newUsers.supplier === filterSupplier &&
              newUsers.expiration_date === formatDatePickerDate
          )
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (filterSupplier) {
      setFiltering(
        filter &&
          filter?.filter((newUsers) => newUsers.supplier === filterSupplier)
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (getIsActive) {
      setFiltering(
        filter && filter?.filter((newUsers) => newUsers.status === getIsActive)
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    } else if (formatDatePickerDate) {
      setFiltering(
        filter &&
          filter?.filter(
            (newUsers) => newUsers.expiration_date === formatDatePickerDate
          )
      );
      if (filtering?.length > 0) {
        setCallFilter(!callFilter);
      }
    }
  }, [refreshFilters, formatDatePickerDate, getIsActive, filterSupplier]);

  useEffect(() => {
    if (filterNameValue?.length > 0) {
      setFilter(
        defaultFilterStatus &&
          defaultFilterStatus?.filter((newUsers) =>
            newUsers.name.toLowerCase().includes(filterNameValue.toLowerCase())
          )
      );
    } else {
      setFilter(defaultFilterStatus);
    }
  }, [filterNameValue]);

  const transformarData = (dataString) => {
    const [, mes, ano] = dataString.split("/");
    return `${mes}/${ano}`;
  };

  function subtrairMeses(dataString, quantidadeMeses) {
    const partes = dataString.split("/");
    const mes = parseInt(partes[0], 10);
    const ano = parseInt(partes[1], 10);

    const data = new Date(ano, mes - 1, 1); // Subtrai 1 do mês, pois os meses em JavaScript são indexados de 0 a 11
    data.setMonth(data.getMonth() - quantidadeMeses);

    const novoMes = (data.getMonth() + 1).toString().padStart(2, "0");
    const novoAno = data.getFullYear();

    return `${novoMes}/${novoAno}`;
  }

  function compararDatas(data1, data2) {
    const partes1 = data1.split("/");
    const partes2 = data2.split("/");

    const ano1 = parseInt(partes1[1], 10);
    const mes1 = parseInt(partes1[0], 10);

    const ano2 = parseInt(partes2[1], 10);
    const mes2 = parseInt(partes2[0], 10);

    const date1 = new Date(ano1, mes1 - 1, 1); // Subtrai 1 do mês, pois os meses em JavaScript são indexados de 0 a 11
    const date2 = new Date(ano2, mes2 - 1, 1);

    return date1 > date2;
  }
  const month_create = transformarData(date_create);
  const dataWithoutTwelve = subtrairMeses(month_create, 12);

  useEffect(() => {
    if (selectedDate) {
      const FormatDatePickerDate = () => {
        const day = selectedDate.format("DD");
        const month = selectedDate.format("MM");
        const year = selectedDate.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDate(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDateEdidClient) {
      const FormatDatePickerEditPlan = () => {
        const day = selectedDateEdidClient.format("DD");
        const month = selectedDateEdidClient.format("MM");
        const year = selectedDateEdidClient.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerEditClient(formattedDate);
      };
      FormatDatePickerEditPlan();
    }
  }, [selectedDateEdidClient]);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    setUserRowData({
      ...NewUserRowData,
      status: clientStatus,
    });
  }, [clientStatus]);

  useEffect(() => {
    if (rowSelectionModel?.length === 1) {
      setIsVerifyRowSelection("isOne");
    } else if (rowSelectionModel?.length > 0) {
      setIsVerifyRowSelection("isThanOne");
    } else {
      setIsVerifyRowSelection("isNull");
    }
  }, [rowSelectionModel]);

  const UpdateClientNewExpDate = async (id) => {
    const userDoc = doc(firestore, "clients", id);
    await updateDoc(userDoc, {
      expiration_date: CallNewExpDateRef.current,
      last_expiration_date: date_create,
    });
    setCallUsers(!callUsers);
    UpdateCredit();
  };

  const UpdateCredit = async () => {
    const supplierGet = supplier.filter(
      (sup) => sup.name === userRowData.supplier
    );
    const supplierGetAmount = supplierGet[0]?.amount;
    const periodicity = selectMonthReload / 30;
    const coastPeriodicity = parseFloat(userRowData.plan_value) * periodicity;
    const newCostValueString =
      parseFloat(supplierGet[0]?.cost_value) - coastPeriodicity;
    const supplierGetAmountFinal = parseFloat(supplierGetAmount) - periodicity;
    const userDoc = doc(firestore, "credit_supplier", supplierGet[0].id);
    await updateDoc(userDoc, {
      amount: supplierGetAmountFinal,
      cost_value: newCostValueString,
    });
    setCallUsers(!callUsers);

    CreateHistoryLog(supplierGetAmount, supplierGetAmountFinal, periodicity);
    setOpenModalUpdateExp(false);
    setModalSuccess(true);
    setRefreshTopNav(!refreshTopNav);
  };

  const useCollectionCreditHistory = collection(firestore, "credit_history");
  const useCollectionCampaigns = collection(firestore, "sms_campaigns");
  const useCollectionSmsHistory = collection(firestore, "sms_history");

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionCampaigns);
      setGetCampaigns(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreateHistoryLog = async (
    supplierGetAmount,
    supplierGetAmountFinal,
    periodicity
  ) => {
    try {
      // Adição do documento ao Firestore
      const user = await addDoc(useCollectionCreditHistory, {
        operation_amount: periodicity,
        initial_amount: supplierGetAmount,
        final_amount: supplierGetAmountFinal,
        supplier: userRowData.supplier,
        user_logged: userLogged.name,
        bank: userRowData.bank,
        client: userRowData.name,
        cost_value: userRowData.plan_value * (selectMonthReload / 30),
        operation: "Saída",
        type_user: "client",
        date_create: date_create_complete,
      });

      setOpenModalUpdateExp(false);
      setModalSuccess(true);

      return user;
    } catch (error) {
      throw error; // Propaga o erro para que possa ser tratado posteriormente
    }
  };

  const UpdateClientEditPlan = async (id) => {
    const userDoc = doc(firestore, "clients", id);
    await updateDoc(userDoc, {
      name: NewUserRowData.name,
      bank: NewUserRowData.bank,
      client_plan: NewUserRowData.client_plan,
      plan_value: NewUserRowData.plan_value,
      expiration_date: formatDatePickerEditClient
        ? formatDatePickerEditClient
        : userRowData.expiration_date,
      fone: NewUserRowData.fone,
      login: NewUserRowData.login,
      senha: NewUserRowData.senha,
      supplier: NewUserRowData.supplier,
      obs: NewUserRowData.obs,
      indicated_by: NewUserRowData.indicated_by,
    });
    setCallUsers(!callUsers);
    setOpenModalEditClient(false);
    setModalSuccess(true);
    setFormatDatePickerEditClient();
  };

  const handleClickReload = () => {
    setOpenModalUpdateExp(true);
  };

  const useCollectionClients = collection(firestore, "clients");
  const useCollectionBank = collection(firestore, "bank");
  const useCollectionSupplier = collection(firestore, "credit_supplier");
  const useCollectionClientPlan = collection(firestore, "client_plans");

  /*************************** */

  const clientNameRef = useRef(clientName);
  const clientBankRef = useRef(clientBank);
  const clientPlanNamePostRef = useRef(clientPlanNamePost);
  const clientPlanValuePostRef = useRef(clientPlanValuePost);
  const clientExpirationDateRef = useRef(clientExpirationDate);
  const clientFoneRef = useRef(clientFone);
  const clientLoginRef = useRef(clientLogin);
  const indicatedByRef = useRef(indicatedBy);
  const observationRef = useRef(observation);
  const clientKeyRef = useRef(clientKey);
  const clientSupplierRef = useRef(clientSupplier);
  const clientStatusRef = useRef(clientStatus);
  const excelDataRef = useRef(excelData);
  const CallNewExpDateRef = useRef(CallNewExpDate);
  const selectMonthReloadRef = useRef(selectMonthReload);
  const formatDatePickerEditClientRef = useRef(formatDatePickerEditClient);
  const formatDatePickerDateInicialRef = useRef(formatDatePickerDateInicial);
  const formatDatePickerDateFinalRef = useRef(formatDatePickerDateFinal);
  const changeMessagesRef = useRef(changeMessages);

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    clientNameRef.current = clientName;
    clientBankRef.current = clientBank;
    clientPlanNamePostRef.current = clientPlanNamePost;
    clientPlanValuePostRef.current = clientPlanValuePost;
    clientExpirationDateRef.current = clientExpirationDate;
    clientFoneRef.current = clientFone;
    clientLoginRef.current = clientLogin;
    indicatedByRef.current = indicatedBy;
    observationRef.current = observation;
    clientKeyRef.current = clientKey;
    clientSupplierRef.current = clientSupplier;
    clientStatusRef.current = clientStatus;
    excelDataRef.current = excelData;
    CallNewExpDateRef.current = CallNewExpDate;
    selectMonthReloadRef.current = selectMonthReload;
    formatDatePickerEditClientRef.current = formatDatePickerEditClient;
    formatDatePickerDateInicialRef.current = formatDatePickerDateInicial;
    formatDatePickerDateFinalRef.current = formatDatePickerDateFinal;
    changeMessagesRef.current = changeMessages;
  }, [
    clientName,
    clientBank,
    clientPlanNamePost,
    clientPlanValuePost,
    clientExpirationDate,
    clientFone,
    clientLogin,
    indicatedBy,
    observation,
    clientKey,
    clientSupplier,
    clientStatus,
    excelData,
    CallNewExpDate,
    selectMonthReload,
    formatDatePickerEditClient,
    formatDatePickerDateInicial,
    formatDatePickerDateFinal,
    changeMessages,
  ]);

  function parseDate(str) {
    const [day, month, year] = str.split("/");
    return new Date(year, month - 1, day); // month - 1 porque os meses em JavaScript são indexados a partir de 0
  }

  useEffect(() => {
    if (formatDatePickerDateInicial && formatDatePickerDateFinal) {
      function filterItemsByDateRange(items, startDate, endDate) {
        // Converter as datas usando a função parseDate para garantir o formato correto
        const startDateObj = parseDate(startDate);
        const endDateObj = parseDate(endDate);

        // Definir manualmente os valores de hora, minuto, segundo e milissegundo para zero
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(0, 0, 0, 0);

        // Filtrar os itens que estão entre as datas especificadas
        return (
          items &&
          items.filter((item) => {
            // Converter a data de criação do item para o formato de data
            const itemDate = parseDate(item.expiration_date);
            itemDate.setHours(0, 0, 0, 0); // Normalizar a hora

            // Verificar se a data do item está dentro do intervalo especificado
            return itemDate >= startDateObj && itemDate <= endDateObj;
          })
        );
      }

      // Filtrar os itens pelo intervalo de datas
      const filteredOutputsAll = filterItemsByDateRange(
        filter,
        formatDatePickerDateInicial,
        formatDatePickerDateFinal
      );

      setFilter(filteredOutputsAll);
    }
  }, [callFilter]);

  useEffect(() => {
    setChangeMessages(
      userRowDataAll &&
        userRowDataAll.map((user) => {
          return {
            phoneNumber: user.fone.replace(/\D/g, ""),
            message: sendSMS.message.replace("{name}", user.name),
            name: user.name,
          };
        })
    );
  }, [sendSMS]);

  const sendSMSFunc = async () => {
    const apiUserId = "8809fe93dcfc75f765a53359570112a3";
    const apiSecret = "75f82ec0fd39f55425c350431ebd1e7e";

    // Função para obter o token de acesso
    async function getAccessToken() {
      try {
        const response = await axios.post(
          "https://api.sendpulse.com/oauth/access_token",
          {
            grant_type: "client_credentials",
            client_id: apiUserId,
            client_secret: apiSecret,
          }
        );
        return response.data.access_token;
      } catch (error) {
        console.error("Erro ao obter o token de acesso:", error);
        throw new Error("Falha ao obter token de acesso"); // Interrompe a execução
      }
    }

    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        console.error("Token de acesso não recebido.");
        return; // Sai da função se não houver accessToken
      }

      setLoading((prevState) => ({
        ...prevState,
        send_sms: true,
      }));

      if (
        !changeMessagesRef.current ||
        changeMessagesRef.current.length === 0
      ) {
        console.error("Nenhuma mensagem para enviar.");
        return;
      }

      for (const messageData of changeMessagesRef.current) {
        const { phoneNumber, message, name } = messageData;
        const smsData = {
          sender: "Matheus",
          body: message,
          phones: [phoneNumber],
        };

        const response = await axios
          .post("https://api.sendpulse.com/sms/send", smsData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            try {
              const user = addDoc(useCollectionSmsHistory, {
                // Utiliza os valores das refs diretamente
                message_sended: message,
                number: phoneNumber,
                campaign_id: response.data.campaign_id,
                client: name,
                user_logged: userLogged.name,
                date_create: date_create_complete,
              });

              return user;
            } catch (error) {
              console.error("Erro ao adicionar documento:", error);
              throw error;
            }
          });
        console.log(
          `SMS enviado com sucesso para ${phoneNumber}:`,
          response.data
        );
        setOpenModalSendSms(false);
        setLoading((prevState) => ({
          ...prevState,
          send_sms: false,
        }));
      }
      setSmsSendSucces(true);
    } catch (error) {
      console.error(
        "Erro ao enviar SMS:",
        error.response ? error.response.data : error.message
      );
      setLoading((prevState) => ({
        ...prevState,
        send_sms: false,
      }));
    }
  };

  const CreateClientPlan = async () => {
    const UsersList = users && users?.map((supppier) => supppier.name);

    if (clientNameRef.current.length === 0) {
      setOpenModalWarningCli((prevState) => ({
        ...prevState,
        emptyName: true,
      }));
    } else if (
      UsersList.some(
        (nome) => nome.toLowerCase() === clientNameRef.current.toLowerCase()
      )
    ) {
      setOpenModalWarningCli((prevState) => ({
        ...prevState,
        clientExists: true,
      }));
    } else {
      try {
        const user = await addDoc(useCollectionClients, {
          // Utiliza os valores das refs diretamente
          name: clientNameRef.current,
          bank: clientBankRef.current,
          client_plan: clientPlanNamePostRef.current,
          plan_value: clientPlanValuePostRef.current,
          expiration_date: formatDatePickerEditClientRef.current,
          fone: clientFoneRef.current,
          login: clientLoginRef.current,
          senha: clientKeyRef.current,
          supplier: clientSupplierRef.current,
          status: clientStatusRef.current ? "ativo" : "inativo",
          obs: observationRef.current,
          indicated_by: indicatedByRef.current,
          last_expiration_date: "",
          company: userLogged.company,
          month_create: month_create,
          date_create,
        });

        setCallUsers(!callUsers);
        setOpenModalCreateClient(false);
        setModalSuccess(true);
        setFormatDatePickerEditClient();

        return user;
      } catch (error) {
        throw error;
      }
    }
  };
  const calcularNovaData = () => {
    // Verificar se userRowData e userRowData.expiration_date estão definidos e não são vazios
    if (
      userRowData?.expiration_date &&
      userRowData.expiration_date.trim() !== ""
    ) {
      const dataInicial = parse(
        userRowData?.expiration_date,
        "dd/MM/yyyy",
        new Date()
      );

      const novaData = addDays(dataInicial, selectMonthReload);

      const novaDataFormatada = format(novaData, "dd/MM/yyyy");

      setCallNewExpDate(novaDataFormatada);
      return novaDataFormatada;
    }

    return null;
  };

  function formatarData(data) {
    // Verifica se a variável data é uma string
    if (typeof data !== "string") {
      return data; // Retorna a data original se não for uma string
    }

    const partes = data.split("/");
    const dia = String(partes[1]).padStart(2, "0");
    const mes = String(partes[0]).padStart(2, "0");
    const ano = "20" + String(partes[2]).padStart(2, "0"); // Assumindo que todos os anos estão no século 21 (2000-2099)

    return `${dia}/${mes}/${ano}`;
  }

  const CreateClientPlanExcel = async (clientDataExcel) => {
    try {
      const user = await addDoc(useCollectionClients, {
        // Utiliza os valores das refs diretamente
        name: String(clientDataExcel?.B),
        bank: String(clientDataExcel?.H),
        client_plan: String(clientDataExcel?.C),
        plan_value: String(clientDataExcel?.D),
        expiration_date: String(formatarData(clientDataExcel?.E)),
        fone: String(clientDataExcel?.I),
        login: String(clientDataExcel?.J),
        senha: String(clientDataExcel?.K),
        supplier: String(clientDataExcel?.A),
        obs: clientDataExcel?.K ? String(clientDataExcel?.G) : "",
        indicated_by: "",
        last_expiration_date: "",
        company: userLogged.company,
        month_create: String(clientDataExcel?.F),
        date_create,
      });
      setCallUsers(!callUsers);
      setOpenModalImportClient(false);
      setCallRegisteredUser(!callRegisteredUser);

      return user;
    } catch (error) {
      throw error;
    }
  };

  // Itera sobre a estrutura de dados e chama a função CreateClientPlan para cada objeto
  // useEffect(() => {
  const fetchData = async () => {
    const usersName = users.map((user) => user.name);
    const supplierName = supplier.map((user) => user.name);
    const hasMissingKeyword = excelData?.some((date) => {
      if (typeof date.A === "string") {
        const names = date.A.split(",").map((name) => name.trim());
        return !supplierName.some((keyword) => names.includes(keyword));
      }
      return false;
    });

    if (
      excelData &&
      !excelData?.every((date) => /^\d{1,2}\/\d{1,2}\/\d{2}$/.test(date.E))
    ) {
      setOpenModalExcelAlert((prevState) => ({
        ...prevState,
        expiration: true,
      }));
    } else if (
      excelData &&
      !excelData?.every((date) => /^\d{1,2}\/\d{4}$/.test(date.F))
    ) {
      setOpenModalExcelAlert((prevState) => ({
        ...prevState,
        monthCreate: true,
      }));
    } else if (
      excelData &&
      excelData?.flatMap((date) => {
        if (typeof date.B === "string") {
          const names = date.B.split(",").map((name) => name.trim());
          const presentNames = names.filter((name) => {
            const lowercaseName = name.toLowerCase();
            const match = usersName.some((user) => {
              const lowercaseUser = user.toLowerCase();
              return lowercaseUser === lowercaseName;
            });
            return match;
          });
          return presentNames;
        }
        return [];
      }).length > 0
    ) {
      setOpenModalExcelAlert((prevState) => ({
        ...prevState,
        hasRepeatedName: true,
      }));
      const casa_forte =
        excelData &&
        excelData?.flatMap((date) => {
          if (typeof date.B === "string") {
            const names = date.B.split(",").map((name) => name.trim());
            const presentNames = names.filter((name) =>
              usersName.some(
                (user) => user.toLowerCase() === name.toLowerCase()
              )
            );
            return presentNames;
          }
          return [];
        });

      setGetRepeatedName(casa_forte);
    } else if (hasMissingKeyword) {
      setOpenModalExcelAlert((prevState) => ({
        ...prevState,
        hasNotSupplier: true,
      }));

      const casa_forte = excelData.flatMap((date) => {
        if (typeof date.A === "string") {
          const names = date.A.split(",").map((name) => name.trim());
          const notPresentNames = names.filter(
            (name) =>
              !supplierName.some(
                (user) => user.toLowerCase() === name.toLowerCase()
              )
          );
          return notPresentNames;
        }
        return [];
      });

      setGetSuppierNotReg(casa_forte);
    } else {
      //Itera sobre a estrutura de dados e chama a função CreateClientPlanExcel para cada objeto
      for (const clientDataExcel of excelDataRef.current) {
        await CreateClientPlanExcel(clientDataExcel);
      }
    }
  };

  //   fetchData();
  // }, [excelDataRef.current]);
  /*************************** */

  useEffect(() => {
    const getUsers = async () => {
      setLoading((prevState) => ({
        ...prevState,
        content: true,
      }));
      const data = await getDocs(useCollectionClients);
      setUsers(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
      setFilter(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading((prevState) => ({
        ...prevState,
        content: false,
      }));
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionClients);
      setFilter(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setDefaultFilterStatus(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setRefreshFilters(!refreshFilters);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus]);

  useEffect(() => {
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getBankGet = async () => {
      const data = await getDocs(useCollectionBank);
      setBankGet(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getClientPlan = async () => {
      const data = await getDocs(useCollectionClientPlan);
      setClientPlanGet(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBankGet();
    getSupplier();
    getClientPlan();
  }, []);

  useEffect(() => {
    const getSupplier = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSupplier();
  }, [callUsers]);

  const HandleSelect = (event) => {
    setSelectMonthReload(event.target.value);
  };

  useEffect(() => {
    calcularNovaData();
  }, [selectMonthReload, userRowData?.expiration_date]);

  useEffect(() => {
    calcularNovaData();
  }, [userRowData?.expiration_date]);

  const handleSetInfoRowData = (button) => {
    if (IsVerifyRowSelection === "isThanOne") {
      let callUserAll = users.filter((row) =>
        rowSelectionModel.includes(row.id)
      );
      setUserRowDataAll(callUserAll);
    } else if (IsVerifyRowSelection === "isOne") {
      users.filter((row) => row.id === rowSelectionModel);
      let callUser = users.filter((row) => row.id == rowSelectionModel);
      setUserRowDataAll(callUser);
      if (button === "reload") {
        handleClickReload(callUser[0]);
      }
    } else if (IsVerifyRowSelection === "isNull") {
      setAlertModal(true);
    }
  };

  const DeleteClient = async (id) => {
    const userDoc = doc(firestore, "clients", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteClient(false);
    setModalSuccess(true);
  };

  useEffect(() => {
    if (orderedColumn.status !== null) {
      setFilter(
        filter &&
          filter.sort((a, b) => {
            // Comparação quando 'name' é a chave ordenada
            if (a.status < b.status) {
              return orderedColumn.status === "cres" ? -1 : 1; // Se crescente, a vem antes de b (a-z)
            }
            if (a.status > b.status) {
              return orderedColumn.status === "desc" ? -1 : 1; // Se descendente, b vem antes de a (z-a)
            }
            return 0; // Se iguais, mantém a ordem atual
          })
      );
    }
    if (orderedColumn.name !== null) {
      setFilter(
        filter &&
          filter.sort((a, b) => {
            // Comparação quando 'name' é a chave ordenada
            if (a.name < b.name) {
              return orderedColumn.name === "cres" ? -1 : 1; // Se crescente, a vem antes de b (a-z)
            }
            if (a.name > b.name) {
              return orderedColumn.name === "desc" ? -1 : 1; // Se descendente, b vem antes de a (z-a)
            }
            return 0; // Se iguais, mantém a ordem atual
          })
      );
    }
    if (orderedColumn.expiration !== null) {
      setFilter(
        filter &&
          filter.sort((a, b) => {
            // Converter strings de data em objetos Date
            const dateA = new Date(
              a.expiration_date.split("/").reverse().join("-")
            );
            const dateB = new Date(
              b.expiration_date.split("/").reverse().join("-")
            );

            // Comparação de datas
            if (dateA < dateB) {
              return orderedColumn.expiration === "cres" ? -1 : 1; // Se crescente, a vem antes de b
            }
            if (dateA > dateB) {
              return orderedColumn.expiration === "desc" ? -1 : 1; // Se descendente, b vem antes de a
            }
            return 0; // Se iguais, mantém a ordem atual
          })
      );
    }
  }, [orderedColumn, filter]);

  useEffect(() => {}, [filter]);

  const columns = [
    { id: "check", label: "check", flex: 1 },
    {
      id: "status",
      label: (
        <button
          onClick={() =>
            setOrderedColumn((prevState) => ({
              ...prevState,
              status:
                orderedColumn.status === null
                  ? "cres"
                  : orderedColumn.status === "cres"
                  ? "desc"
                  : orderedColumn.status === "desc"
                  ? "cres"
                  : null,
              name: null,
              expiration: null,
              type: null,
            }))
          }
          className={classes.ColumnsButtonTitle}
        >
          status
          <img
            src={orderedColumn.status === "cres" ? arrowUp : arrowDown}
            alt=""
          />
        </button>
      ),
      minWidth: 50,
      align: "center",
    },
    {
      id: "name",
      label: (
        <button
          onClick={() =>
            setOrderedColumn((prevState) => ({
              ...prevState,
              name:
                orderedColumn.name === null
                  ? "cres"
                  : orderedColumn.name === "cres"
                  ? "desc"
                  : orderedColumn.name === "desc"
                  ? "cres"
                  : null,
              status: null,
              expiration: null,
              type: null,
            }))
          }
          className={classes.ColumnsButtonTitle}
        >
          Nome
          <img
            src={orderedColumn.name === "cres" ? arrowUp : arrowDown}
            alt=""
          />
        </button>
      ),
      flex: 1,
    },
    {
      id: "fone",
      label: "telefone",
      flex: 1,
    },
    { id: "plan_value", label: "valor plano", flex: 1 },
    {
      id: "expiration_date",
      label: (
        <button
          onClick={() =>
            setOrderedColumn((prevState) => ({
              ...prevState,
              expiration:
                orderedColumn.expiration === null
                  ? "cres"
                  : orderedColumn.expiration === "cres"
                  ? "desc"
                  : orderedColumn.expiration === "desc"
                  ? "cres"
                  : null,
              name: null,
              status: null,
              type: null,
            }))
          }
          className={classes.ColumnsButtonTitle}
        >
          expiração
          <img src={!orderedColumn.expiration ? arrowDown : arrowUp} alt="" />
        </button>
      ),
      flex: 1,
    },
    {
      id: "type",
      label: "tipo",
      flex: 1,
    },
    { id: "month_create", label: "mês criação", flex: 1 },
    {
      id: "supplier",
      label: "fornecedor",
      flex: 1,
    },
    { id: "obs", label: "obs", flex: 1 },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];
  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = filter && filter.slice(startIndex, endIndex);

  const formatarValor = (valor) => {
    const valorNumerico = parseFloat(valor);
    return `R$ ${valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={openModalDeleteClient}
        setOpenModal={setOpenModalDeleteClient}
        title={`Deseja realmente excluir o usuário '${userRowData?.name}'`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteClient(userRowData.id)}
      />
      <ModalSmall
        openModal={alertModal}
        setOpenModal={setAlertModal}
        title={`Selecione um usuário!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={modalSuccess}
        setOpenModal={setModalSuccess}
        title={`Operação concluída com sucesso!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={smsSendSucces}
        setOpenModal={setSmsSendSucces}
        title={`Lista de SMS enviada com sucesso!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalWarningCli.clientExists}
        setOpenModal={setOpenModalWarningCli}
        title={`Já existe na base um Cliente com este nome. Altere!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalWarningCli.emptyName}
        setOpenModal={setOpenModalWarningCli}
        title={`O nome do Cliente não pode estar vazio. Altere!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalExcelAlert.expiration}
        setOpenModal={setOpenModalExcelAlert}
        title={
          "A coluna 'expiração' está com dados incorretos. O padrão é 'dd/mm/aaaa'. Ajuste!"
        }
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalExcelAlert.monthCreate}
        setOpenModal={setOpenModalExcelAlert}
        title={
          "A coluna 'mês criação' está com dados incorretos. O padrão é 'mm/aaaa'. Ajuste!"
        }
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalExcelAlert.hasRepeatedName}
        setOpenModal={setOpenModalExcelAlert}
        isWarning={true}
        title={
          <div>
            O campo 'nome' está com usuários já existentes na base. Seguem nomes
            repetidos. Ajuste!
            <br />
            {
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "80px",
                  margin: "15px 0",
                }}
              >
                {getRepeatedName.map((tes) => (
                  <div
                    style={{
                      color: "#6e6e6e",
                    }}
                  >
                    {tes}
                  </div>
                ))}
              </div>
            }
          </div>
        }
        isDelete={false}
      />
      <ModalSmall
        openModal={openModalExcelAlert.hasNotSupplier}
        setOpenModal={setOpenModalExcelAlert}
        isWarning={true}
        title={
          <div>
            Existem fornecedores na lista não cadastrados no sistema. Cadastre
            esses fornecedores!
            <br />
            {
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "80px",
                  margin: "15px 0",
                }}
              >
                {getSuppierNotReg.map((tes) => (
                  <div
                    style={{
                      color: "#6e6e6e",
                    }}
                  >
                    {tes}
                  </div>
                ))}
              </div>
            }
          </div>
        }
        isDelete={false}
      />
      <ModalSmall
        openModal={openModalUpdateExp}
        setOpenModal={setOpenModalUpdateExp}
        selectMonthReload={selectMonthReload}
        title={
          <>
            <div className={classes.ClientPlanArea}>
              <div className={classes.ClientPlanButtons}>
                <div className={classes.ButtonArea}>
                  <FormLabel
                    style={{
                      color: "#3487be",
                      fontSize: widthButton > "768" ? "14px" : "12px",
                    }}
                  >
                    Renovar Usuário
                  </FormLabel>

                  <Select
                    style={{
                      border: "1.5px solid #3487be",
                      borderRadius: "5px",
                      color: "#3487be",
                      padding: "0 10px",
                      fontSize: widthButton > "768" ? "14px" : "12px",
                      height: "30px",
                      width:
                        widthButton > "1200"
                          ? "30vw"
                          : widthButton > "768"
                          ? "28vw"
                          : "86vw",
                      maxWidth: widthButton > "768" ? "300px" : "100vw",
                    }}
                    sx={{
                      "& .MuiSelect-icon": {
                        color: "#3487be", // Define a cor do ícone como vermelho
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={HandleSelect}
                    value={selectMonthReload}
                  >
                    <MenuItem
                      sx={{
                        "&.MuiInputBase-root": {
                          color: "#3487be",
                          "&.Mui-focused": {
                            color: "#3487be !important",
                          },
                        },
                        "& .MuiSelect-icon": {
                          color: "#3487be",
                        },
                      }}
                      value={30}
                    >
                      Renovar 1 mês
                    </MenuItem>
                    <MenuItem
                      sx={{
                        "&.MuiInputBase-root": {
                          color: "#3487be",
                          "&.Mui-focused": {
                            color: "#3487be !important",
                          },
                        },
                        "& .MuiSelect-icon": {
                          color: "#3487be",
                        },
                      }}
                      value={60}
                    >
                      Renovar 2 meses
                    </MenuItem>
                    <MenuItem
                      sx={{
                        "&.MuiInputBase-root": {
                          color: "#275370",
                          "&.Mui-focused": {
                            color: "#275370 !important",
                          },
                        },
                        "& .MuiSelect-icon": {
                          color: "#275370",
                        },
                      }}
                      value={90}
                    >
                      Renovar 3 meses
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <br />
            {userRowData?.last_expiration_date === date_create && (
              <div style={{ fontSize: "15px", color: "red" }}>
                <b>OBS:</b> o usuário foi atualizado hoje. Deseja atualizar
                novamente?
              </div>
            )}
          </>
        }
        btnLeftText="Renovar"
        isDelete={false}
        callFunc={() => UpdateClientNewExpDate(userRowData.id)}
      />
      <ModalDefault
        openModal={openModalCreateClient}
        setOpenModal={setOpenModalCreateClient}
        optionsBankGet={optionsBankGet}
        optionsSupplier={optionsSupplier}
        optionsClientPlan={optionsClientPlan}
        setClientName={setClientName}
        setClientBank={setClientBank}
        setClientPlanNamePost={setClientPlanNamePost}
        setClientPlanValuePost={setClientPlanValuePost}
        setClientExpirationDate={setClientExpirationDate}
        setClientFone={setClientFone}
        setClientLogin={setClientLogin}
        setClientKey={setClientKey}
        setClientSupplier={setClientSupplier}
        setClientStatus={setClientStatus}
        setIndicatedBy={setIndicatedBy}
        setObservation={setObservation}
        setSelectedDateEdidClient={setSelectedDateEdidClient}
        clientStatus={clientStatus}
        widthButton={widthButton}
        modalType="cadastrar cliente"
        title="cadastrar cliente"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          CreateClientPlan();
        }}
      />
      <ModalDefault
        openModal={openModalImportClient}
        setOpenModal={setOpenModalImportClient}
        excelData={excelDataRef.current}
        setExcelData={setExcelData}
        widthButton={widthButton}
        modalType="importar cliente"
        title="importar cliente"
        callCsvList="true"
        callRegisteredUser={callRegisteredUser}
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          fetchData();
        }}
      />
      <ModalDefault
        openModal={openModalEditClient}
        setOpenModal={setOpenModalEditClient}
        setClientName={setClientName}
        clientName={clientName}
        optionsBankGet={optionsBankGet}
        optionsSupplier={optionsSupplier}
        optionsClientPlan={optionsClientPlan}
        NewUserRowData={NewUserRowData}
        setUserRowData={setUserRowData}
        setClientStatus={setClientStatus}
        selectedDateEdidClient={selectedDateEdidClient}
        setSelectedDateEdidClient={setSelectedDateEdidClient}
        clientStatus={clientStatus}
        userRowData={userRowData}
        widthButton={widthButton}
        modalType="editar cliente"
        title="editar cliente"
        btnLeftText="salvar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          UpdateClientEditPlan(userRowData.id);
        }}
      />
      <ModalDefault
        openModal={openModalCopyInfo}
        setOpenModal={setOpenModalCopyInfo}
        optionsSupplier={optionsSupplier}
        setClientName={setClientName}
        userRowData={userRowData}
        widthButton={widthButton}
        modalType="copiar informações"
        title="copiar informações"
        optionsApps={optionsApps}
        // btnLeftText="salvar"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          // CreateClientPlanExcel();
        }}
      />
      <ModalDefault
        openModal={openModalIndicatedInfo}
        setOpenModal={setOpenModalIndicatedInfo}
        userRowData={userRowData}
        users={users}
        widthButton={widthButton}
        modalType="indicações"
        title="indicações"
        // btnLeftText="salvar"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          // CreateClientPlanExcel();
        }}
      />
      <ModalDefault
        openModal={openModalWhatsList}
        setOpenModal={setOpenModalWhatsList}
        userRowDataAll={userRowDataAll}
        users={users}
        widthButton={widthButton}
        modalType="lista envio whatsapp"
        title="lista envio whatsapp"
        // btnLeftText="salvar"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          // CreateClientPlanExcel();
        }}
      />
      <ModalDefault
        openModal={openModalSendSms}
        setOpenModal={setOpenModalSendSms}
        userRowDataAll={userRowDataAll}
        users={users}
        widthButton={widthButton}
        sendSMS={sendSMS}
        setSendSMS={setSendSMS}
        getCampaigns={getCampaigns}
        optionsGetCampaigns={optionsGetCampaigns}
        modalType="enviar sms"
        title="enviar sms"
        btnLeftText="enviar"
        loadingSms={loading.send_sms}
        btnCloseModalText="cancelar"
        callFunc={() => {
          sendSMSFunc();
        }}
      />
      <ModalDefault
        openModal={openModalFilter}
        setOpenModal={setOpenModalFilter}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        getIsActive={getIsActive}
        setGetIsActive={setGetIsActive}
        filterSupplier={filterSupplier}
        hasSelectActive="true"
        filter={filter}
        optionsSupplier={optionsSupplier}
        setFilterSupplier={setFilterSupplier}
        selectedDateInicial={selectedDateInicial}
        setSelectedDateInicial={setSelectedDateInicial}
        selectedDateFinal={selectedDateFinal}
        setSelectedDateFinal={setSelectedDateFinal}
        users={users}
        widthButton={widthButton}
        modalType="filtros"
        title="filtros"
        btnLeftText="filtrar"
        btnRightText="limpar filtros"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          setCallFilter(!callFilter);
          setOpenModalFilter(false);
        }}
        callFuncRight={() => {
          setFilter(users);
          setOpenModalFilter(false);
          setFilterSupplier();
          setSelectedDateInicial();
          setSelectedDateFinal();
          setGetIsActive();
          setFormatDatePickerDate();
          setSelectedDate();
        }}
      />
      <CardTable
        title="Lista de Clientes"
        hasFilterArea="true"
        param={filter}
        FilterAreaCallFunc={() => {
          setOpenModalFilter(true);
        }}
        hasFilterName="true"
        CheckboxCallFunc={() => {
          setSelectCheckAll(!selectCheckAll);
        }}
        setFilterNameValue={setFilterNameValue}
        hasCheckbox="true"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Lista de clientes" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="enviar sms"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={envelope}
                rightIcon={plusImg}
                width={widthButton > 768 ? "150px" : "100%"}
                callFunc={() => {
                  handleSetInfoRowData("whatsApp");
                  IsVerifyRowSelection !== "isNull" &&
                    setOpenModalSendSms(true);
                }}
              />
              <GeneralButton
                text="lista whatsapp"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : "0 0 0 10px"}
                leftIcon={whatsapp}
                rightIcon={plusImg}
                width={widthButton > 768 ? "160px" : "100%"}
                callFunc={() => {
                  handleSetInfoRowData("whatsApp");
                  IsVerifyRowSelection !== "isNull" &&
                    setOpenModalWhatsList(true);
                }}
              />
              <GeneralButton
                text="cadastrar cliente"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : "0 0 0 10px"}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "180px" : "100%"}
                callFunc={() => setOpenModalCreateClient(true)}
              />
              <GeneralButton
                text="importar"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : "0 0 0 10px"}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "150px" : "100%"}
                callFunc={() => setOpenModalImportClient(true)}
              />
            </div>
          </div>
        }
        table={
          loading.content ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="cliente" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "65vh" }}>
                {widthButton > 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize:
                                widthButton > "1920"
                                  ? "14px"
                                  : widthButton > "1200"
                                  ? "10px"
                                  : "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize:
                                        widthButton > "1920"
                                          ? "14px"
                                          : widthButton > "1200"
                                          ? "10px"
                                          : "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "action" ? (
                                      (value = (
                                        <div
                                          className={classes.TableActionButtons}
                                        >
                                          <button
                                            title="Usuários indicados"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalIndicatedInfo(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={danger_circle} alt="" />
                                          </button>
                                          <button
                                            title="Editar usuário"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalEditClient(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={edit} alt="" />
                                          </button>
                                          <button
                                            title="Copiar informações"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalCopyInfo(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={copy} alt="" />
                                          </button>
                                          <button
                                            title="Renovar usuário"
                                            onClick={() => {
                                              setUserRowData(row);
                                              handleClickReload(row);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={reload} alt="" />
                                          </button>
                                          <button
                                            title="Deletar usuário"
                                            onClick={() => {
                                              setUserRowData(row);
                                              setOpenModalDeleteClient(true);
                                            }}
                                            className={classes.DeleteButton}
                                          >
                                            <img src={deleteImg} alt="" />
                                          </button>
                                        </div>
                                      ))
                                    ) : column.id === "status" &&
                                      value === "inativo" ? (
                                      <div
                                        className={classes.TableStatusInactive}
                                      >
                                        {value}
                                      </div>
                                    ) : column.id === "status" &&
                                      value === "ativo" ? (
                                      <div
                                        className={classes.TableStatusActive}
                                      >
                                        {value}
                                      </div>
                                    ) : column.id === "check" ? (
                                      <div className={classes.TableCheck}>
                                        {value}
                                        <Checkbox
                                          // defaultChecked={selectCheckAll}
                                          checked={
                                            selectCheckAll
                                              ? selectCheckAll
                                              : rowSelectionModel.includes(
                                                  row.id
                                                )
                                          }
                                          onClick={() => {
                                            setRowSelectionModel(
                                              (prevSelection) => {
                                                if (
                                                  prevSelection.includes(row.id)
                                                ) {
                                                  // Se já está presente, remove
                                                  return prevSelection.filter(
                                                    (id) => id !== row.id
                                                  );
                                                } else {
                                                  // Se não está presente, adiciona
                                                  return [
                                                    ...prevSelection,
                                                    row.id,
                                                  ];
                                                }
                                              }
                                            );
                                          }}
                                          sx={{
                                            color: "#0091d7",
                                            width: "35px",
                                            heigth: "35px",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : column.id === "type" ? (
                                      <div>
                                        {compararDatas(
                                          row.month_create,
                                          dataWithoutTwelve
                                        )
                                          ? "Novo"
                                          : "Antigo"}
                                      </div>
                                    ) : column.id === "plan_value" ? (
                                      <div>{formatarValor(value)}</div>
                                    ) : column.id === "fone" ? (
                                      row.fone
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
                {widthButton < 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columnsCollapse.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columnsCollapse.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "collapse" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            selectCheckAll
                                              ? selectCheckAll
                                              : rowSelectionModel.includes(
                                                  row.id
                                                )
                                          }
                                          onClick={() => {
                                            setRowSelectionModel(
                                              (prevSelection) => {
                                                if (
                                                  prevSelection.includes(row.id)
                                                ) {
                                                  // Se já está presente, remove
                                                  return prevSelection.filter(
                                                    (id) => id !== row.id
                                                  );
                                                } else {
                                                  // Se não está presente, adiciona
                                                  return [
                                                    ...prevSelection,
                                                    row.id,
                                                  ];
                                                }
                                              }
                                            );
                                          }}
                                          sx={{
                                            color: "#0091d7",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                        />
                                        <div style={{ fontSize: "16px" }}>
                                          {row.name}
                                        </div>

                                        <IconButton
                                          sx={{
                                            color: "#0091d7",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => {
                                            setRowId(row.id);
                                            setOpen(!open);
                                          }}
                                        >
                                          <img
                                            style={{ width: "25px" }}
                                            src={
                                              open && row.id == rowId
                                                ? closeIcon
                                                : openIcon
                                            }
                                            alt=""
                                          />
                                        </IconButton>
                                      </div>
                                    ) : (
                                      value
                                    )}
                                    {row.id == rowId && (
                                      <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box
                                          sx={{
                                            margin: 1,
                                            color: "#FFF",
                                            fontSize: "11px",
                                          }}
                                        >
                                          <Table
                                            size="small"
                                            aria-label="purchases"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Itens
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Informações
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"STATUS:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.status}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"NOME:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.name}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"VALOR DO PLANO:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.plan_value}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"EXPIRAÇÃO:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.expiration_date}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"TIPO:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {compararDatas(
                                                    row.month_create,
                                                    dataWithoutTwelve
                                                  )
                                                    ? "Novo"
                                                    : "Antigo"}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"MÊS CRIAÇÃO:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.month_create}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"FORNECEDOR:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.supplier}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"OBS:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.obs}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"AÇÕES:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  <div
                                                    className={
                                                      classes.TableActionButtons
                                                    }
                                                  >
                                                    <button
                                                      title="Usuários indicados"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalIndicatedInfo(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img
                                                        src={danger_circle}
                                                        alt=""
                                                      />
                                                    </button>
                                                    <button
                                                      title="Editar usuário"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalEditClient(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img src={edit} alt="" />
                                                    </button>
                                                    <button
                                                      title="Copiar informações"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalCopyInfo(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img src={copy} alt="" />
                                                    </button>
                                                    <button
                                                      title="Renovar usuário"
                                                      onClick={() => {
                                                        handleClickReload(row);
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img
                                                        src={reload}
                                                        alt=""
                                                      />
                                                    </button>
                                                    <button
                                                      title="Deletar usuário"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalDeleteClient(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img
                                                        src={deleteImg}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={filter && filter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Clients;
