import classes from "./Title.module.scss";

const Title = ({ icon = null, title = null }) => {
  return (
    <div className={classes.TitleMain}>
      <img className={classes.Icon} src={icon} alt="" />
      <div className={classes.Title}>{title}</div>
    </div>
  );
};
export default Title;
