/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classes from "./ModalCustomCopyInfo.module.scss";
import { firestore } from "../../../../Services/Firebase";
import {
  collection,
  getDocs,
  // query,
  // where,
} from "firebase/firestore";

const ModalCustomCopyInfo = ({ stepSupplier, userRowData, serverApp }) => {
  const codeXciptvRef = useRef(null);

  const [supplier, setSupplier] = useState("");
  const [copyInfo, setCopyInfo] = useState(false);

  const useCollectionSupplier = collection(firestore, "credit_supplier");

  const copyToClipboard = () => {
    if (codeXciptvRef.current) {
      const range = document.createRange();
      range.selectNode(codeXciptvRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionSupplier);
      setSupplier(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        // .filter((user) => user.company === userLogged.company)
      );
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setCopyInfo(false);
  }, [stepSupplier]);

  return (
    <>
      <div className={classes.ModalDefault}>
        <div className={classes.ContentPlace} ref={codeXciptvRef}>
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <div>
                  {serverApp === "Duplex" && (
                    <li>
                      Lista m3u:
                      <label>{`http://${actualSupplier.m3u_link}/get.php?username=${userRowData.login}&password=${userRowData.senha}&type=m3u_plus&output=ts`}</label>
                    </li>
                  )}
                  <br />
                </div>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <div>
                  {serverApp === "Roku" && (
                    <div style={{ color: "#fff", fontSize: "11px" }}>
                      <div>
                        Liga a tv, aperta na casinha do controle vai para o lado
                        esquerdo procuro CANAIS DE STREAMING entre nele Aperta
                        pro lado esquerdo e click em buscar
                      </div>
                      <br />
                      <div>digite : ibo pro</div>
                      <br />
                      <div>
                        entre no meta símbolo laranja depois ADICIONAR CANAL
                        depois IR PARA CANAL , DEPOIS DE INSTALADO IRÁ APARECER
                        DOIS CÓDIGOS EM AMARELO OU BRANCO COPIE ELES AQUI E
                        MANDE DIGITADO ASSIM FACILITARÁ O ATENDIMENTO
                      </div>
                    </div>
                  )}
                  <br />
                </div>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {serverApp === "Blink" && (
                    <div>
                      <li>
                        Nome do perfil:
                        <label>{actualSupplier.playlist_name}</label>
                      </li>
                      <li>
                        Url: <label>{actualSupplier.url}</label>
                      </li>
                      <li>
                        usuário: <label>{userRowData.login}</label>
                      </li>
                      <li>
                        Senha: <label>{userRowData.senha}</label>
                      </li>
                    </div>
                  )}
                </>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {serverApp === "Stb" && (
                    <div style={{ color: "#fff", fontSize: "11px" }}>
                      <div>
                        <b> Samsung Serie J</b>
                      </div>
                      <br />
                      <div>1º - Acesse o MENU de config</div>
                      <div>2º - Entre no menu REDE</div>
                      <div>3º - Clique em STATUS DE REDE</div>
                      <div>4º - Clique em CONFIG. IP</div>
                      <div>
                        5º - Altere o Servidor DNS para: "OBTER MANUALMENTE" "
                      </div>
                      <div>
                        {`6º - Digite o Dns: ${
                          actualSupplier.dns_number_one
                        } ou ${actualSupplier.dns_number_two} ou ${
                          actualSupplier.dns_number_three &&
                          actualSupplier.dns_number_three
                        } e OK`}
                      </div>
                      <br />
                      <div>Ao fazer desliga a tv por 2 min e liga</div>
                    </div>
                  )}
                </>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {serverApp === "Smarters" && (
                    <div>
                      <li>
                        Nome do usuário:
                        <label>{actualSupplier.playlist_name}</label>
                      </li>
                      <li>
                        usuário: <label>{userRowData.login}</label>
                      </li>
                      <li>
                        Senha: <label>{userRowData.senha}</label>
                      </li>
                      <li>
                        Url: <label>{actualSupplier.url}</label>
                      </li>
                    </div>
                  )}
                </>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {serverApp === "Xciptv" && (
                    <div>
                      <li>
                        Url: <label>{actualSupplier.url}</label>
                      </li>
                      <li>
                        usuário: <label>{userRowData.login}</label>
                      </li>
                      <li>
                        Senha: <label>{userRowData.senha}</label>
                      </li>
                    </div>
                  )}
                </>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {serverApp === "Webplayer" && (
                    <div>
                      <div>
                        <div>Webplayer</div>
                        <li>
                          Site: <label>{actualSupplier.web_player}</label>
                        </li>
                        <li>
                          usuário: <label>{userRowData.login}</label>
                        </li>
                        <li>
                          Senha: <label>{userRowData.senha}</label>
                        </li>
                      </div>
                      <br/>
                      {actualSupplier.web_player_two && (
                        <div>
                          <div>Webplayer 2</div>
                          <li>
                            Site:{" "}
                            <label>{actualSupplier.web_player_two} </label>
                          </li>
                          <li>
                            usuário: <label>{userRowData.login}</label>
                          </li>
                          <li>
                            Senha: <label>{userRowData.senha}</label>
                          </li>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))}
          {/* {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  <div>
                    {actualSupplier.dns_number_one && (
                      <li>
                        Dns 01: <label>{actualSupplier.dns_number_one}</label>{" "}
                      </li>
                    )}

                    {actualSupplier.dns_number_two && (
                      <li>
                        Dns 02: <label>{actualSupplier.dns_number_two}</label>
                      </li>
                    )}

                    {actualSupplier.dns_number_three && (
                      <li>
                        Dns 03: <label>{actualSupplier.dns_number_three}</label>
                      </li>
                    )}

                    {(actualSupplier.dns_number_one ||
                      actualSupplier.dns_number_two ||
                      actualSupplier.dns_number_three) && (
                      <>
                        <li>
                          Login: <label>{userRowData.login}</label>
                        </li>

                        <li>
                          Senha: <label>{userRowData.senha}</label>
                        </li>
                      </>
                    )}
                  </div>
                </>
              ))}
          {supplier &&
            supplier
              .filter((actualSupplier) => actualSupplier.name === stepSupplier)
              .map((actualSupplier) => (
                <>
                  {actualSupplier.url && (
                    <div>
                      {actualSupplier.playlist_name && (
                        <li>
                          Profile: <label>{actualSupplier.playlist_name}</label>
                        </li>
                      )}
                      <li>
                        Url: <label>{actualSupplier.url}</label>
                      </li>
                      <li>
                        Login: <label>{userRowData.login}</label>
                      </li>
                      <li>
                        Senha: <label>{userRowData.senha}</label>
                      </li>
                    </div>
                  )}
                </>
              ))} */}
        </div>
      </div>
      <button
        className={
          copyInfo ? classes.ButtonConfirmActive : classes.ButtonConfirm
        }
        onClick={() => {
          copyToClipboard();
          setCopyInfo(true);
        }}
      >
        {copyInfo ? "Copiado" : "Copiar informações"}
      </button>
    </>
  );
};

export default ModalCustomCopyInfo;
