/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./Login.module.scss";
import { useContext, useState, useEffect } from "react";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import google_logo from "../../Assets/google_logo.svg";
import loading from "../../Assets/loading.gif";
import { firestore } from "../../Services/Firebase";
import { collection, getDocs, where, query } from "firebase/firestore";

const Login = () => {
  const location = useLocation();
  const { pathname } = location;
  const partesDaURL = pathname.split("/");
  const CompanyUrl = partesDaURL[2]; //
  const { HandleGoogleSignIn, signed, user } = useContext(AuthGoogleContext);
  const [infoCompany, setInfoCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userNotLogged, setUserNotLogged] = useState(false);

  async function LoginGoogle() {
    await HandleGoogleSignIn();
  }

  useEffect(() => {
    if (CompanyUrl) {
      const getCompanyByEmail = async () => {
        setIsLoading(true);
        const CompanyCollection = collection(firestore, "company");
        const q = await getDocs(
          query(
            CompanyCollection,
            where("createCompany_name", "==", CompanyUrl)
          )
        );

        if (q.docs.length > 0) {
          const userData = q.docs[0].data();
          setInfoCompany(userData);
          setIsLoading(false);
        } else {
          setInfoCompany(false);
          setIsLoading(false);
        }
      };
      getCompanyByEmail();
    }
  }, []);

  if (!user && !signed) {
    return (
      <div className={classes.LoginMain}>
        <div className={classes.LoginCard}>
          <div className={classes.LoginTitle}>Bem vindo</div>
          {!isLoading ? (
            <img
              className={classes.LoginCompanyImg}
              src={infoCompany && infoCompany.createCompany_image_url}
              alt=""
            />
          ) : (
            <img className={classes.LoginCompanyImg} src={loading} alt="" />
          )}
          <div className={classes.LoginDescText}>
            Continue seu login manualmente através do botão abaixo
          </div>
          <GeneralButton
            text="Continuar com o google"
            leftIcon={google_logo}
            backgroundColor="#FFF"
            padding="12px 14px"
            borderRadius="8px"
            color="#06080A"
            fontSize="14px"
            fontWeight="bold"
            callFunc={() => {
              LoginGoogle();
              setUserNotLogged(true);
            }}
          />
          <div className={classes.LoginWarningText}>
            Ao logar com o google, nenhum dos seus dados de login serão coletados nem armazenados em qualquer banco de dados
          </div>
          {/* <div>
            <a href="URL_DO_SEU_DESTINO">Já tenho uma conta</a>
          </div> */}
        </div>
      </div>
    );
  } else if (user && !signed && userNotLogged) {
    return (
      <div className={classes.LoginMain}>
        <div className={classes.LoginCard}>
          <div className={classes.LoginTitle}>{`Olá, ${user.email}`}</div>
          <div className={classes.LoginDescText}>
            Você ainda não possui conta na plataforma. Procure o administrador
            da sua empresa e solicite um link de cadastro.
          </div>
          <img className={classes.LoginCompanyImg} src={loading} alt="" />
        </div>
      </div>
    );
  } else if (user && signed) {
    return <Navigate to="/dashboard" />;
  }
};

export default Login;
