import classes from "./NoContentPage.module.scss";
import loading from "../../../Assets/no_content_page.svg";

const NoContentPage = ({ tableItem }) => {
  return (
    <div className={classes.SpinnerMain}>
      <img className={classes.SpinnerCompanyImg} src={loading} alt="" />
      <div className={classes.SpinnerText}>
        {`Você ainda não possui nenhum ${tableItem} cadastrado `}{" "}
      </div>
    </div>
  );
};
export default NoContentPage;
