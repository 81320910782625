import classes from "./GeneralButton.module.scss";

const GeneralButton = ({
  leftIcon = null,
  rightIcon = null,
  text = null,
  width,
  height,
  fontSize,
  border = null,
  padding,
  callFunc,
  backgroundColor,
  color,
  margin,
  borderRadius,
  fontWeight,
}) => {
  return (
    <div
      className={classes.ButtonMain}
      style={{
        width: width,
        height: height,
        border: border,
        padding: padding,
        backgroundColor: backgroundColor,
        color: color,
        margin: margin,
        borderRadius: borderRadius,
        fontWeight: fontWeight,
      }}
      onClick={callFunc}
    >
      {leftIcon && <img className={classes.LeftIcon} src={leftIcon} alt="" />}
      <div className={classes.Text} style={{ fontSize: fontSize }}>
        {text}
      </div>
      {rightIcon && <img className={classes.LeftIcon} src={rightIcon} alt="" />}
    </div>
  );
};
export default GeneralButton;
