/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import classes from "./Layout.module.scss";
import AsideNav from "./../../Layouts/AsideNav/AsideNav";
import TopNav from "../../Layouts/TopNav/TopNav";
import Main from "./../../Layouts/Main";
import { AuthGoogleContext } from "../../Contexts/authGoogle";

const Layout = ({ children }) => {
  document.getElementById("load").style.display = "none";
  let grid_12 = "col-12";
  const { signed } = useContext(AuthGoogleContext);
  const _main = (
    <>
      <Main className={signed ? classes.MainLogged : classes.Main}>
        <div>
          <div className={grid_12}>{children}</div>
        </div>
      </Main>
    </>
  );

  let _content = (
    <div className={classes.Layout}>
      {signed && <AsideNav className={classes.Aside} />}
      <div className={classes.LayoutRight}>
        {signed && <TopNav />}
        {_main}
      </div>
    </div>
  );

  return _content;
};
export default Layout;
