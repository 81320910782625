/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import classes from "./ModalSmall.module.scss";

const ModalSmall = ({
  openModal = null,
  setOpenModal = null,
  textDesc = null,
  title = null,
  callFunc,
  btnLeftText,
  isDelete = false,
  modalType,
  companyRowData,
  selectMonthReload,
  isWarning = false,
}) => {
  const [content, setContent] = useState(false);
  const [copyInfo, setCopyInfo] = useState(false);
  const codeBlinkRef = useRef(null);

  const copyToClipboard = () => {
    if (codeBlinkRef.current) {
      const range = document.createRange();
      range.selectNode(codeBlinkRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  };

  useEffect(() => {
    setCopyInfo(false);
  }, [openModal]);

  useEffect(() => {
    setContent(
      openModal ? (
        <div className={classes.ModalSmall}>
          {modalType === "sendCompanyLink" ? (
            <>
              <div>
                <div ref={codeBlinkRef} className={classes.Link}>
                  {`https://mpadmin-d4396.web.app/login/${companyRowData.createCompany_name}`}
                </div>
              </div>
              <div className={classes.ButtonsArea}>
                <button
                  onClick={() => {
                    copyToClipboard();
                    setCopyInfo(true);
                  }}
                  className={
                    copyInfo
                      ? classes.ButtonConfirmActive
                      : classes.ButtonConfirm
                  }
                >
                  {copyInfo ? "Copiado" : "Copiar informações"}
                </button>

                <button
                  onClick={() => setOpenModal(false)}
                  className={classes.ButtonCancel}
                >
                  cancelar
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={classes.Title}>{title}</div>
              <div>{textDesc}</div>
              <div className={classes.ButtonsArea}>
                {btnLeftText && (
                  <button
                    onClick={callFunc}
                    className={
                      isDelete ? classes.ButtonDelete : classes.ButtonConfirm
                    }
                  >
                    {btnLeftText}
                  </button>
                )}
                <button
                  onClick={() => setOpenModal(false)}
                  className={classes.ButtonCancel}
                >
                  {isWarning ? "ok" : "cancelar"}
                </button>
              </div>
            </>
          )}
        </div>
      ) : null
    );
  }, [openModal, copyInfo, selectMonthReload]);

  return content;
};
export default ModalSmall;
