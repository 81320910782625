/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./CardGraphic.module.scss";
import iconInfo from "../../../Assets/icon_info.svg";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts";
import * as React from "react";
import { axisClasses } from "@mui/x-charts";
import { useState } from "react";
import { useEffect } from "react";
// import NoContentPage from "../../UI/NoContentPage/NoContentPage";

const CardGraphic = ({
  width,
  height,
  title,
  subTitle,
  graphicType,
  monthOutputsByUser,
  monthOutputsByUserFilteredDataset,
  users,
  monthOutputsByUserFiltered,
  monthProfitByUser,
  monthProfitByUserFiltered,
  widthButton,
  noContentPage,
}) => {
  const [groupedBySupplier, setGroupedBySupplier] = useState();
  const [profitBySupplier, setProfitBySupplier] = useState();
  const [dataset, setDataset] = useState();

  const chartSetting = {
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  useEffect(() => {
    if (monthOutputsByUserFilteredDataset) {
      const newDataset =
        Array.isArray(monthOutputsByUserFilteredDataset) &&
        monthOutputsByUserFilteredDataset.length > 0 &&
        Object.values(
          monthOutputsByUserFilteredDataset.reduce((acc, res) => {
            const supplier = res.supplier;
            const costValue = parseFloat(res.cost_value);

            if (!acc[supplier]) {
              acc[supplier] = {
                Quantidade: parseFloat(res.operation_amount),
                Valor: costValue,
                month: supplier,
              };
            } else {
              acc[supplier].Quantidade += parseFloat(res.operation_amount);
              acc[supplier].Valor += costValue;
            }

            return acc;
          }, {})
        );
      setDataset(newDataset || []);
    } else {
      setDataset([]);
    }
  }, [monthOutputsByUserFilteredDataset]);

  const dataset_history =
    (Array.isArray(users) && // Verifica se users é um array
      users.length > 0 && // Verifica se users não está vazio
      Object.values(
        users.reduce((acc, res) => {
          const month_create = res.month_create;

          // Verificar se month_create está definido e não é vazio
          if (month_create && month_create.trim() !== "") {
            const quantidade = 1; // Contar cada item individualmente

            if (!acc[month_create]) {
              acc[month_create] = {
                "Número de vendas": 1, // Iniciar com 1 para contar o primeiro item
                Permaneceram: res.status === "ativo" ? 1 : 0, // Se o status for ativo, conta 1, caso contrário, 0
                Cancelados: res.status !== "ativo" ? 1 : 0, // Se o status não for ativo, conta 1, caso contrário, 0
                month: month_create,
              };
            } else {
              acc[month_create]["Número de vendas"] += quantidade;
              acc[month_create].Permaneceram +=
                res.status === "ativo" ? quantidade : 0;
              acc[month_create].Cancelados +=
                res.status !== "ativo" ? quantidade : 0;
            }
          }

          return acc;
        }, {})
      ).sort((a, b) => {
        // Extrair a parte numérica do mês/ano (ignorando a parte do mês/ano)
        const [aMonth, aYear] = a.month.split("/");
        const [bMonth, bYear] = b.month.split("/");
        const aDate = new Date(aYear, aMonth);
        const bDate = new Date(bYear, bMonth);
        return aDate - bDate; // Ordenar por ordem crescente de data
      })) ||
    []; // Retorna um array vazio se users for falso

  const valueFormatter = (value) => `R$ ${value},00`;
  // const valueFormatterPie = (value) => `R$ ${value}`;

  useEffect(() => {
    if (
      monthProfitByUser &&
      Array.isArray(monthProfitByUserFiltered) && // Verifica se é um array
      monthProfitByUserFiltered.length > 0 // Verifica se o array não está vazio
    ) {
      setGroupedBySupplier(
        monthProfitByUserFiltered.reduce((acc, item) => {
          const supplier = item.supplier;
          const operation = item.operation;
          const costValue = parseFloat(item.cost_value);

          if (!acc[supplier]) {
            acc[supplier] = { entries: 0, exits: 0 };
          }

          if (operation === "Entrada") {
            acc[supplier].entries += costValue;
          } else if (operation === "Saída") {
            acc[supplier].exits += costValue;
          }

          return acc;
        }, {})
      );
    } else {
      // Se monthProfitByUserFiltered for falso, defina groupedBySupplier como um objeto vazio
      setGroupedBySupplier({});
    }
  }, [monthProfitByUserFiltered, monthProfitByUser]);

  useEffect(() => {
    if (groupedBySupplier !== null && groupedBySupplier !== undefined) {
      setProfitBySupplier(
        Object.keys(groupedBySupplier).map((supplier) => {
          const { entries, exits } = groupedBySupplier[supplier];
          return {
            id: supplier,
            value: exits - entries,
            label: supplier,
          };
        })
      );
    }
  }, [groupedBySupplier]);

  return (
    <div
      className={classes.CardContent}
      style={{ width: width, height: height }}
    >
      <div className={classes.CardTop}>
        <div className={classes.CardTopLeft}>
          <div className={classes.CardLeftTitle}>{title}</div>
          <div className={classes.CardLeftSubTitle}>{subTitle}</div>
        </div>
        <img src={iconInfo} alt="info" />
      </div>
      <div className={classes.CardBottom}>
        {graphicType === "pie profit" &&
          profitBySupplier !== undefined &&
          profitBySupplier !== null && (
            <PieChart
              series={[
                {
                  data: Object.values(profitBySupplier),
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -10,
                    color: "gray",
                  },
                },
              ]}
              width={380}
              height={widthButton > "768" ? 180 : 120}
            />
          )}
        {graphicType === "pie" && monthOutputsByUser && (
          <PieChart
            series={[
              {
                data: Object.values(
                  (monthOutputsByUserFiltered || []).reduce((acc, res) => {
                    const supplier = res.supplier;
                    const cost_value = parseFloat(res.cost_value);
                    if (!acc[supplier]) {
                      acc[supplier] = {
                        id: supplier,
                        value: cost_value,
                        label: supplier,
                        valueFormatter,
                      };
                    } else {
                      acc[supplier].value += cost_value;
                    }
                    return acc;
                  }, {})
                ),
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -10,
                  color: "gray",
                },
              },
            ]}
            width={380}
            height={widthButton > "768" ? 180 : 120}
          />
        )}
        {graphicType === "bar_amount_value" &&
          dataset &&
          dataset.length > 0 && ( // Verifica se o dataset não está vazio
            <BarChart
              dataset={dataset}
              xAxis={[{ scaleType: "band", dataKey: "month" }]}
              series={[
                { dataKey: "Quantidade", label: "Quantidade" },
                { dataKey: "Valor", label: "Valor", valueFormatter },
              ]}
              {...chartSetting}
              width={
                widthButton > "2000"
                  ? 700
                  : widthButton > "1600"
                  ? 600
                  : widthButton > "1200"
                  ? 500
                  : widthButton > "768"
                  ? 400
                  : 500
              }
              height={widthButton > "768" ? 250 : 180}
            />
          )}
        {graphicType === "bar_history_recurrence" && users.length > 0 && (
          <BarChart
            dataset={dataset_history}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={[
              { dataKey: "Número de vendas", label: "Número de vendas" },
              { dataKey: "Permaneceram", label: "Permaneceram" },
              { dataKey: "Cancelados", label: "Cancelados" },
            ]}
            {...chartSetting}
            width={
              widthButton > "2000"
                ? 1800
                : widthButton > "1600"
                ? 1400
                : widthButton > "1200"
                ? 1000
                : widthButton > "768"
                ? 900
                : 500
            }
            height={widthButton > "768" ? 250 : 180}
          />
        )}
      </div>
    </div>
  );
};
export default CardGraphic;
