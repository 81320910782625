/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./SignUp.module.scss";
import { useContext, useState, useEffect } from "react";
import { AuthGoogleContext } from "../../Contexts/authGoogle";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import google_logo from "../../Assets/google_logo.svg";
import loading from "../../Assets/loading.gif";
import { firestore } from "../../Services/Firebase";
import { addDoc, collection, getDocs, where, query } from "firebase/firestore";

const SignUp = () => {
  const location = useLocation();
  const { pathname } = location;
  const partesDaURL = pathname.split("/");
  const CompanyUrl = partesDaURL[2]; //
  const { HandleGoogleSignIn, signed, user } = useContext(AuthGoogleContext);
  const [infoCompany, setInfoCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [users, setUsers] = useState("");
  const [userExist, setUserExist] = useState();
  const [status, setStatus] = useState("");
  const [hasUserGoogle, setHasUserGoogle] = useState(false);
  const [pendingScreen, setPendingScreen] = useState(false);

  const useCollection = collection(firestore, "users");

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  /********************************************************** */

  async function LoginGoogle() {
    await HandleGoogleSignIn();
  }

  useEffect(() => {
    if (user && users.find((item) => item.email === user.email)) {
      setUserExist(true);
    } else if (user && users.find((item) => item.email !== user.email)) {
      setUserExist(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && !userExist) {
      setName(user.displayName);
      setEmail(user.email);
      setCompany(infoCompany.createCompany_name);
      setStatus("pendente");
      setHasUserGoogle(true);
    }
  }, [userExist]);

  useEffect(() => {
    if (name && email && company) {
      const CreateUser = async () => {
        const user = await addDoc(useCollection, {
          name,
          email,
          company,
          date_create,
          permission: {
            admin: true,
            clients: {
              clients: true,
              plans: true,
              report: true,
              sms_campaign: true,
              sms_history: true,
            },
            credits: {
              bank: true,
              credit_list: true,
              history: true,
              supplier: true,
            },
            dashboard: true,
            resale: {
              financial_history: true,
              report: true,
              resale: true,
            },
            top_nav_btn: {
              active: true,
              credit_cart: true,
              expire_all: true,
              expire_month: true,
              expire_today: true,
              monthly_billing: true,
              profit_month: true,
              resale: true,
            },
          },
          status,
        });
        setPendingScreen(true);
        return user;
      };
      CreateUser();
    }
  }, [hasUserGoogle]);

  useEffect(() => {
    if (CompanyUrl) {
      const getCompanyByEmail = async () => {
        setIsLoading(true);
        const CompanyCollection = collection(firestore, "company");
        const q = await getDocs(
          query(
            CompanyCollection,
            where("createCompany_name", "==", CompanyUrl)
          )
        );

        if (q.docs.length > 0) {
          const userData = q.docs[0].data();
          setInfoCompany(userData);
          setIsLoading(false);
        } else {
          setInfoCompany(false);
          setIsLoading(false);
        }
      };
      const getUsers = async () => {
        const useCollectionUsers = collection(firestore, "users");
        const data = await getDocs(useCollectionUsers);
        setUsers(
          data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          // .filter((user) => user.company === userLogged.company)
        );
      };
      getUsers();
      getCompanyByEmail();
    }
  }, []);

  if (!user && !signed) {
    return (
      <div className={classes.LoginMain}>
        <div className={classes.LoginCard}>
          <div className={classes.LoginTitle}>Bem vindo</div>
          {!isLoading ? (
            <img
              className={classes.LoginCompanyImg}
              src={infoCompany && infoCompany.createCompany_image_url}
              alt=""
            />
          ) : (
            <img className={classes.LoginCompanyImg} src={loading} alt="" />
          )}
          <div className={classes.LoginDescText}>
            {`Continue seu Cadastro na empresa "${infoCompany.createCompany_name}" através do botão abaixo`}
          </div>
          <GeneralButton
            text="Cadastrar com o google"
            leftIcon={google_logo}
            backgroundColor="#FFF"
            padding="12px 14px"
            borderRadius="8px"
            color="#06080A"
            fontSize="14px"
            fontWeight="bold"
            callFunc={() => LoginGoogle()}
          />
        </div>
      </div>
    );
  } else if (user && !signed && pendingScreen) {
    return (
      <div className={classes.LoginMain}>
        <div className={classes.LoginCard}>
          <div className={classes.LoginTitle}>Bem vindo</div>
          {!isLoading ? (
            <img
              className={classes.LoginCompanyImg}
              src={infoCompany && infoCompany.createCompany_image_url}
              alt=""
            />
          ) : (
            <img className={classes.LoginCompanyImg} src={loading} alt="" />
          )}
          <div className={classes.LoginDescText}>
            <div>
              Seu acesso se encontra pendente. Aguarde o administrador do
              sistema pela liberação do seu cadastro.
            </div>
            <br />
            <div>
              A liberação pode levar até 48h úteis, após esse período, você
              poderá acessar o sistema.
            </div>
          </div>
        </div>
      </div>
    );
  } else if (user && signed) {
    return <Navigate to="/clientes" />;
  }
};

export default SignUp;
