import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASjLr68HyivYpkYqH0lLYpVXzpO_j-DFw",
  authDomain: "mpadmin-d4396.firebaseapp.com",
  projectId: "mpadmin-d4396",
  storageBucket: "mpadmin-d4396.appspot.com",
  messagingSenderId: "111904594266",
  appId: "1:111904594266:web:1844ec2f4b122ae1ed3b63",
  measurementId: "G-KWKVY43CZ1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
