/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import classes from "./Sms.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import axios from "axios";
import {
  collection,
  getDocs,
  // query,
  // where,
} from "firebase/firestore";
import { firestore } from "../../Services/Firebase";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import { useRef } from "react";

const Sms = () => {
  const [widthButton, setWidthButton] = useState("");
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [getSmsHistory, setGetSmsHistory] = useState([]);
  const [loading, setLoading] = useState({
    content: true,
  });
  const [filter, setFilter] = useState();
  const [filterNameValue, setFilterNameValue] = useState();
  const [combinedArray, setCombinedArray] = useState();
  const [callFilter, setCallFilter] = useState(false);
  // const [adminInfo, setAdminInfo] = useState([]);

  const [campaigns, setCampaigns] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [groupedObjects, setGroupedObjects] = useState([]);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [selectedDateInicial, setSelectedDateInicial] = useState();
  const [formatDatePickerDateInicial, setFormatDatePickerDateInicial] =
    useState();
  const [selectedDateFinal, setSelectedDateFinal] = useState();
  const [formatDatePickerDateFinal, setFormatDatePickerDateFinal] = useState();

  const formatDatePickerDateInicialRef = useRef(formatDatePickerDateInicial);
  const formatDatePickerDateFinalRef = useRef(formatDatePickerDateFinal);

  useEffect(() => {
    formatDatePickerDateInicialRef.current = formatDatePickerDateInicial;
    formatDatePickerDateFinalRef.current = formatDatePickerDateFinal;
  }, [formatDatePickerDateInicial, formatDatePickerDateFinal]);

  console.log("formatDatePickerDateInicial", formatDatePickerDateInicial);
  console.log("formatDatePickerDateFinal", formatDatePickerDateFinal);

  const useCollectionSmsHistory = collection(firestore, "sms_history");
  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(useCollectionSmsHistory);
      setGetSmsHistory(
        data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((objeto) => objeto.date_create)
          .sort(compararPorDateCreate)
      );
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterNameValue?.length > 0) {
      setFilter(
        combinedArray &&
          combinedArray?.filter((newUsers) =>
            newUsers.client
              .toLowerCase()
              .includes(filterNameValue.toLowerCase())
          )
      );
    } else {
      setFilter(combinedArray);
    }
  }, [filterNameValue]);

  const compararPorDateCreate = (a, b) => {
    const extrairDataHora = (dataString) => {
      const [dataParte, horaParte] = dataString.split(" ");
      const [dia, mes, ano] = dataParte.split("/");
      const [hora, minuto, segundo] = horaParte.split(":");
      return new Date(ano, mes - 1, dia, hora, minuto, segundo);
    };

    const dataA = extrairDataHora(a.date_create);
    const dataB = extrairDataHora(b.date_create);

    // Compara as datas em ordem decrescente
    return dataB - dataA;
  };

  console.log("combinedArray", combinedArray);

  useEffect(() => {
    if (getSmsHistory && groupedObjects) {
      setCombinedArray(
        getSmsHistory
          .filter((item1) => {
            const match = groupedObjects.find(
              (item2) => item2.id === item1.campaign_id
            );
            return match !== undefined;
          })
          .map((item1) => {
            const item2 = groupedObjects.find(
              (item2) => item2.id === item1.campaign_id
            );
            return {
              ...item1,
              ...item2,
              campaign_details: item2, // adiciona detalhes da campanha do segundo array como sub-objeto, se necessário
            };
          })
      );
      setFilter(
        getSmsHistory
          .filter((item1) => {
            const match = groupedObjects.find(
              (item2) => item2.id === item1.campaign_id
            );
            return match !== undefined;
          })
          .map((item1) => {
            const item2 = groupedObjects.find(
              (item2) => item2.id === item1.campaign_id
            );
            return {
              ...item1,
              ...item2,
              campaign_details: item2, // adiciona detalhes da campanha do segundo array como sub-objeto, se necessário
            };
          })
      );
    }
  }, [getSmsHistory, groupedObjects]);

  useEffect(() => {
    if (selectedDateInicial) {
      const FormatDatePickerDate = () => {
        const day = selectedDateInicial.format("DD");
        const month = selectedDateInicial.format("MM");
        const year = selectedDateInicial.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;
        setFormatDatePickerDateInicial(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateInicial]);

  useEffect(() => {
    if (selectedDateFinal) {
      const FormatDatePickerDate = () => {
        const day = selectedDateFinal.format("DD");
        const month = selectedDateFinal.format("MM");
        const year = selectedDateFinal.format("YYYY");

        // Formatar para "DD/MM/YYYY"
        const formattedDate = `${day}/${month}/${year}`;

        setFormatDatePickerDateFinal(formattedDate);
      };
      FormatDatePickerDate();
    }
  }, [selectedDateFinal]);

  function parseDate(str) {
    const [datePart] = str.split(" "); // Ignora a parte do tempo e usa apenas a parte da data
    const [day, month, year] = datePart.split("/");
    return new Date(year, month - 1, day); // Apenas data, sem tempo
  }

  useEffect(() => {
    if (formatDatePickerDateInicial && formatDatePickerDateFinal) {
      function filterItemsByDateRange(items, startDate, endDate) {
        // Converter as datas usando a função parseDate para garantir o formato correto
        const startDateObj = parseDate(startDate);
        const endDateObj = parseDate(endDate);

        // Definir os horários para meia-noite para garantir comparação apenas por data
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(0, 0, 0, 0);

        // Filtrar os itens que estão entre as datas especificadas
        return (
          items &&
          items.filter((item) => {
            // Converter a data de criação do item para o formato de data
            const itemDate = parseDate(item.date_create);
            itemDate.setHours(0, 0, 0, 0);

            // Verificar se a data do item está dentro do intervalo especificado
            return itemDate >= startDateObj && itemDate <= endDateObj;
          })
        );
      }

      // Filtrar os itens pelo intervalo de datas
      const filteredOutputsAll = filterItemsByDateRange(
        filter,
        formatDatePickerDateInicial,
        formatDatePickerDateFinal
      );

      setFilter(filteredOutputsAll); // Suponho que setFilter seja uma função de atualização de estado
    }
  }, [callFilter]);

  useEffect(() => {
    const fetchData = async () => {
      const apiUserId = "8809fe93dcfc75f765a53359570112a3";
      const apiSecret = "75f82ec0fd39f55425c350431ebd1e7e";

      // Função para obter o token de acesso
      async function getAccessToken() {
        try {
          const response = await axios.post(
            "https://api.sendpulse.com/oauth/access_token",
            {
              grant_type: "client_credentials",
              client_id: apiUserId,
              client_secret: apiSecret,
            }
          );
          return response.data.access_token;
        } catch (error) {
          console.error("Erro ao obter o token de acesso:", error);
          throw new Error("Falha ao obter token de acesso"); // Interrompe a execução
        }
      }

      // try {
      //   const accessToken = await getAccessToken();
      //   if (!accessToken) {
      //     console.error("Token de acesso não recebido.");
      //     return; // Sai da função se não houver accessToken
      //   }
      //   const response = await axios.get("https://api.sendpulse.com/sms/info", {
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   });
      //   setAdminInfo(response.data.data);
      // } catch (error) {
      //   console.error("Erro ao buscar campanhas", error);
      // }
      try {
        const accessToken = await getAccessToken();
        if (!accessToken) {
          console.error("Token de acesso não recebido.");
          return; // Sai da função se não houver accessToken
        }
        const response = await axios.get(
          "https://api.sendpulse.com/sms/campaigns/list",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCampaignsList(response.data.data);
      } catch (error) {
        console.error("Erro ao buscar campanhas", error);
      }
      try {
        const accessToken = await getAccessToken();
        if (!accessToken) {
          console.error("Token de acesso não recebido.");
          return; // Sai da função se não houver accessToken
        }
        const response = await axios.get(
          "https://api.sendpulse.com/campaigns",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCampaigns(response.data);
        setLoading((prevState) => ({
          ...prevState,
          content: false,
        }));
      } catch (error) {
        console.error("Erro ao buscar campanhas", error);
      }
    };

    fetchData();
  }, []);

  // Função para agrupar os arrays
  function groupBy(array1, array2) {
    const grouped = {};

    // Processa o primeiro array
    array1.forEach((item) => {
      grouped[item.id] = { ...grouped[item.id], ...item };
    });

    // Processa o segundo array
    array2.forEach((item) => {
      if (grouped[item.id]) {
        grouped[item.id] = { ...grouped[item.id], ...item };
      } else {
        grouped[item.id] = item;
      }
    });

    return Object.values(grouped);
  }

  useEffect(() => {
    if (campaigns && campaignsList) {
      setGroupedObjects(groupBy(campaigns, campaignsList));
    }
  }, [campaigns, campaignsList]);

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      setWidthButton(larguraTela);
    }

    window.addEventListener("resize", obterTamanhoDaTela);

    obterTamanhoDaTela();
  }, []);

  // const ddd = "statistics.sent";

  const columns = [
    {
      id: "client",
      label: "cliente",
      flex: 1,
    },
    { id: "number", label: "número", flex: 1 },
    {
      id: "message_sended",
      label: "mensagem enviada",
      flex: 1,
    },
    { id: "statistics", label: "status", flex: 1 },
    { id: "user_logged", label: "USER SISTEMA", flex: 1 },
    { id: "date_create", label: "criado em", flex: 1 },
  ];
  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = filter && filter.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      {/* <ModalSmall
        openModal={openModalDeleteClient}
        setOpenModal={setOpenModalDeleteClient}
        title={`Deseja realmente excluir o usuário '${userRowData?.name}'`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteClient(userRowData.id)}
      /> */}

      <ModalDefault
        openModal={openModalFilter}
        setOpenModal={setOpenModalFilter}
        filter={filter}
        selectedDateInicial={selectedDateInicial}
        setSelectedDateInicial={setSelectedDateInicial}
        selectedDateFinal={selectedDateFinal}
        setSelectedDateFinal={setSelectedDateFinal}
        widthButton={widthButton}
        modalType="filtros data"
        title="filtros data"
        btnLeftText="filtrar"
        btnRightText="limpar filtros"
        // btnCloseModalText="cancelar"
        callFunc={() => {
          setCallFilter(!callFilter);
          setOpenModalFilter(false);
        }}
        callFuncRight={() => {
          setFilter(combinedArray);
          setOpenModalFilter(false);
          setSelectedDateInicial();
          setSelectedDateFinal();
        }}
      />

      <CardTable
        title="Envios de SMS"
        hasFilterArea="true"
        // param={filter}
        FilterAreaCallFunc={() => {
          setOpenModalFilter(true);
        }}
        hasFilterName="true"
        setFilterNameValue={setFilterNameValue}
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Envios de SMS" />
            <div className={classes.TopButtonsArea}>
              {/* <GeneralButton
                text="enviar sms"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={envelope}
                rightIcon={plusImg}
                width={widthButton > 768 ? "150px" : "100%"}
                callFunc={() => {
                  handleSetInfoRowData("whatsApp");
                  IsVerifyRowSelection !== "isNull" &&
                    setOpenModalSendSms(true);
                }}
              /> */}
            </div>
          </div>
        }
        table={
          loading.content ? (
            <Spinner content />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "65vh" }}>
                {widthButton > 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize:
                                widthButton > "1920"
                                  ? "14px"
                                  : widthButton > "1200"
                                  ? "12px"
                                  : "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize:
                                        widthButton > "1920"
                                          ? "14px"
                                          : widthButton > "1200"
                                          ? "12px"
                                          : "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "statistics" && value ? (
                                      value.delivered === 1 ? (
                                        <div
                                          style={{
                                            color: "green",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          ENTREGUE
                                        </div>
                                      ) : value.error === 1 ? (
                                        <div
                                          style={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          FALHA NA ENTREGA
                                        </div>
                                      ) : value.sent === 1 ? (
                                        <div
                                          style={{
                                            color: "#0091d7",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          enviada
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#d6a016",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          CAMPANHA EM MODERAÇÃO
                                        </div>
                                      )
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
                {widthButton < 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columnsCollapse.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columnsCollapse.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "collapse" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ fontSize: "16px" }}>
                                          {row.client}
                                        </div>

                                        <IconButton
                                          sx={{
                                            color: "#0091d7",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => {
                                            setRowId(row.id);
                                            setOpen(!open);
                                          }}
                                        >
                                          <img
                                            style={{ width: "25px" }}
                                            src={
                                              open && row.id == rowId
                                                ? closeIcon
                                                : openIcon
                                            }
                                            alt=""
                                          />
                                        </IconButton>
                                      </div>
                                    ) : (
                                      value
                                    )}
                                    {row.id == rowId && (
                                      <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box
                                          sx={{
                                            margin: 1,
                                            color: "#FFF",
                                            fontSize: "11px",
                                          }}
                                        >
                                          <Table
                                            size="small"
                                            aria-label="purchases"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Itens
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Informações
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"NÚMERO:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.number}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"MENSAGEM ENVIADA:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.message_sended}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"STATUS:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {/* {row.statistics} */}
                                                  {row.statistics.delivered ===
                                                  1 ? (
                                                    <div
                                                      style={{
                                                        color: "green",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      ENTREGUE
                                                    </div>
                                                  ) : row.statistics.error ===
                                                    1 ? (
                                                    <div
                                                      style={{
                                                        color: "#d73131",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      FALHA NA ENTREGA
                                                    </div>
                                                  ) : row.statistics.sent ===
                                                    1 ? (
                                                    <div
                                                      style={{
                                                        color: "#0091d7",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      enviada
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        color: "#d6a016",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      CAMPANHA EM MODERAÇÃO
                                                    </div>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"USER SISTEMA:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.user_logged}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"CRIADO EM:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.date_create}
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={filter && filter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Sms;
