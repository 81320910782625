/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from "react";
import classes from "./Supplier.module.scss";
import Title from "../../Components/UI/Title/Title";
import admin from "./../../Assets/TitleIcons/admin.svg";
import CardTable from "../../Components/CardTable/CardTable";
import { firestore } from "../../Services/Firebase";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  // updateDoc,
  // query,
  // where,
} from "firebase/firestore";
import paperImg from "../../Assets/AdminPage/paper.svg";
import plusImg from "../../Assets/AdminPage/plus.svg";
import GeneralButton from "../../Components/UI/GeneralButton/GeneralButton";
import ModalDefault from "../../Components/Modals/ModalDefault/ModalDefault";
import ModalSmall from "../../Components/Modals/ModalSmall/ModalSmall";
import deleteImg from "../../Assets/AdminPage/delete.svg";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import edit from "../../Assets/edit.svg";
import openIcon from "../../Assets/open_icon.svg";
import closeIcon from "../../Assets/close_icon.svg";
import Spinner from "../../Components/UI/Spinner/Spinner";
import NoContentPage from "../../Components/UI/NoContentPage/NoContentPage";

const Supplier = () => {
  const [widthButton, setWidthButton] = useState("");
  const [openModalCreateBank, setOpenModalCreateBank] = useState("");
  const [name, setName] = useState("");
  const [playlistNameSup, setPlaylistNameSup] = useState("");
  const [urlSup, setUrlSup] = useState("");
  const [linkM3uSup, setLinkM3uSup] = useState("");
  const [dans01Sup, setDans01Sup] = useState("");
  const [dans02Sup, setDans02Sup] = useState("");
  const [dans03Sup, setDans03Sup] = useState("");
  const [webPlayerSup, setWebPlayerSup] = useState("");
  const [webPlayerSupTwo, setWebPlayerSupTwo] = useState("");
  const [supplier, setSupplier] = useState("");
  const [callUsers, setCallUsers] = useState(false);
  const [userRowData, setUserRowData] = useState("");
  const [openModalDeleteClient, setOpenModalDeleteClient] = useState(false);
  const [openModalEditSupplier, setOpenModalEditSupplier] = useState(false);
  const [openModalWarningSup, setOpenModalWarningSup] = useState({
    emptyName: false,
    supplierExists: false,
  });
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noContentPage, setNoContentPage] = useState(false);

  const NewUserRowData = { ...userRowData };

  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const date_create = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${ano}`;

  const useCollection = collection(firestore, "credit_supplier");

  const nameRef = useRef(name);
  const playlistNameSupRef = useRef(playlistNameSup);
  const urlSupRef = useRef(urlSup);
  const linkM3uSupRef = useRef(linkM3uSup);
  const dans01SupRef = useRef(dans01Sup);
  const dans02SupRef = useRef(dans02Sup);
  const dans03SupRef = useRef(dans03Sup);
  const webPlayerSupRef = useRef(webPlayerSup);
  const webPlayerSupTwoRef = useRef(webPlayerSupTwo);
  const dateCreateRef = useRef(date_create);

  useEffect(() => {
    // Atualiza as refs quando os estados mudam
    nameRef.current = name;
    playlistNameSupRef.current = playlistNameSup;
    urlSupRef.current = urlSup;
    linkM3uSupRef.current = linkM3uSup;
    dans01SupRef.current = dans01Sup;
    dans02SupRef.current = dans02Sup;
    dans03SupRef.current = dans03Sup;
    webPlayerSupRef.current = webPlayerSup;
    webPlayerSupTwoRef.current = webPlayerSupTwo;
    dateCreateRef.current = date_create;
  }, [
    name,
    date_create,
    playlistNameSup,
    urlSup,
    linkM3uSup,
    dans01Sup,
    dans02Sup,
    dans03Sup,
    webPlayerSup,
    webPlayerSupTwo,
  ]);

  const CreateUser = async () => {
    // Array com alguns nomes
    const SupplierList = supplier && supplier?.map((supppier) => supppier.name);

    // Verifica se o nome está presente no array, ignorando maiúsculas e minúsculas
    if (nameRef.current.length === 0) {
      setOpenModalWarningSup((prevState) => ({
        ...prevState,
        emptyName: true,
      }));
    } else if (
      SupplierList.some(
        (nome) => nome.toLowerCase() === nameRef.current.toLowerCase()
      )
    ) {
      setOpenModalWarningSup((prevState) => ({
        ...prevState,
        supplierExists: true,
      }));
    } else {
      try {
        const user = await addDoc(useCollection, {
          // Utiliza os valores das refs diretamente
          name: nameRef.current,
          amount: "0",
          cost_value: "0",
          last_cost_per_credit: "",
          url: urlSupRef.current,
          m3u_link: linkM3uSupRef.current,
          playlist_name: playlistNameSupRef.current,
          dns_number_one: dans01SupRef.current,
          dns_number_two: dans02SupRef.current,
          dns_number_three: dans03SupRef.current,
          web_player: webPlayerSupRef.current,
          web_player_two: webPlayerSupTwoRef.current,
          date_create: dateCreateRef.current,
        });
        setOpenModalCreateBank(false);
        setCallUsers(!callUsers);
        return user;
      } catch (error) {
        console.error("Erro ao adicionar documento:", error);
        throw error;
      }
    }
  };

  const UpdateSupplierEditPlan = async (id) => {
    try {
      const userDoc = doc(firestore, "credit_supplier", id);
      const user = await updateDoc(userDoc, {
        // Utiliza os valores das refs diretamente
        name: NewUserRowData.name,
        url: NewUserRowData.url,
        m3u_link: NewUserRowData.m3u_link,
        playlist_name: NewUserRowData.playlist_name,
        dns_number_one: NewUserRowData.dns_number_one,
        dns_number_two: NewUserRowData.dns_number_two,
        dns_number_three: NewUserRowData.dns_number_three,
        web_player: NewUserRowData.web_player,
        web_player_two: NewUserRowData.web_player_two,
      });

      setOpenModalEditSupplier(false);
      setCallUsers(!callUsers);

      return user;
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      throw error;
    }
  };

  const DeleteClient = async (id) => {
    const userDoc = doc(firestore, "credit_supplier", id);
    await deleteDoc(userDoc);
    setCallUsers(!callUsers);
    setOpenModalDeleteClient(false);
  };

  useEffect(() => {
    function obterTamanhoDaTela() {
      const larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      console.log("Largura da tela:", larguraTela);
      setWidthButton(larguraTela);
    }

    // Adicionar um ouvinte de evento para o evento 'resize'
    window.addEventListener("resize", obterTamanhoDaTela);

    // Chamar a função inicialmente para obter o tamanho da tela quando a página carregar
    obterTamanhoDaTela();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const data = await getDocs(useCollection);
      setSupplier(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setNoContentPage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length === 0
      );
      setLoading(false);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUsers]);

  const columns = [
    { id: "name", label: "FORNECEDOR", flex: 1 },
    { id: "playlist_name", label: "PLAYLIST NAME (app)", flex: 1 },
    { id: "url", label: "URL (app)", flex: 1 },
    { id: "m3u_link", label: "LINK M3U", flex: 1 },
    { id: "web_player", label: "WEB PLAYER", flex: 1 },
    { id: "web_player_two", label: "WEB PLAYER 2", flex: 1 },
    { id: "dns_number_one", label: "DNS 01", flex: 1 },
    { id: "dns_number_two", label: "DNS 02", flex: 1 },
    { id: "dns_number_three", label: "DNS 03", flex: 1 },
    {
      id: "action",
      label: "ações",
      flex: 1,
    },
  ];

  const columnsCollapse = [{ id: "collapse", label: "Lista", flex: 1 }];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const usersToShow = supplier && supplier.slice(startIndex, endIndex);

  return (
    <div className={classes.AdminMain}>
      <ModalSmall
        openModal={openModalDeleteClient}
        setOpenModal={setOpenModalDeleteClient}
        title={`Deseja realmente excluir o fornecedor '${userRowData?.name}'`}
        btnLeftText="Excluir"
        isDelete={true}
        callFunc={() => DeleteClient(userRowData.id)}
      />
      <ModalSmall
        openModal={openModalWarningSup.supplierExists}
        setOpenModal={setOpenModalWarningSup}
        title={`Já existe na base um Fornecedor com este nome. Altere!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalSmall
        openModal={openModalWarningSup.emptyName}
        setOpenModal={setOpenModalWarningSup}
        title={`O nome do Fornecedor não pode estar vazio. Altere!`}
        isDelete={false}
        isWarning={true}
      />
      <ModalDefault
        openModal={openModalCreateBank}
        setOpenModal={setOpenModalCreateBank}
        setOnchangeInfoOne={setName}
        setPlaylistNameSup={setPlaylistNameSup}
        setUrlSup={setUrlSup}
        setLinkM3uSup={setLinkM3uSup}
        setDans01Sup={setDans01Sup}
        setDans02Sup={setDans02Sup}
        setDans03Sup={setDans03Sup}
        setWebPlayerSup={setWebPlayerSup}
        setWebPlayerSupTwo={setWebPlayerSupTwo}
        modalType="cadastro de fornecedor"
        title="cadastro de fornecedor"
        widthButton={widthButton}
        btnLeftText="cadastrar"
        btnCloseModalText="cancelar"
        callFunc={() => {
          CreateUser();
        }}
      />
      <ModalDefault
        openModal={openModalEditSupplier}
        setOpenModal={setOpenModalEditSupplier}
        NewUserRowData={NewUserRowData}
        userRowData={userRowData}
        setUserRowData={setUserRowData}
        modalType="editar fornecedor"
        title="editar fornecedor"
        btnLeftText="editar"
        widthButton={widthButton}
        btnCloseModalText="cancelar"
        callFunc={() => {
          UpdateSupplierEditPlan(userRowData.id);
        }}
      />
      <CardTable
        title="Fornecedores"
        cardTop={
          <div className={classes.AdminTop}>
            <Title icon={admin} title="Fornecedores" />
            <div className={classes.TopButtonsArea}>
              <GeneralButton
                text="cadastrar fornecedor"
                fontSize="12px"
                border="1px solid"
                padding="8px 10px"
                margin={widthButton < 768 ? "10px 0" : null}
                leftIcon={paperImg}
                rightIcon={plusImg}
                width={widthButton > 768 ? "185px" : "100%"}
                callFunc={() => setOpenModalCreateBank(true)}
              />
            </div>
          </div>
        }
        table={
          loading ? (
            <Spinner content />
          ) : noContentPage ? (
            <NoContentPage tableItem="fornecedor" />
          ) : (
            <div>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                {widthButton > 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              // role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "10px 16px 10px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "action"
                                      ? (value = (
                                          <div
                                            className={
                                              classes.TableActionButtons
                                            }
                                          >
                                            <button
                                              title="Editar usuário"
                                              onClick={() => {
                                                setUserRowData(row);
                                                setOpenModalEditSupplier(true);
                                              }}
                                              className={classes.DeleteButton}
                                            >
                                              <img src={edit} alt="" />
                                            </button>
                                            <button
                                              title="Deletar usuário"
                                              onClick={() => {
                                                setUserRowData(row);
                                                setOpenModalDeleteClient(true);
                                              }}
                                              className={classes.DeleteButton}
                                            >
                                              <img src={deleteImg} alt="" />
                                            </button>
                                          </div>
                                        ))
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
                {widthButton < 768 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columnsCollapse.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              height: "20px",
                              color: "#0091D7",
                              textTransform: "uppercase",
                              borderColor: "#275370",
                              fontSize: "11px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToShow &&
                        usersToShow.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columnsCollapse.map((column) => {
                                let value = row[column.id];
                                return (
                                  <TableCell
                                    className={classes.TableCell}
                                    style={{
                                      // minWidth: column.minWidth,
                                      padding: "5px 16px 5px 16px",
                                      color: "#fff",
                                      borderColor: "#275370",
                                      marginRight: "50px",
                                      fontSize: "11px",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "collapse" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>FORNECEDOR:</div>
                                        <div style={{ fontSize: "16px" }}>
                                          {row.name}
                                        </div>

                                        <IconButton
                                          sx={{
                                            color: "#0091d7",
                                            "&.Mui-checked": {
                                              color: "#0091d7",
                                            },
                                          }}
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => {
                                            setRowId(row.id);
                                            setOpen(!open);
                                          }}
                                        >
                                          <img
                                            style={{ width: "25px" }}
                                            src={
                                              open && row.id == rowId
                                                ? closeIcon
                                                : openIcon
                                            }
                                            alt=""
                                          />
                                        </IconButton>
                                      </div>
                                    ) : (
                                      value
                                    )}
                                    {row.id == rowId && (
                                      <Collapse
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box
                                          sx={{
                                            margin: 1,
                                            color: "#FFF",
                                            fontSize: "11px",
                                          }}
                                        >
                                          <Table
                                            size="small"
                                            aria-label="purchases"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Itens
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  Informações
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"PLAYLIST NAME (APP):"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.playlist_name}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"URL:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.url}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"LINK M3U:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.m3u_link}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"WEB PLAYER:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.web_player}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"DNS 01:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.dns_number_one}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"DNS 02:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.dns_number_two}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"DNS 03:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  {row.dns_number_three}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#0091d7",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                >
                                                  {"AÇÕES:"}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#fff",
                                                    fontSize: "11px",
                                                    borderColor: "#275370",
                                                  }}
                                                  align="right"
                                                >
                                                  <div
                                                    className={
                                                      classes.TableActionButtons
                                                    }
                                                  >
                                                    <button
                                                      title="Editar usuário"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalEditSupplier(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img src={edit} alt="" />
                                                    </button>
                                                    <button
                                                      title="Deletar usuário"
                                                      onClick={() => {
                                                        setUserRowData(row);
                                                        setOpenModalDeleteClient(
                                                          true
                                                        );
                                                      }}
                                                      className={
                                                        classes.DeleteButton
                                                      }
                                                    >
                                                      <img
                                                        src={deleteImg}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          )
        }
        pagination={
          <TablePagination
            style={{
              color: "#50A1D7",
              borderRadius: "8px 8px 8px 8px",
              borderColor: "#275370",
              backgroundColor: "#212C32",
              maxWidth: "410px",
            }}
            rowsPerPageOptions={[10, 25, 100, 150]}
            component="div"
            count={supplier.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        }
      />
    </div>
  );
};

export default Supplier;
